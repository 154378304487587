import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class StorageUnit extends CrudForm {
  formType = () => 'Storage Unit';

  getCrudType = () => 'contracts/storage_units';

  successAlertMsg = () => this.successMsg('Storage Unit');

  modalBodyRows = () => (
    <div>
      {this.getTextFieldRow('name', 'Name')}
      {this.getTextFieldRow('code', 'Code')}
      {this.getTextFieldRow('capacity', 'Capacity', false)}
      {this.getTextFieldRow('material', 'Material', false)}
    </div>
  )
}));
