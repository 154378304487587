import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Terms extends OptionsBaseApi {
  getApiName = () => 'credit/terms';

  getApiParams = () => ({
    order: 'name',
    filtered: JSON.stringify([{ id: 'system__code', value: 'elian_agris' }]),
    show_all: 'true',
  });

  getDropdownLabelText = () => this.props.fieldLabel || 'Payment Term';

  getDropdownFieldName = () => this.props.fieldName || 'term_uuid';

  getLabelFieldName = () => 'code';
}
