import React from 'react';
import { Button } from 'reactstrap';
import { Api, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const invoicePdf = (invoiceNumber, type, context) => Api.doPdfRequest(
  'GET',
  `${process.env.REACT_APP_CONTRACTO_API_URL}/v1/contracts/invoice_pdfs/${invoiceNumber}?type=${type}`,
  '',
  context.props.auth,
);

const Columns = (context) => [
  {
    Header: 'Invoice Number',
    accessor: 'invoice_number',
    Cell: (row) => (
      <Button
        color="link"
        className="otto__no_padding"
        onClick={() => invoicePdf(row.original.invoice_number, row.original.order_type, context)}
      >
        {row.original.invoice_number}
      </Button>
    ),
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Contract ID',
    accessor: 'contract_id',
    Cell: (row) => Links.getLink('contracts', `contracts/view/${row.original.contract_uuid}`, row.original.contract_id),
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Invoice Date',
    accessor: 'invoice_date',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Invoice Due Date',
    accessor: 'invoice_due_date',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Settlement Number',
    accessor: 'settlement_number',
    className: 'text-center',
    width: 130,
  },
  {
    Header: 'Bill To',
    accessor: 'bill_to',
    className: 'text-left',
  },
  {
    Header: 'Tax Location',
    accessor: 'tax_location',
    className: 'text-left',
    width: 120,
  },
  {
    Header: 'Invoice Amount',
    accessor: 'invoice_amount',
    className: 'text-right',
    width: 120,
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    className: 'text-center',
    width: 100,
  },
];

export default Columns;
