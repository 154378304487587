import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CommodityCodes extends OptionsBaseApi {
  getApiName = () => 'items/commodity_codes';

  getApiParams = () => this.props.apiParams || [];

  getDropdownLabelText = () => this.props.fieldLabel || 'Commodity Code';

  getDropdownFieldName = () => this.props.fieldName || 'commodity_code_uuid';

  getLabelFieldName = () => 'name_with_code';
}
