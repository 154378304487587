import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context, url, isPurchase = false) => [
  {
    Header: 'Order ID',
    accessor: 'order_number',
    Cell: (row) => (
      Links.getLink('contracts', `vessel_orders/view/${url}/${row.original.uuid}`, row.original.order_number)
    ),
    className: 'text-center',
    width: 100,
  },
  {
    id: 'contract_schedule_number',
    Header: 'Contract Schedule',
    accessor: (d) => `${d.contract_id} - ${d.contract_schedule_number}`,
    Cell: (row) => (
      Links.getLink(
        'contracts',
        `schedules/view/${row.original.contract_schedule_uuid}`,
        `${row.original.contract_id} - ${row.original.contract_schedule_number}`,
      )
    ),
    className: 'text-center',
    width: 130,
  },
  {
    id: 'counterparty__name',
    Header: 'Seller',
    accessor: 'counterparty_name',
    className: 'text-left',
    show: isPurchase,
    csvShow: isPurchase,
  },
  {
    id: 'counterparty__name',
    Header: 'Customer',
    accessor: 'counterparty_name',
    className: 'text-left',
    show: !isPurchase,
    csvShow: !isPurchase,
  },
  {
    Header: 'Vessel Name',
    accessor: 'vessel_name',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    Header: 'Origin Quantity',
    accessor: 'origin_quantity',
    className: 'text-right',
    width: 120,
  },
  {
    Header: 'Destination Quantity',
    accessor: 'destination_quantity',
    className: 'text-right',
    width: 140,
  },
  {
    id: 'unit_of_measure__symbol',
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-center',
    width: 80,
  },
  {
    id: 'contract__weight_base',
    Header: 'Weight Base',
    accessor: 'weight_base',
    className: 'text-center',
    width: 120,
  },
  {
    id: 'arrival_date',
    Header: 'Arrival Date',
    accessor: 'arrival_date_formatted',
    className: 'text-center',
    width: 150,
    show: isPurchase,
    csvShow: isPurchase,
  },
  {
    id: 'shipped_date',
    Header: 'Shipped Date',
    accessor: 'shipped_date_formatted',
    className: 'text-center',
    width: 120,
    show: !isPurchase,
    csvShow: !isPurchase,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    width: 100,
  },
];

export default Columns;
