import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  CrudForm, HtmlHelpers, UserGroups, WmForm, WmReactTable,
} from '@westernmilling/eu_react_shared';
import { Col } from 'reactstrap';

import Columns from 'helpers/columns/OutboundOrderUploads';
import SourceLocation from 'components/Options/SourceLocation';

export default withAuth(withAlert()(class OutboundTruckUploadContainer extends CrudForm {
  async componentDidMount() {
    const userInfo = await UserGroups.UserInfo(this.props.auth);
    if (this.props.setUserInfo !== undefined) {
      this.props.setUserInfo(userInfo);
    }
    await this.setState({ userGroups: userInfo.groups });
  }

  getCrudType = () => 'contracts/truck_order_weight_uploads';

  formType = () => '';

  onSubmit = (event) => this.formDataSubmit(event);

  getLabelColumns = () => 4;

  getFieldColumns = () => 4;

  options = () => (
    {
      source: {
        component: SourceLocation,
        field: 'agris_bin_desc',
        props: { isRequired: true },
      },
    }
  );

  onChangeFileEvent = async (event) => {
    const newData = { ...this.state.item };
    const target = event.target;

    newData[target.name] = target.files[0];
    return newData;
  }

  formData = () => {
    const form = new FormData();
    form.append('agris_bin_desc', this.state.item.agris_bin_desc);
    if (this.state.item.attachment !== undefined) {
      form.append('attachment', this.state.item.attachment);
    }
    return form;
  }

  disclaimerNotes = () => (
    <div>
      Notes:
      <ul>
        <li>The file type must be xlsx</li>
        <li>The column headers must be the 1st row and match the following order exactly</li>
        <li>
          Columns: Order ID, Ship-to Name, Ship-to address1, Ship-to address2, Ship-to city, Ship-to province, Ship-to Zip,
          Ship-to country, Carrier name, Carrier CIF, Pick-up date, Truck plate, Trailer plate, Customer PO, Seal, Batch,
          Tare (KG), Tare date, Gross (KG), Gross date, Net (KG), BOL number
        </li>
      </ul>
    </div>
  )

  showData = () => this.state.data?.map((d) => (
    Object.keys(d).map((k) => (
      <div>
        {k}
        :
        {d[k]}
      </div>
    ))
  ))

  handleSuccess = (data) => {
    let info = [];
    if (data !== undefined) {
      info = data.map((d) => d.attributes);
    }
    this.setState((prevState) => (
      {
        data: info, disabled: false, item: { attachment: null, agris_bin_desc: null }, formKey: prevState.formKey + 1,
      }
    ));
  }

  results = () => (HtmlHelpers.isEmpty(this.state.data) ? '' : (
    <div>
      <hr />
      <WmReactTable
        data={this.state.data}
        columns={Columns(this)}
      />
    </div>
  ));

  getContents = () => (
    <div>
      <h2>Outbound Truck Upload</h2>
      <WmForm key={this.state.formKey} submit={this.onSubmit} ref={this.formRef} id="form__crud">
        {this.dropdownField('source')}
        {this.getUploadFileRow('attachment', '', true)}
        <Col md="12" xs="12" className="text-center">
          {this.getButton('Upload File')}
        </Col>
      </WmForm>
      <br />
      {this.disclaimerNotes()}
      {this.results()}
    </div>
  );
}));
