import React from 'react';
import { ColumnButton } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Variety',
    accessor: 'reference',
    className: 'text-center',
  },
  {
    Header: 'Item',
    accessor: 'name',
    className: 'text-left',
  },
  {
    Header: 'Commodity Code',
    accessor: 'commodity_code',
    className: 'text-center',
  },
  {
    Header: 'Premium/ Discount',
    accessor: 'premium',
    className: 'text-center',
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => {
      let content = '';
      if (row.original.is_main_item) {
        content = 'Main';
      } else if (row.original.can_edit) {
        content = <ColumnButton onClick={() => context.handleOpenModal(row.index)} text="edit" />;
      }
      return content;
    },
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 100,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default Columns;
