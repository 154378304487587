import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Items from 'components/Options/Items';
import Locations from 'components/Options/Locations';
import SourceLocation from 'components/Options/SourceLocation';
import UnitOfMeasure from 'components/Options/UnitOfMeasure';
import Carriers from 'components/Options/Carriers';
import CarrierAgencies from 'components/Options/CarrierAgencies';
import FinalUsages from 'components/Options/FinalUsages';

import ShipToField from 'components/Forms/ShipToField';

export default withAuth(withAlert()(class TruckOrderForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.truck_num = 1;
    this.state.item.transfer = true;
    this.state.item.customer_entity_uuid = process.env.REACT_APP_ELIAN_ENTITY_UUID;
    this.state.formKey = 1;
  }

  formType = () => 'Transfer Truck Order';

  getCrudType = () => 'contracts/truck_order_transfers';

  successAlertMsg = () => this.successMsg('Truck Order');

  options = () => (
    {
      carrierAgency: {
        component: CarrierAgencies,
        field: 'carrier_agency_entity_uuid',
      },
      finalUsage: {
        component: FinalUsages,
        field: 'final_usage',
        props: { isRequired: true },
        currentValue: this.getItem().final_usage,
      },
      item: {
        component: Items,
        field: 'item_uuid',
        props: {
          isRequired: true,
          apiParams: ({ filtered: JSON.stringify([{ id: 'active', value: 'yes' }]), show_all: 'true' }),
        },
      },
      source: {
        component: SourceLocation,
        field: 'agris_bin_desc',
        props: { isRequired: true, used: [this.getItem().transfer_to_agris_bin_desc] },
      },
      destination: {
        component: SourceLocation,
        field: 'transfer_to_agris_bin_desc',
        label: 'Transfer To',
        props: { isRequired: true, used: [this.getItem().agris_bin_desc] },
      },
      uom: {
        component: UnitOfMeasure,
        field: 'unit_of_measure_uuid',
        props: {
          isRequired: true,
          apiParams: ({ filtered: JSON.stringify([{ id: 'active', value: 'yes' }]), show_all: 'true' }),
        },
      },
      carriers: {
        component: Carriers,
        field: 'carrier_entity_uuid',
        props: { isRequired: true },
      },
    }
  );

  locationsParams = () => (
    {
      entity_uuid: this.getItem().customer_entity_uuid,
      tms_locations: 'Y',
      with_systems: 'Y',
      show_all: 'true',
      sorted: JSON.stringify([{ id: 'name', desc: false }]),
    }
  );

  modalBodyRows = () => (
    <div>
      {this.getDateFieldRow('pickup_date', 'Pickup Date', false)}
      {this.dropdownField('source')}
      {this.dropdownField('destination')}
      {this.dropdownField('item')}
      {this.dropdownField('uom')}
      {this.dropdownField('finalUsage')}
      {this.getNumericFieldRow('quantity_ordered', 'Quantity')}
      {this.getNumericFieldRow('transfer_freight_rate', 'Freight Rate', false)}
      {this.dropdownField('carrierAgency')}
      {this.dropdownField('carriers')}
      {this.getTextFieldRow('driver_name', 'Driver', false)}
      {this.getTextFieldRow('driver_id', 'Driver ID', false)}
      {this.getTextFieldRow('truck_plate', 'Truck Plate', false)}
      {this.getTextFieldRow('trailer_plate', 'Trailer Plate', false)}
    </div>
  )
}));
