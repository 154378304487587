import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class PaymentMethods extends OptionsBaseApi {
  getApiName = () => 'entities/payment_methods';

  getApiParams = () => ({ query: 'active = true' });

  getDropdownLabelText = () => this.props.fieldLabel || 'Payment Method';

  getDropdownFieldName = () => this.props.fieldName || 'payment_method_uuid';
}
