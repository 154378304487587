import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { AddIcon, Api, CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/GrainTickestNotInvoiced';

export default withAuth(withAlert()(class GrainTicketsNotInvoicedListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/grain_tickets_not_invoiced';

  getStorageName = () => 'otto_grain_tickets_not_invoiced_list';

  getPageTitle = () => 'Grain Tickets - Not Invoiced';

  getAddGroups = () => ['execution_admin'];

  getAddText = () => 'Import Invoices';

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  createButton = () => (
    <div>
      <AddIcon className="otto__margin_btm_30" onClick={this.importInvoices} addText={this.getAddText()} />
    </div>
  )

  importInvoices = () => Api
    .crudCreate(this.props.auth, 'credit/invoices', {})
    .then(async (response) => {
      this.setState({ disabled: true });
      this.props.alert.show('Invoice job started', { type: 'success' });
      setTimeout(() => this.fetchData(), 10000);
    });
}));
