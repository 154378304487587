import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import {
  CrudForm, HtmlHelpers, Links, TableTitle,
} from '@westernmilling/eu_react_shared';

import Nominations from 'components/Tables/Nominations';

export default withAuth(withAlert()(class ContractScheduleViewForm extends CrudForm {
  formType = () => 'Contract Schedule';

  getCrudType = () => 'contracts/contract_schedules';

  getItems = () => this.getItem().item_names.map((item, idx) => (
    <div key={`item_${idx + 1}`}>
      { item }
    </div>
  ));

  isBasis = () => !['NPE', 'Flat'].includes(this.getItem().contract_type);

  isPremium = () => this.getItem().contract_type === 'Premium Over Premium';

  isNpe = () => this.getItem().contract_type === 'NPE';

  contractLink = () => (
    Links.getLink('contracts', `contracts/view/${this.getItem().contract_uuid}`, this.getItem().contract_id)
  );

  col1 = () => (
    <Col md={4} sm={12}>
      {this.getRowWithValue('Contract ID', this.contractLink())}
      {this.getRow('number', 'Schedule Number')}
      {this.getRow('delivery_start_formatted', 'Delivery Start')}
      {this.getRow('delivery_end_formatted', 'Delivery End')}
      {this.getRow('status', 'Status')}
    </Col>
  )

  col2 = () => (
    <Col md={4} sm={12}>
      {this.getRow('quantity', 'Quantity')}
      {this.getRow('balance', 'Unnominated')}
      {this.getRow('quantity_shipped', 'Shipped')}
      {this.getRow('unshipped', 'Unshipped')}
      {this.getRow('unit_of_measure_symbol', 'UOM')}
    </Col>
  )

  col3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('counterparty_name', 'Counterparty')}
      {this.getRowWithValue('Items', this.getItems())}
    </Col>
  )

  col4 = () => (this.isBasis() ? (
    <div>
      {this.getRow('board_name', 'Board')}
      {this.getRow('futures_uom_symbol', 'Fut. UOM')}
      {this.getRow('option_month_text', 'Opt. Month')}
      {this.getRow('option_year', 'Opt. Year')}
    </div>
  ) : (
    <div>
      {this.getRow('board_name', 'Board')}
    </div>
  ));

  col5 = () => (this.isBasis() ? (
    <div>
      {this.isPremium() ? this.getRow('contract_premium', 'Premium') : ''}
      {this.getRow('contract_basis', 'Basis')}
      {this.getRow('contract_futures', 'Futures')}
      {this.getRow('contract_fx', 'FX Rate')}
    </div>
  ) : '');

  col6 = () => (this.isBasis() ? (
    <div>
      {this.isPremium() ? this.getRow('invoiced_premium', 'Inv Premium') : ''}
      {this.getRow('invoiced_basis', 'Inv Basis')}
      {this.getRow('invoiced_futures', 'Inv Futures')}
    </div>
  ) : '');

  pricingDetails = () => (this.isNpe() ? '' : (
    <div>
      <br />
      <hr />
      <Row>
        <Col md="8" className="text-left">
          <TableTitle title="Pricing" />
        </Col>
      </Row>
      <Row>
        <Col md={3} sm={12}>
          {this.col4()}
        </Col>
        <Col md={3} sm={12}>
          {this.col5()}
        </Col>
        <Col md={3} sm={12}>
          {this.col6()}
        </Col>
        <Col md={3} sm={12}>
          {this.getRow('contract_price', 'Flat Price')}
          {this.isBasis() ? this.getRow('basis_currency_name', 'Basis Ccy') : '' }
          {this.isBasis() ? this.getRow('basis_uom_symbol', 'Basis UOM') : '' }
        </Col>
      </Row>
    </div>
  ))

  modalBodyRows = () => (
    <div>
      <Row>
        {this.col1()}
        {this.col2()}
        {this.col3()}
      </Row>
      {this.pricingDetails()}
      <br />
      <hr />
      <Nominations schedule={this.getItem()} pageType="nomination" updateData={this.props.updateData} />
    </div>
  )
}));
