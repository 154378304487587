import React from 'react';
import { HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Contract ID',
    accessor: 'contract_id',
    Cell: (row) => Links.getLink('contracts', `contracts/view/${row.original.contract_uuid}`, row.original.contract_id),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Active',
    accessor: 'active_text',
    className: 'text-center',
    width: 70,
  },
  {
    Header: 'Invoice Number',
    accessor: 'invoice_number',
    className: 'text-center',
    width: 110,
  },
  {
    Header: 'Invoice Date',
    accessor: 'invoice_date_formatted',
    className: 'text-center',
    sortMethod: HtmlHelpers.dateSort,
    width: 100,
  },
  {
    Header: 'Due Date',
    accessor: 'invoice_due_date_formatted',
    className: 'text-center',
    sortMethod: HtmlHelpers.dateSort,
    width: 100,
  },
  {
    Header: 'Delivery Start',
    accessor: 'delivery_start_formatted',
    className: 'text-center',
    sortMethod: HtmlHelpers.dateSort,
    width: 100,
  },
  {
    Header: 'Delivery End',
    accessor: 'delivery_end_formatted',
    className: 'text-center',
    sortMethod: HtmlHelpers.dateSort,
    width: 100,
  },
  {
    Header: 'Counterparty',
    accessor: 'counterparty',
    className: 'text-left',
  },
  {
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
  },
  {
    Header: 'Commodity',
    accessor: 'commodity_code',
    className: 'text-left',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    className: 'text-right',
    sortMethod: HtmlHelpers.numberSort,
    width: 80,
  },
  {
    Header: 'Shipped',
    accessor: 'quantity_shipped',
    className: 'text-right',
    sortMethod: HtmlHelpers.numberSort,
    width: 80,
  },
  {
    Header: 'UOM',
    accessor: 'symbol',
    className: 'text-center',
    width: 70,
  },
  {
    Header: 'Broker',
    accessor: 'broker',
    className: 'text-left',
  },
  {
    Header: 'Broker Rate',
    accessor: 'brokerage_rate',
    className: 'text-right',
    sortMethod: HtmlHelpers.numberSort,
    width: 100,
  },
  {
    Header: 'Broker Fee',
    accessor: 'brokerage_fee',
    className: 'text-right',
    sortMethod: HtmlHelpers.numberSort,
    width: 100,
  },
  {
    Header: 'Price',
    accessor: 'contract_price',
    className: 'text-right',
    width: 70,
  },
  {
    Header: 'Currency',
    accessor: 'name',
    className: 'text-center',
    width: 70,
  },
];

export default Columns;
