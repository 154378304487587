import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default class InboundTransTemplates extends OptionsBaseList {
  getOptions = () => ([
    { value: 'verdu', label: 'Verdu Template' },
  ]);

  getDropdownLabelText = () => 'Template';

  getDropdownFieldName = () => 'template';
}
