import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Carriers extends OptionsBaseApi {
  getApiName = () => 'entities/entities';

  getApiParams = () => ({ carriers: 'Y', show_all: 'true', sorted: JSON.stringify([{ id: 'name', desc: false }]) });

  getDropdownLabelText = () => this.props.fieldLabel || 'Carrier';

  getDropdownFieldName = () => this.props.fieldName || 'carrier_entity_uuid';

  getLabelFieldName = () => 'name_with_cif';
}
