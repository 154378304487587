import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { WmReactTable } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/ContractChangesReview';

export default withAuth(withAlert()(class ContractChanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      contractId: props.contractId,
    };
  }

  getColumns = () => Columns(this);

  getPageTitle = () => `Contract - ${this.state.contractId}`;

  render() {
    return (
      <Modal
        className="otto__modal-l"
        size="lg"
        isOpen={this.props.showModal}
        toggle={this.props.closeModal}
        backdrop="static"
      >
        <ModalHeader toggle={this.props.closeModal}>
          {this.getPageTitle()}
        </ModalHeader>
        <ModalBody>
          <WmReactTable
            data={this.state.data}
            className="otto__short_table"
            columns={this.getColumns()}
          />
        </ModalBody>
      </Modal>
    );
  }
}));
