import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudView, ModalConfirmation } from '@westernmilling/eu_react_shared';

import ActionLinks from 'components/ActionMenus/ContractView';
import ItemForm from 'components/Forms/Contract';
import ItemView from 'components/Forms/ContractView';
import AccrualModal from 'components/Modals/AccrualModal';
import SignedModal from 'components/Modals/SignedModal';

export default withAuth(withAlert()(class ContractViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
  }

  editForm = () => ItemForm;

  refreshAfterUpdate = () => {
    this.setState({ data: null });
    this.updateData();
  }

  getSection = () => (
    {
      type: 'contracts/contracts',
      form: ItemView,
      title: 'View Contract',
      listRoute: '../../contracts',
      listLabel: 'Contract List',
      edit_admins: [],
    }
  )

  additionalButtonsAfterList = () => (
    <span>
      <ActionLinks
        linkInfo={this.state.linkInfo || {}}
        uuid={this.getItem().uuid}
        openModal={this.handleOpenModal}
      />
      {this.cancelModal()}
      {this.accrualModal()}
      {this.signedModal()}
    </span>
  );

  cancelApi = () => {
    const data = { uuid: this.getUuid(), status: 'Canceled' };
    this.setState({ disableCancel: true });
    Api.crudDelete(this.props.auth, 'contracts/contracts', data)
      .then((response) => { this.handleResponse(response, 'Sucessfully canceled the contract'); })
      .then(() => this.handleCloseModal('Cancel'));
  };

  cancelModal = () => {
    let contents = '';
    if (this.state.showModalCancel) {
      contents = (
        <ModalConfirmation
          msg="Are you sure you want to cancel this contract?"
          onConfirmation={this.cancelApi}
          showModal={this.state.showModalCancel}
          closeModal={() => this.handleCloseModal('Cancel')}
          disabled={this.state.disableCancel}
        />
      );
    }
    return contents;
  };

  accrualApi = (data) => {
    this.setState({ disableAccrural: true });
    Api.crudUpdate(this.props.auth, 'contracts/contracts', data)
      .then((response) => { this.handleResponse(response, 'Accrurals added to the contract'); })
      .then(() => this.handleCloseModal('Accrural'));
  };

  accrualModal = () => {
    let contents = '';
    if (this.state.showModalAccrural) {
      contents = (
        <AccrualModal
          item={this.getItem() || {}}
          onConfirmation={this.accrualApi}
          showModal={this.state.showModalAccrural}
          closeModal={() => this.handleCloseModal('Accrural')}
          disabled={this.state.disableAccrural}
          updateData={this.refreshAfterUpdate}
        />
      );
    }
    return contents;
  };

  signedModal = () => {
    let contents = '';
    if (this.state.showModalSigned) {
      contents = (
        <SignedModal
          item={this.getItem() || {}}
          showModal={this.state.showModalSigned}
          closeModal={() => this.handleCloseModal('Signed')}
          disabled={this.state.disableSigned}
          updateData={this.refreshAfterUpdate}
        />
      );
    }
    return contents;
  };
}));
