import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudView, ModalConfirmation } from '@westernmilling/eu_react_shared';

import ActionLinks from 'components/ActionMenus/ScheduleView';
import ItemForm from 'components/Forms/Schedule';
import ItemView from 'components/Forms/ScheduleView';
import SchedulePricingModal from 'components/Modals/SchedulePricingModal';

export default withAuth(withAlert()(class ContractScheduleViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
  }

  editForm = () => ItemForm;

  getSection = () => (
    {
      type: 'contracts/contract_schedules',
      form: ItemView,
      title: 'View Contract Schedule',
      listRoute: '../../schedules',
      listLabel: 'Contract Schedule List',
      edit_admins: [],
    }
  )

  additionalButtonsAfterList = () => (
    <span>
      <ActionLinks
        linkInfo={this.state.linkInfo || {}}
        uuid={this.getItem().uuid}
        openModal={this.handleOpenModal}
      />
      {this.cancelModal()}
      {this.pricingModal()}
    </span>
  );

  cancelApi = () => {
    const data = { uuid: this.getUuid() };
    this.setState({ disableCancel: true });
    Api.crudDelete(this.props.auth, 'contracts/contract_schedules', data)
      .then((response) => { this.handleResponse(response, 'Sucessfully canceled the contract schedule'); })
      .then(() => {
        this.handleCloseModal('Cancel');
        this.setState({ disableCancel: false });
      });
  };

  cancelModal = () => {
    let contents = '';
    if (this.state.showModalCancel) {
      contents = (
        <ModalConfirmation
          msg="Are you sure you want to cancel this contract schedule?"
          onConfirmation={this.cancelApi}
          showModal={this.state.showModalCancel}
          closeModal={() => this.handleCloseModal('Cancel')}
          disabled={this.state.disableCancel}
        />
      );
    }
    return contents;
  };

  updateData = () => {
    this.setState({ data: null });
    this.fetchItem();
  }

  pricingModal = () => {
    let contents = '';
    if (this.state.showModalPrice) {
      contents = (
        <SchedulePricingModal
          item={this.getItem() || {}}
          showModal={this.state.showModalPrice}
          closeModal={() => this.handleCloseModal('Price')}
          updateData={this.updateData}
        />
      );
    }
    return contents;
  };
}));
