import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import CommodityBoards from 'components/Options/CommodityBoards';
import ContractType from 'components/Options/ContractType';
import Currencies from 'components/Options/Currencies';
import OptionMonthsYears from 'components/Options/OptionMonthsYears';
import UnitOfMeasure from 'components/Options/UnitOfMeasure';

export default withAuth(withAlert()(class SchedulePricingModal extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item = props.item;
    this.state.item.quantity_to_price ||= this.state.item.quantity;
    this.state.fromNpe = this.state.item.contract_type === 'NPE';
    this.state.item.basisCurrency = '';
    this.state.item.basisSymbol = '';
    this.state.formKey = 1;
  }

  componentDidMount = async () => {
    await this.getOptionMonthYears(this.getItem());
  }

  formType = () => 'Schedule Pricing Fixation';

  headerTitle = () => '';

  getCrudType = () => 'contracts/contract_schedules';

  submitLabel = () => 'Update Pricing';

  isPremium = () => this.getItem().contract_type === 'Premium Over Premium';

  getOptionMonthYears = async (item = {}) => {
    if (!['NPE', ''].includes(item.contract_type)) {
      let monthYearOptions = [];
      const path = 'items/commodity_code_commodity_boards?months=Y';
      const params = {
        board_uuid: item.commodity_board_uuid || item.board_uuid,
        commodity_uuid: item.commodity_code_uuid,
        contract_date: item.contract_date_formatted,
      };

      await Api.crudList(this.props.auth, path, params).then((response) => {
        if (response.data !== undefined) {
          monthYearOptions = response.data.attributes;
        }
      });

      if (HtmlHelpers.isEmpty(monthYearOptions) && ['NPE', 'Flat'].includes(item.contract_type)) {
        this.setState({ item: { ...this.state.item, commodity_board_uuid: '', board_uuid: '' } });
      }

      this.setState({ monthYearOptions });
    }
  };

  options = () => (
    {
      boards: {
        component: CommodityBoards,
        field: 'commodity_board_uuid',
        currentValue: this.getItem().commodity_board_uuid || this.getItem().board_uuid,
        props: { isRequired: this.getItem().contract_type !== 'Flat' },
      },
      currencies: {
        component: Currencies,
        field: 'basis_currency_uuid',
        label: 'Basis Currency',
        currentValue: this.getItem().basis_currency_uuid,
        props: { isRequired: true },
      },
      optionMonthYears: {
        component: OptionMonthsYears,
        label: 'Option Month/ Year',
        field: 'option_month_year',
        currentValue: this.getItem().option_month_year,
        data: this.state.monthYearOptions || [],
        props: { isRequired: true, key: this.state.formKey },
      },
      types: {
        component: ContractType,
        field: 'contract_type',
        props: { isRequired: true },
        currentValue: this.getItem().contract_type,
      },
      uom: {
        component: UnitOfMeasure,
        field: 'basis_unit_of_measure_uuid',
        label: 'Basis UOM',
        props: { isRequired: true },
        currentValue: this.getItem().basis_unit_of_measure_uuid,
      },
    }
  );

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;

    if (name === 'basis_unit_of_measure_uuid') {
      newData.basisSymbol = event.target.value.item.symbol;
    }
    if (name === 'commodity_board_uuid') {
      newData.board_code = event.target.value.item.code;
      this.getOptionMonthYears(newData);
    }
    if (name === 'basis_currency_uuid') {
      newData.basis_currency_name = event.target.value.label;
    }
    if (name === 'contract_type') {
      if (newData[name] === 'US Basis') {
        newData.basisCurrency = 'USD';
      } else if (['Spanish Basis', 'Euro Basis'].includes(newData[name])) {
        newData.basisCurrency = 'EUR';
      } else {
        newData.basisCurrency = '';
        newData.basis_currency_uuid = '';
      }
    }

    this.setVariables({ item: newData });
  }

  buildApiVariables = () => ({
    uuid: this.getItem().uuid,
    contract_price: this.getItem().contract_price,
    contract_premium: this.getItem().contract_premium,
    contract_basis: this.getItem().contract_basis,
    contract_futures: this.getItem().contract_futures,
    contract_fx: this.getItem().contract_fx,
    option_month_year: this.getItem().option_month_year,
    contract_type: this.getItem().contract_type,
    commodity_board_uuid: this.getItem().commodity_board_uuid,
    quantity_to_price: this.getItem().quantity_to_price,
    basis_currency_uuid: this.getItem().basis_currency_uuid,
    basisCurrency: this.getItem().basisCurrency,
    basis_unit_of_measure_uuid: this.getItem().basis_unit_of_measure_uuid,
  });

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    this.props.updateData();
    this.props.closeModal();
  }

  getAccrualRow = (field, label) => this.getNumericFieldRow(field, label, false);

  minQty = () => this.getItem().quantity - this.getItem().quantity_balance;

  basisCurrency = () => {
    let curr = '';
    if (HtmlHelpers.isNotEmpty(this.getItem().basis_currency_name)) {
      curr = `(${this.getItem().basis_currency_name})`;
    }
    return curr;
  }

  futuresCurrency = () => {
    let curr = '';
    if (this.getItem().board_code === 'cme') {
      curr = '(USD)';
    } else if (this.getItem().board_code === 'matif') {
      curr = '(EUR)';
    }
    return curr;
  };

  basisUomCurr = () => `${this.getItem().basis_uom_symbol || this.getItem().basisSymbol} ${this.basisCurrency()}`;

  futuresUomCurr = () => (
    `${this.getItem().futures_uom_symbol || ''} ${this.futuresCurrency()}`
  );

  qtyToPriceCurr = () => (
    `${this.getItem().unit_of_measure_symbol || ''} (${this.getItem().contract_currency})`
  );

  premiumField = () => (
    this.isPremium()
      ? this.getNumericFieldRow('contract_premium', 'Premium', true, { buttonAfter: this.basisUomCurr() })
      : ''
  );

  qtyToPriceField = () => (
    ['NPE', ''].includes(this.getItem().contract_type) || this.getItem().status === 'Flat'
      ? ''
      : this.getNumericFieldRow('quantity_to_price', 'Qty to Price', true, { buttonAfter: this.qtyToPriceCurr() })
  );

  minPriceField = () => (
    this.getTextRow('Minimum Qty to Price', `${this.minQty()} ${this.getItem().unit_of_measure_symbol}`)
  );

  basisFields = () => (
    <div>
      { this.minPriceField() }
      { this.state.fromNpe ? '' : this.getTextRow('Commodity Board', this.getItem().board_name) }
      <hr />
      { this.state.fromNpe ? this.dropdownField('types') : '' }
      { this.state.fromNpe ? this.dropdownField('boards') : '' }
      { (this.state.fromNpe && this.isPremium()) ? this.dropdownField('currencies') : '' }
      { this.state.fromNpe ? this.dropdownField('uom') : '' }
      { this.premiumField() }
      { this.getNumericFieldRow('contract_basis', 'Basis', !this.isPremium(), { buttonAfter: this.basisUomCurr() }) }
      { this.getNumericFieldRow('contract_futures', 'Futures', false, { buttonAfter: this.futuresUomCurr() }) }
      { this.dropdownField('optionMonthYears') }
      { this.getNumericFieldRow('contract_fx', 'FX Rate', false) }
      { this.qtyToPriceField() }
    </div>
  );

  flatFields = () => (
    <div>
      { this.state.fromNpe ? this.minPriceField() : '' }
      { this.state.fromNpe ? <hr /> : '' }
      { this.state.fromNpe ? this.dropdownField('types') : '' }
      { this.state.fromNpe ? this.dropdownField('boards') : '' }
      { this.state.fromNpe ? this.qtyToPriceField() : '' }
      { this.getNumericFieldRow('contract_price', 'Flat Price') }
    </div>
  );

  modalBodyRows = () => {
    let content = '';
    if (this.getItem().contract_type === 'Flat') {
      content = this.flatFields();
    } else if (['NPE', ''].includes(this.getItem().contract_type)) {
      content = this.dropdownField('types');
    } else {
      content = this.basisFields();
    }
    return content;
  }
}));
