import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import {
  Attachments, CrudForm, HtmlHelpers, ExportTable, Links,
} from '@westernmilling/eu_react_shared';

import TruckOrderNotes from 'components/Tables/TruckOrderNotes';

export default withAuth(withAlert()(class TruckOrderViewForm extends CrudForm {
  formType = () => 'Truck Order';

  getCrudType = () => 'contracts/truck_orders';

  isBarcelona = () => this.getItem().agris_bin_desc === this.props.additionalFormProps.barcelona;

  contractLink = () => Links.getLink(
    'contracts',
    `contracts/view/${this.getItem().contract_uuid}`,
    this.getItem().contract_id,
  );

  nominationLink = () => Links.getLink(
    'contracts',
    `nominations/view/${this.getItem().nomination_uuid}`,
    this.getItem().nomination_id,
  );

  itemLink = () => Links.getLink(
    'items',
    `items/view/${this.getItem().item_uuid}`,
    this.getItem().item_name,
  );

  uom = () => this.getItem().unit_of_measure_symbol;

  formatQty = (label, amt) => this.getRowWithValue(label, (HtmlHelpers.isEmpty(amt) ? '' : `${amt} ${this.uom()}`));

  col1 = () => (
    this.getItem().transfer ? (
      <Col md={4} sm={12}>
        {this.getRow('id', 'Order ID')}
        {this.getRow('transfer_freight_rate', 'Freight Rate')}
      </Col>
    ) : (
      <Col md={4} sm={12}>
        {this.getRow('id', 'Order ID')}
        {this.getRowWithValue('Nomination', this.nominationLink())}
        {this.getRowWithValue('Contract', this.contractLink())}
        {this.getRow('bill_to_name', 'Bill To')}
        {this.getRow('po_number', 'Customer PO')}
      </Col>
    )
  );

  col2 = () => (
    <Col md={4} sm={12}>
      {this.formatQty('Order Qty', this.getItem().quantity_ordered)}
      {this.formatQty('Shipped Qty', this.getItem().quantity_shipped)}
      {this.getItem().transfer ? this.formatQty('Received Qty', this.getItem().received_quantity) : ''}
      {this.getRow('gross_weight_at', 'Qty Scaled At')}
      {this.isBarcelona() ? this.getRow('check_in_at', 'Checked In At') : ''}
      {this.isBarcelona() ? this.getRow('arrived_at', 'Arrived At') : ''}
    </Col>
  );

  col3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('status', 'Order Status')}
      {this.getRow('agris_bin_desc', 'Source')}
      {this.getRowWithValue('Item', this.itemLink())}
      {this.getRow('batch_number', 'Batch #')}
      {this.getRow('storage_unit', 'Storage Unit')}
    </Col>
  );

  shipCol1 = () => (
    <Col md={3} sm={12}>
      {this.getRow('pickup_date', 'Scheduled Date')}
      {this.getRow('shipped_date', 'Shipped Date')}
      {this.getRow('time_slot_range', 'Time Slot')}
      {this.getRowWithValue('Ship To', <pre>{this.getItem().ship_to}</pre>)}
    </Col>
  );

  carrierAgency = () => (
    HtmlHelpers.isNotEmpty(this.getItem().carrier_agency_name)
      ? this.getRow('carrier_agency_name', 'Carrier Agency')
      : ''
  );

  shipCol2 = () => (
    <Col md={3} sm={12}>
      {this.carrierAgency()}
      {this.getRow('carrier_name', 'Carrier')}
      {this.getRow('carrier_cif', 'Carrier CIF')}
      {this.getRow('seals', 'Seals')}
    </Col>
  );

  shipCol3 = () => (
    <Col md={3} sm={12}>
      {this.getRow('tare_weight', 'Tare Weight (kg)')}
      {this.getRow('tare_weight_at', 'Tare Weight At')}
      {this.getRow('gross_weight', 'Gross Weight (kg)')}
      {this.getRow('gross_weight_at', 'Gross Weight At')}
      {this.getRow('net_weight', 'Net Weight (kg)')}
    </Col>
  );

  shipCol4 = () => (
    <Col md={3} sm={12}>
      {this.getRow('document_number', 'BOL #')}
      {this.getRow('driver_name', 'Driver')}
      {this.getRow('driver_id', 'Driver ID')}
      {this.getRow('truck_plate', 'Truck Plate')}
      {this.getRow('trailer_plate', 'Trailer Plate')}
    </Col>
  );

  inboundGrainTicket = () => (
    HtmlHelpers.isNotEmpty(this.getItem().inbound_grain_ticket)
      ? this.getRow('inbound_grain_ticket', 'Inbound Grain Ticket')
      : ''
  );

  agCol1 = () => (
    this.getItem().transfer ? (
      <Col md={4} sm={12}>
        {this.getRow('grain_ticket', 'Grain Ticket')}
        {this.inboundGrainTicket()}
      </Col>
    ) : (
      <Col md={4} sm={12}>
        {this.getRow('grain_ticket', 'Grain Ticket')}
        {this.inboundGrainTicket()}
        {this.getRow('delivery_sheet', 'Delivery Sheet')}
      </Col>
    )
  );

  agCol2 = () => (
    this.getItem().transfer ? '' : (
      <Col md={4} sm={12}>
        {this.getRow('invoice_date', 'Invoice Date')}
        {this.getRow('invoice_due_date', 'Invoice Due Date')}
      </Col>
    )
  );

  agCol3 = () => (
    this.getItem().transfer ? '' : (
      <Col md={4} sm={12}>
        {this.getRow('invoice_number', 'Invoice Number')}
        {this.getRow('settlement_number', 'Settlement Number')}
      </Col>
    )
  );

  attachments = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <Attachments
      auth={this.props.auth}
      alert={this.props.alert}
      modelType="Contracts::TruckOrder"
      modelUuid={this.getItem().uuid}
      isAddAdmin
    />
  ));

  exports = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <ExportTable
      auth={this.props.auth}
      modelType="Contracts::TruckOrder"
      modelUuid={this.getItem().uuid}
      showExportButton={false}
    />
  ));

  modalBodyRows = () => (
    <div>
      <Row>
        {this.col1()}
        {this.col2()}
        {this.col3()}
      </Row>
      <br />
      <hr />
      <h4>
        Shipping Details
      </h4>
      <Row>
        {this.shipCol1()}
        {this.shipCol2()}
        {this.shipCol3()}
        {this.shipCol4()}
      </Row>
      <br />
      <hr />
      <h4>
        Agris Details
      </h4>
      <Row>
        {this.agCol1()}
        {this.agCol2()}
        {this.agCol3()}
      </Row>
      {this.attachments()}
      {this.exports()}
      <TruckOrderNotes auth={this.props.auth} uuid={this.getItem().uuid} />
    </div>
  )
}));
