import React from 'react';
import { Button } from 'reactstrap';
import { ColumnButton, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    id: 'contract__contract_id',
    Header: 'Number',
    accessor: 'contract_number',
    Cell: (row) => Links.getLink('contracts', `contracts/view/${row.original.contract_uuid}`, row.original.contract_number),
    className: 'text-center',
    width: 120,
  },
  {
    id: 'contract__purchase_sales',
    Header: 'Type',
    accessor: 'purchase_sales',
    className: 'text-center',
    width: 50,
  },
  {
    id: 'contract__contract_type',
    Header: 'Contract Type',
    accessor: 'contract_type',
    className: 'text-left',
    width: 300,
  },
  {
    id: 'counterparty__name',
    Header: 'Counterparty',
    accessor: 'counterparty_name',
    className: 'text-left',
  },
  {
    id: 'contract__active',
    Header: 'Active',
    accessor: 'contract_active_text',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Pricing Changes',
    accessor: 'pricing_changes_text',
    className: 'text-center',
    width: 120,
  },
  {
    Header: '',
    Cell: (row) => (
      <Button
        color="link"
        className="otto__no_padding"
        onClick={() => (
          context.handleOpenModal(row.original.changes, 'Changes', { contractId: row.original.contract_number })
        )}
      >
        Changes
      </Button>
    ),
    className: 'text-center',
    csvField: (d) => (Object.entries(d.changes).map((data) => (
      `Field: ${data[0]} - Previous Value: ${data[1].previous} - Current Value: ${data[1].current}`
    )).join(', ')),
    filterable: false,
    sortable: false,
    width: 120,
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => (
      <div>
        {row.original.send_email ? (
          <ColumnButton
            className="otto__margin_btm_10"
            onClick={() => context.emailChanges(row.original.contract_uuid, row.original.run_time)}
            text="Email Contract Changes to Customer"
          />
        ) : '' }
        <ColumnButton
          onClick={() => (
            context.emailChanges(row.original.contract_uuid, row.original.run_time, true, row.original.send_email)
          )}
          text="Mark As Reviewed"
        />
      </div>
    ),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 300,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default Columns;
