import { Api, HtmlHelpers, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Items extends OptionsBaseApi {
  getApiName = () => 'items/items';

  getDropdownLabelText = () => this.props.fieldLabel || 'Item';

  getApiParams = () => this.props.apiParams || [];

  getLabelFieldName = () => this.props.valueName || 'item_with_reference';

  getDropdownFieldName = () => this.props.fieldName || 'item_uuid';

  getFilterField = () => this.props.valueName || 'item_with_reference';

  getItemList = () => (
    this.state.items.filter((i) => i.value === this.props.currentValue
      || HtmlHelpers.isEmpty(this.props.used)
      || !this.props.used.includes(i.value))
  );

  getOptions = async (auth) => {
    let options = [];
    if (!this.props.data) {
      await Api.crudList(auth, this.getApiName(), this.getApiParams(), this.getApiVersion())
        .then((response) => {
          if (response.data !== undefined) {
            const optionList = response.data.map((d) => (
              {
                label: d.attributes[this.getLabelFieldName()],
                value: d.attributes[this.getValueFieldName()],
                commodityCodeUuid: d.attributes.commodity_code_uuid,
              }
            ));
            options = this.uniqueArray(optionList);
          }
        });
    }

    this.setState({ items: options });
  }
}
