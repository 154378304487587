import React from 'react';
import { ColumnButton, Links, TableFilters } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    id: 'contract_schedule_number',
    Header: 'Number',
    accessor: (d) => `${d.contract_id} - ${d.number}`,
    Cell: (row) => (
      Links.getLink(
        'contracts',
        `schedules/view/${row.original.uuid}`,
        `${row.original.contract_id} - ${row.original.number}`,
      )
    ),
    className: 'text-center',
    width: 125,
  },
  {
    Header: 'Type',
    accessor: 'contract_purchase_sales',
    className: 'text-center',
    width: 55,
  },
  {
    Header: 'Contract Date',
    accessor: 'contract_date_formatted',
    className: 'text-center',
    width: 125,
  },
  {
    Header: 'Delivery Start',
    accessor: 'delivery_start_formatted',
    className: 'text-center',
    width: 125,
  },
  {
    Header: 'Delivery End',
    accessor: 'delivery_end_formatted',
    className: 'text-center',
    width: 125,
  },
  {
    Header: 'Counterparty',
    accessor: 'counterparty_name',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_names',
    Cell: (row) => (
      row.original.item_names.map((name) => (
        <div>
          {name}
        </div>
      ))
    ),
    className: 'text-left',
  },
  {
    Header: 'Source',
    accessor: 'source_names',
    Cell: (row) => (
      row.original.source_names.map((name) => (
        <div>
          {name}
        </div>
      ))
    ),
    className: 'text-left',
    width: 180,
  },
  {
    Header: 'Remaining QTY',
    accessor: 'remaining_quantity',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Lower Limit',
    accessor: 'lower_limit',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-center',
    width: 60,
  },
  {
    Header: 'Price',
    accessor: 'contract_price',
    className: 'text-right',
    width: 120,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    width: 120,
  },
  {
    id: 'edit',
    Header: '',
    Cell: (row) => (
      <ColumnButton onClick={() => context.adjustSchedule(row.original.uuid)} text="Adjust Schedule" />
    ),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 140,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default Columns;
