import React from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { CaretToggle, Notes } from '@westernmilling/eu_react_shared';

import NoteAddForm from 'components/Forms/NoteAddForm';

export default class VesselOrderNotes extends Notes {
  constructor(props) {
    super(props);
    this.getData();
  }

  addAdmin = () => true;

  addButtonType = () => 'Order';

  title = () => 'Notes';

  modelType = () => 'Contracts::VesselOrder';

  modelUuid = () => this.props.uuid;

  columnsBefore = () => [
    {
      Header: 'Category',
      accessor: 'category',
      className: 'text-left',
      width: 250,
    },
  ];

  addForm = () => (
    !this.state.noteAddModal ? '' : (
      <NoteAddForm
        auth={this.props.auth}
        modelType={this.modelType()}
        modelUuid={this.modelUuid()}
        title={`${this.addButtonType()} Note`}
        showModal={this.state.noteAddModal}
        closeModal={this.closeAddNote}
        updateData={this.updateData}
      />
    )
  );

  getTable = () => (
    <div>
      <hr />
      <Row>
        <Col md="6" xs="12">
          <h4>
            {`${this.title()} (${this.state.loading ? 'loading' : this.state.data.length})`}
            &nbsp;&nbsp;&nbsp;
            <CaretToggle open={this.state.notesShowing} onClick={this.showToggle} />
          </h4>
        </Col>
        <Col md="6" xs="12" className="text-right">
          {this.addButton()}
        </Col>
      </Row>

      <Collapse isOpen={this.state.notesShowing}>
        {this.notesTable()}
      </Collapse>
    </div>
  );
}
