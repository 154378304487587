import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TimeSlotGroups';
import ItemForm from 'components/Forms/TimeSlotGroup';

export default withAuth(class TimeSlotGroupsListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/time_slot_groups';

  getStorageName = () => 'otto_contracts_time_slot_groups_list';

  getPageTitle = () => 'Time Slot Groups';

  getFormTag = () => ItemForm;

  getAddGroups = () => ['time_slot_admin'];

  getEditGroups = () => ['time_slot_admin'];

  getAddText = () => 'Create Time Slot Group';

  auditLogModelType = () => 'Contracts::TimeSlotGroup';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
