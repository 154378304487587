import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Alert } from 'reactstrap';
import { Api, CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import BatchNumbers from 'components/Options/BatchNumbers';
import ShipToField from 'components/Forms/ShipToField';

export default withAuth(withAlert()(class TrucksCheckOutForm extends CrudForm {
  // constructor(props) {
  //   super(props);
  // this.state.disabled = this.isBarcelona() && HtmlHelpers.isEmpty(this.getItem().storage_unit_uuid);
  // }

  componentDidMount() {
    this.getUniwinWeights();
  }

  formType = () => '';

  getCrudType = () => 'contracts/truck_orders';

  successAlertMsg = () => this.successMsg('Truck Order');

  submitLabel = () => 'Check Truck Out';

  headerTitle = () => 'Truck Check Out'

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  getModalSize = () => 'xl';

  buildApiVariables = () => ({ ...this.getItem(), truck_check_out: 'Y' });

  // Add following line to disabledWeights when we add storage unit functionality
  // || (this.isBarcelona() && HtmlHelpers.isEmpty(this.getItem().storage_unit_uuid))
  disabledWeights = (response) => (
    HtmlHelpers.isEmpty(response.data?.attributes.tare_weight) && HtmlHelpers.isEmpty(this.getItem().tare_weight)
  );

  getUniwinWeights = () => {
    this.setState({ loading: true });
    Api.crudView(this.props.auth, 'uniwin/uniwin_move', this.getItem().id)
      .then((response) => {
        if (response.data !== undefined) {
          this.setState({
            tareWeight: response.data?.attributes.tare_weight,
            grossWeight: response.data?.attributes.gross_weight,
            netWeight: response.data?.attributes.net_weight,
            disabled: this.disabledWeights(response),
            loading: false,
          });
        }
      });
  }

  options = () => (
    {
      batch: {
        component: BatchNumbers,
        field: 'batch_number',
        props: { isRequired: true, itemUuid: this.getItem().item_uuid, agrisBin: this.getItem().agris_bin_desc },
      },
    }
  );

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    const link = document.createElement('a');
    link.href = data.attributes.bill_of_lading_pdf_url;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    this.props.updateData(data);
  }

  shipToEntityUuid = () => (
    HtmlHelpers.isEmpty(this.getItem().endorsee_entity_uuid)
      ? this.getItem().bill_to_entity_uuid
      : this.getItem().endorsee_entity_uuid
  );

  shipTo = () => (
    this.getItem().transfer ? '' : (
      <ShipToField
        auth={this.props.auth}
        entityUuid={this.shipToEntityUuid()}
        item={this.getItem()}
        onChange={this.onChange}
        isRequired
      />
    )
  );

  uniwinAlert = () => (
    this.isBarcelona() ? (
      <Alert color="warning" className="text-center">
        No Uniwin Weight Available
      </Alert>
    ) : ''
  );

  weightDisplay = () => (
    HtmlHelpers.isEmpty(this.getItem().tare_weight) ? (
      <Alert color="danger" className="text-center">
        Tare Weight is needed to check out
      </Alert>
    ) : (
      <div>
        {this.uniwinAlert()}
        {this.getNumericFieldRow('gross_weight', 'Gross Weight (kg)', true, { step: 1, min: 1 })}
        {this.isBarcelona() ? this.getTextareaRow('manual_weight_reason', 'Reason', true) : ''}
      </div>
    )
  );

  // Call this method on line 149 when we add storage unit functionality
  storageUnitValidation = () => (
    this.isBarcelona() && HtmlHelpers.isEmpty(this.getItem().storage_unit_uuid) ? (
      <Alert color="danger" className="text-center">
        Storage Unit is needed to check out
      </Alert>
    ) : ''
  )

  manualWeight = () => (HtmlHelpers.isEmpty(this.state.grossWeight) && !this.state.loading ? this.weightDisplay() : '');

  isBarcelona = () => this.getItem().agris_bin_desc === this.props.additionalFormProps.barcelona;

  modalBodyRows = () => (
    <div>
      {this.getTextRow('Order ID', this.getItem().id)}
      {this.getTextRow('Carrier', this.getItem().carrier_name)}
      {this.getTextRow('Tare Weight (kg)', this.getItem().tare_weight || this.state.tareWeight)}
      {this.getTextRow('Gross Weight (kg)', this.state.grossWeight)}
      {this.getTextRow('Net Weight (kg)', this.state.netWeight)}
      <hr />
      {this.getTextFieldRow('driver_name', 'Driver')}
      {this.getTextFieldRow('driver_id', 'Driver ID')}
      {this.getTextFieldRow('truck_plate', 'Truck Plate')}
      {this.getTextFieldRow('trailer_plate', 'Trailer Plate', false)}
      {this.shipTo()}
      {this.isBarcelona() ? this.getTextFieldRow('rfid', 'RFID') : ''}
      {this.isBarcelona() ? this.getTextFieldRow('seals', 'Seal', false) : ''}
      {this.dropdownField('batch')}
      {this.manualWeight()}
    </div>
  )
}));
