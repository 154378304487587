import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Api, CrudView, HtmlHelpers, ModalConfirmation,
} from '@westernmilling/eu_react_shared';

import ActionLinks from 'components/ActionMenus/VesselOrderView';
import ItemForm from 'components/Forms/VesselOrder';
import ItemView from 'components/Forms/VesselOrderView';
import DestinationWeightModal from 'components/Modals/DestinationWeightModal';

export default withAuth(withAlert()(class VesselOrderViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
  }

  editForm = () => ItemForm;

  getItem = () => (this.state.data || [])[0] || {};

  getSection = () => (
    {
      type: 'contracts/vessel_orders',
      form: ItemView,
      title: 'View Vessel Order',
      listRoute: `../../../${this.props.match.params.type}_vessel_orders`,
      listLabel: 'Order List',
      edit_admins: [],
    }
  );

  additionalButtonsAfterList = () => (
    <span>
      <ActionLinks
        linkInfo={this.state.linkInfo || {}}
        uuid={this.getItem().uuid}
        item={this.getItem()}
        openModal={this.handleOpenModal}
        createExport={this.createExport}
      />
      {this.destinationWeightModal()}
      {this.reverseGrainTicket()}
    </span>
  );

  updateData = () => {
    this.setState({
      data: null,
      showModalDestinationWeight: null,
      showModalReverseExport: null,
    });
    this.fetchItem();
  }

  createExport = () => {
    const data = { uuid: this.getUuid() };
    Api.crudCreate(this.props.auth, 'contracts/vessel_order_grain_tickets', data)
      .then((response) => { this.handleResponse(response, 'Grain ticket Export started successfully'); });
  }

  destinationWeightModal = () => {
    let contents = '';
    if (this.state.showModalDestinationWeight) {
      contents = (
        <DestinationWeightModal
          showModal={this.state.showModalDestinationWeight}
          closeModal={() => this.handleCloseModal('DestinationWeight')}
          disabled={this.state.disableDestinationWeight}
          item={this.state.data[0]}
          includes={this.state.includes}
          orderType="Vessel"
          path="vessel_orders"
          type="edit"
          updateData={this.updateData}
        />
      );
    }
    return contents;
  };

  reverseExportApi = () => {
    const data = { uuid: this.getUuid() };
    this.setState({ disableReverseExport: true });
    Api.crudUpdate(this.props.auth, 'contracts/vessel_order_grain_tickets', data)
      .then((response) => { this.handleResponse(response, 'Export successfully reversed'); })
      .then(() => this.handleCloseModal('ReverseExport'));
  };

  reverseGrainTicket = () => {
    let contents = '';
    if (this.state.showModalReverseExport) {
      contents = (
        <ModalConfirmation
          msg="Are you sure you want to reverse the export on this order?"
          onConfirmation={this.reverseExportApi}
          showModal={this.state.showModalReverseExport}
          closeModal={() => this.handleCloseModal('ReverseExport')}
          disabled={this.state.disableReverseExport}
        />
      );
    }
    return contents;
  };
}));
