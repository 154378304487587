import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Api, CrudView, HtmlHelpers, ModalConfirmation,
} from '@westernmilling/eu_react_shared';

import ActionLinks from 'components/ActionMenus/TruckOrderView';
import ItemForm from 'components/Forms/TruckOrderActionEdit';
import ReverseExportModal from 'components/Modals/ReverseExportModal';
import TransferForm from 'components/Forms/TransferTruckOrder';
import ItemView from 'components/Forms/TruckOrderView';
import TruckOrderManualApptModal from 'components/Modals/TruckOrderManualApptModal';
import TruckOrderManualWeightModal from 'components/Modals/TruckOrderManualWeightModal';

export default withAuth(withAlert()(class TruckOrderViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
  }

  editForm = () => ItemForm;

  // TODO: getItem exists already
  getItem = () => (this.state.data || [])[0] || {};

  getSection = () => (
    {
      type: 'contracts/truck_orders',
      form: ItemView,
      title: 'View Truck Order',
      listRoute: `../../${this.props.type}`,
      listLabel: 'Order List',
      edit_admins: [],
    }
  );

  additionalButtonsAfterList = () => (
    <span>
      <ActionLinks
        linkInfo={this.state.linkInfo || {}}
        uuid={this.getItem().uuid}
        item={this.getItem()}
        openModal={this.handleOpenModal}
        createExport={this.createExport}
      />
      {this.cancelModal()}
      {this.manualApptModal()}
      {this.manualWeightModal()}
      {this.reverseGrainTicket()}
      {this.transferModal()}
    </span>
  );

  additionalFormProps = () => ({ barcelona: this.state.additionalInfo.barcelona });

  updateData = () => {
    this.setState({
      data: null,
      showModalManualAppt: null,
      showModalManualWeight: null,
      showModalReverseExport: null,
      showModalTransfer: null,
    });
    this.fetchItem();
  }

  cancelApi = () => {
    const data = { uuid: this.getUuid() };
    this.setState({ disableCancel: true });
    Api.crudDelete(this.props.auth, 'contracts/truck_orders', data)
      .then((response) => { this.handleResponse(response, 'Sucessfully canceled the truck order'); })
      .then(() => this.handleCloseModal('Cancel'));
  };

  cancelModal = () => {
    let contents = '';
    if (this.state.showModalCancel) {
      contents = (
        <ModalConfirmation
          msg="Are you sure you want to cancel this truck order?"
          onConfirmation={this.cancelApi}
          showModal={this.state.showModalCancel}
          closeModal={() => this.handleCloseModal('Cancel')}
          disabled={this.state.disableCancel}
        />
      );
    }
    return contents;
  };

  createExport = () => {
    const data = { uuid: this.getUuid() };
    Api.crudCreate(this.props.auth, 'contracts/truck_order_grain_tickets', data)
      .then((response) => { this.handleResponse(response, 'Grain ticket Export started successfully'); });
  }

  reverseGrainTicket = () => (
    this.state.showModalReverseExport ? (
      <ReverseExportModal
        showModal={this.state.showModalReverseExport}
        closeModal={() => this.handleCloseModal('ReverseExport')}
        disabled={this.state.disableReverseExport}
        item={this.state.data[0]}
        includes={this.state.includes}
        type="edit"
        updateData={this.updateData}
      />
    ) : ''
  );

  transferModal = () => {
    let contents = '';
    if (this.state.showModalTransfer) {
      contents = (
        <TransferForm
          showModal={this.state.showModalTransfer}
          closeModal={() => this.handleCloseModal('Transfer')}
          disabled={this.state.disableTransfer}
          data={this.state.data}
          includes={this.state.includes}
          type="edit"
          editItem={0}
          updateData={this.updateData}
          englishLanguageUuid={this.state.englishLanguageUuid}
          additionalFormProps={this.additionalFormProps()}
        />
      );
    }
    return contents;
  };

  manualWeightModal = () => {
    let contents = '';
    if (this.state.showModalManualWeight) {
      contents = (
        <TruckOrderManualWeightModal
          showModal={this.state.showModalManualWeight}
          closeModal={() => this.handleCloseModal('ManualWeight')}
          disabled={this.state.disableManualWeight}
          item={this.state.data[0]}
          includes={this.state.includes}
          type="edit"
          updateData={this.updateData}
        />
      );
    }
    return contents;
  };

  manualApptModal = () => {
    let contents = '';
    const uuid = HtmlHelpers.isEmpty(this.state.data) ? '' : this.state.data[0].uuid;
    if (this.state.showModalManualAppt) {
      contents = (
        <TruckOrderManualApptModal
          showModal={this.state.showModalManualAppt}
          closeModal={() => this.handleCloseModal('ManualAppt')}
          disabled={this.state.disableManualAppt}
          updateData={this.updateData}
          uuid={uuid}
        />
      );
    }
    return contents;
  };
}));
