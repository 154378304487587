import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    id: 'checkbox',
    accessor: '',
    className: 'text-center',
    Cell: ({ original }) => (
      <input
        type="checkbox"
        className="checkbox"
        checked={context.state.bulkCancel[original.uuid] === true}
        onChange={() => context.toggleSelectedTruck(original.uuid)}
      />
    ),
    filterable: false,
    Header: () => (
      <input
        type="checkbox"
        className="checkbox"
        checked={context.state.selectAll}
        onChange={() => context.toggleSelectAll()}
      />
    ),
    sortable: false,
    csvShow: false,
    show: context.isEditAdmin(),
    width: 45,
  },
  {
    Header: 'Order ID',
    accessor: 'id',
    Cell: (row) => Links.getLink('contracts', `truck_orders/view/${row.original.uuid}`, row.original.id),
    className: 'text-center',
    width: 100,
  },
  {
    id: 'contract_schedule_number',
    Header: 'Contract Schedule',
    accessor: (d) => `${d.contract_id} - ${d.contract_schedule_number}`,
    Cell: (row) => {
      let display = '';
      if (HtmlHelpers.isNotEmpty(row.original.contract_schedule_number)) {
        const name = `${row.original.contract_id} - ${row.original.contract_schedule_number}`;
        display = Links.getLink('contracts', `schedules/view/${row.original.contract_schedule_uuid}`, name);
      }
      return display;
    },
    className: 'text-center',
    width: 130,
  },
  {
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
  },
  {
    id: 'bill_to_entity__name',
    Header: 'Bill To',
    accessor: 'bill_to_name',
    className: 'text-left',
  },
  {
    id: 'customer__name',
    Header: 'Endorsee',
    accessor: 'customer_name',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (<pre>{row.original.ship_to}</pre>),
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'quantity',
    Header: 'Quantity',
    accessor: (d) => d.quantity_shipped || d.quantity_ordered,
    className: 'text-right',
    width: 120,
  },
  {
    id: 'unit_of_measure__symbol',
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-right',
    width: 60,
  },
  {
    id: 'pickup_shipped',
    Header: 'Pickup Date',
    accessor: (d) => d.shipped_date || d.pickup_date || '',
    className: 'text-center',
    width: 100,
  },
];

export default Columns;
