import { HtmlHelpers, LinksActionData } from '@westernmilling/eu_react_shared';

export default class TruckOrderView extends LinksActionData {
  menuItems = () => {
    const uuid = this.props.uuid;
    const info = this.props.linkInfo;
    const arr = [];
    if (HtmlHelpers.isNotEmpty(uuid)) {
      arr.push(
        this.cancelInfo(info.cancel),
        this.createExportInfo(info.create_export),
        this.manualApptInfo(info.add_manual_appt),
        this.manualWeightInfo(info.add_manual_weight),
        this.reverseExportInfo(info.reverse_export),
      );
      if (this.props.item.transfer) {
        arr.push(this.editTransferInfo(info.edit_transfer));
      } else {
        arr.push(this.editInfo(info.edit));
      }
    }
    return arr.sort((a, b) => a.label.localeCompare(b.label));
  }

  cancelInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('Cancel'));

  createExportInfo = (info) => this.buildInfo(info, info.label, () => this.props.createExport());

  editInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal());

  editTransferInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('Transfer'));

  manualApptInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('ManualAppt'));

  manualWeightInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('ManualWeight'));

  reverseExportInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('ReverseExport'));
}
