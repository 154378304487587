import { Api, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class MarketZones extends OptionsBaseApi {
  getApiName = () => 'agris/market_zones';

  getDropdownLabelText = () => this.props.fieldLabel || 'Market Zone';

  getDropdownFieldName = () => this.props.fieldName || 'market_zone_code';

  getValueFieldName = () => 'name';

  additionalFields = () => ['code'];
}
