import React from 'react';
import { ColumnButton, ColumnHelpers, HtmlHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Start Time',
    accessor: 'start_time',
    className: 'text-center',
  },
  {
    Header: 'End Time',
    accessor: 'end_time',
    className: 'text-center',
  },
  {
    Header: '# of Appointments',
    accessor: 'num_appts',
    className: 'text-center',
  },
];

export default Columns;
