import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, TableTitle } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/ContractSchedules';

export default withAuth(withAlert()(class ContractSchedules extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/contract_schedules';

  getListParams = () => ({ query: `contract_uuid='${this.props.contract.uuid}'` })

  getStorageName = () => 'otto_contract_contract_schedules_table_list';

  getEditGroups = () => ['execution_admin'];

  getPageTitle = () => 'Contract Schedules';

  auditLogModelType = () => 'Contracts::ContractSchedule';

  getTableClassSettings = () => 'otto__short_table';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  smallTitle = () => (
    <Row>
      <Col md="8" className="text-left">
        <TableTitle title={this.getPageTitle()} />
      </Col>
    </Row>
  );
}));
