import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

import Items from 'components/Options/Items';

export default withAuth(withAlert()(class ContractItem extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.contract_uuid = props.additionalFormProps.contractUuid;
  }

  formType = () => 'Contract Item';

  getCrudType = () => 'contracts/contract_items';

  successAlertMsg = () => this.successMsg('Contract Item');

  options = (idx) => (
    {
      items: {
        component: Items,
        field: 'item_uuid',
        label: 'Variety',
        props: {
          isRequired: true,
          used: this.props.additionalFormProps.usedItems,
          apiParams: ({
            filtered: JSON.stringify(
              [
                { id: 'active', value: true },
                { id: 'commodity_code_uuid', value: this.props.additionalFormProps.commodityCodeUuid },
              ],
            ),
            show_all: 'true',
          }),
        },
        disabled: !this.isNew(),
        currentValue: this.getItem().item_uuid,
      },
    }
  );

  modalBodyRows = () => (
    <div>
      {this.props.additionalFormProps.commodityCodeUuid ? this.dropdownField('items') : ''}
      {this.getNumericFieldRow('premium', 'Premium')}
    </div>
  )
}));
