import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class ManualWeightEntryForm extends CrudForm {
  formType = () => `Tare Weight - Truck Order #${this.getItem().id}`;

  submitLabel = () => `${this.headerTitle()} Tare Weight`;

  getCrudType = () => 'contracts/truck_orders';

  successAlertMsg = () => this.successMsg('Tare Weight');

  isNew = () => HtmlHelpers.isEmpty(this.props.data[this.props.editItem].tare_weight);

  buildApiVariables = () => ({ ...this.getItem(), add_first_weight: 'Y' });

  isBarcelona = () => this.getItem().agris_bin_desc === this.props.additionalFormProps.barcelona;

  modalBodyRows = () => (
    <div>
      { this.getNumericFieldRow('tare_weight', 'Tare Weight (kg)', true, { step: 1, min: 1 }) }
      { this.isBarcelona() ? this.getTextareaRow('manual_weight_reason', 'Reason', true) : '' }
    </div>
  )
}));
