import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Invoices';

export default withAuth(class InvoiceListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'credit/invoices';

  getListParams = () => ({ sorted: [{ id: 'invoice_number', desc: true }] });

  getStorageName = () => 'otto_credit_invoices_list';

  getPageTitle = () => 'Invoices';

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getWidthCenter = () => 10;

  getWidthLeft = () => 1;

  getWidthRight = () => 1;
});
