import React from 'react';
import { Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    id: 'contract_schedule_number',
    Header: 'Number',
    accessor: (d) => `${d.contract_id} - ${d.number}`,
    Cell: (row) => (
      Links.getLink(
        'contracts',
        `schedules/view/${row.original.uuid}`,
        `${row.original.contract_id} - ${row.original.number}`,
      )
    ),
    className: 'text-center',
    width: 125,
  },
  {
    Header: 'Type',
    accessor: 'contract_purchase_sales',
    className: 'text-center',
    width: 40,
  },
  {
    Header: 'Delivery Start',
    accessor: 'delivery_start_formatted',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Delivery End',
    accessor: 'delivery_end_formatted',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Counterparty',
    accessor: 'counterparty_name',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_names',
    Cell: (row) => (
      row.original.item_names.map((name) => (
        <div>
          {name}
        </div>
      ))
    ),
    className: 'text-left',
  },
  {
    Header: 'Source',
    accessor: 'source_names',
    Cell: (row) => (
      row.original.source_names.map((name) => (
        <div>
          {name}
        </div>
      ))
    ),
    className: 'text-left',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    className: 'text-right',
    width: 100,
  },
  {
    Header: 'Qty Shipped',
    accessor: 'quantity_shipped',
    className: 'text-right',
    width: 100,
  },
  {
    Header: 'Unnominated',
    accessor: 'balance',
    className: 'text-right',
    width: 100,
  },
  {
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-center',
    width: 50,
  },
  {
    Header: 'Premium',
    accessor: 'contract_premium',
    className: 'text-right',
    width: 100,
  },
  {
    Header: 'Basis',
    accessor: 'contract_basis',
    className: 'text-right',
    width: 100,
  },
  {
    Header: 'Futures',
    accessor: 'contract_futures',
    className: 'text-right',
    width: 100,
  },
  {
    Header: 'FX Rate',
    accessor: 'contract_fx',
    className: 'text-right',
    width: 100,
  },
  {
    Header: 'Flat Price',
    accessor: 'contract_price',
    className: 'text-right',
    width: 100,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
  },
];

export default Columns;
