import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  AddIcon, Api, CrudList, HtmlHelpers,
} from '@westernmilling/eu_react_shared';
import { Col, Row } from 'reactstrap';

export default class UpdateTablesListContainer extends CrudList {
  getWidthCenter = () => 6;

  getWidthLeft = () => 3;

  getWidthRight = () => 3;

  canDownloadCsv = () => true;

  getAddText = () => 'Update Agris Tables';

  updateTables = () => {
    Api.crudCreate(this.props.auth, 'agris/update_tables').then((response) => {
      this.props.alert.show('Starting Agris Tables Update', { type: 'success' });
    });
  }

  smallTitle = (caret = false) => (
    <div>
      <Row>
        <Col md="8" className="text-left">
          { caret ? this.getTitle('table') : this.getTitle('page')}
        </Col>
        <Col md="4" className="align-self-center text-right">
          { this.createButton() }
        </Col>
      </Row>
      <Row>
        <Col md="9" />
        <Col md="3" className="text-right">
          {`As of: ${this.state.additonalInfo?.last_updated}`}
        </Col>
      </Row>
    </div>
  );

  createButton = () => (
    <div>
      <AddIcon className="otto__margin_btm_30" onClick={this.updateTables} addText={this.getAddText()} />
    </div>
  )
}
