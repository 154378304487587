import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, TableTitle } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TruckOrders';

export default withAuth(withAlert()(class EndorseeTruckOrders extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/truck_orders';

  getListParams = () => ({
    filtered: [{ id: 'endorsement_num', value: this.props.nomination.id }],
    show_all: 'true',
    nomination_uuid: this.props.nomination.uuid,
  });

  getStorageName = () => 'otto_truck_orders_table_list';

  getPageTitle = () => ('Endorsee Truck Orders');

  auditLogModelType = () => 'Contracts::TruckOrder';

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  smallTitle = () => (
    <Row>
      <Col md="8" className="text-left">
        <TableTitle title={this.getPageTitle()} />
      </Col>
      <Col md="4" className="align-self-center text-right">
        { this.isAddAdmin() ? this.createButton() : ''}
      </Col>
    </Row>
  );
}));
