import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudList, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/ContractChanges';
import ContractChanges from 'components/Tables/ContractChanges';

export default withAuth(withAlert()(class ContractChangesListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/contract_changes';

  getListParams = () => ({ show_all: 'true' });

  getStorageName = () => 'otto_contracts_contract_changes_list';

  getPageTitle = () => 'Contract Amendments for Review';

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  // TODO: Figure out with pagination, counting number of contract_changes not distinct number of contracts.

  // loadOnMount = () => '';

  // indexTable = () => this.paginationTable();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  canDownloadCsv = () => true;

  emailChanges = (uuid, timestamp, reviewOnly = false, createNewPdf = false) => {
    Api.crudUpdate(this.props.auth, 'contracts/contract_changes', {
      uuid, timestamp, reviewOnly, createNewPdf,
    })
      .then((response) => {
        if (response.data !== undefined) {
          this.props.alert.show('Contract changes reviewed successfully', { type: 'success' });
          this.refreshAfterUpdate();
        } else if (response.errors !== undefined) {
          const errors = HtmlHelpers.convertRailsErrors(response.errors);
          this.props.alert.show(errors.join(', '), { type: 'error' });
        } else {
          this.props.alert.show(
            'System error, please try again. If you continue to get this error, open a Help Desk ticket',
            { type: 'error' },
          );
        }
      });
  }

  additionalContent = () => {
    let data = [];
    if (HtmlHelpers.isNotEmpty(this.state.selectedItemChanges)) {
      data = this.state.selectedItemChanges.map((row) => (
        {
          field_name: row.field_name,
          previous_value: row.previous,
          current_value: row.current,
          changed_by: row.changed_by,
          created_at: row.created_at,
        }
      ));
    }
    return (
      this.state.showModalChanges ? (
        <ContractChanges
          closeModal={() => this.handleCloseModal('Changes')}
          contractId={this.state.selectedOptionsChanges.contractId}
          data={data}
          disabled={this.state.disableChanges}
          showModal={this.state.showModalChanges}
        />
      ) : ''
    );
  };
}));
