import React from 'react';
import { CrudForm } from '@westernmilling/eu_react_shared';

import FinalUsages from 'components/Options/FinalUsages';
import GradeBases from 'components/Options/GradeBase';
import UnitOfMeasure from 'components/Options/UnitOfMeasure';
import WeightBases from 'components/Options/WeightBase';

export default class Quantity extends CrudForm {
  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.data || {};

  options = () => (
    {
      finalUsage: {
        component: FinalUsages,
        field: 'final_usage',
        props: { isRequired: true },
        currentValue: this.getItem().final_usage,
      },
      grades: {
        component: GradeBases,
        field: 'grade_base',
        props: { isRequired: true },
        currentValue: this.getItem().grade_base,
      },
      uoms: {
        component: UnitOfMeasure,
        field: 'unit_of_measure_uuid',
        props: { isRequired: true },
        currentValue: this.getItem().unit_of_measure_uuid,
      },
      weights: {
        component: WeightBases,
        field: 'weight_base',
        props: { isRequired: true },
        currentValue: this.getItem().weight_base,
      },
    }
  );

  limitTypeField = () => (
    this.getToggleRadioField(
      { label: 'Weight', value: 'Weight' },
      { label: 'Percentage', value: 'Percentage' },
      'limit_type',
      'Limit Type',
      true,
      false,
      { value: this.getItem().limit_type },
    )
  );

  modalBodyRows = () => (
    <div>
      {this.getNumericFieldRow('num_schedules', '# of Schedules', true, { min: 1, value: this.getItem().num_schedules })}
      {this.getNumericFieldRow('quantity', 'Quantity', true, { value: this.getItem().quantity })}
      {this.dropdownField('uoms')}
      {this.limitTypeField()}
      {this.getNumericFieldRow('lower_limit', 'Lower Limit', true, { value: this.getItem().lower_limit })}
      {this.getNumericFieldRow('higher_limit', 'Higher Limit', true, { value: this.getItem().higher_limit })}
      {this.dropdownField('weights')}
      {this.dropdownField('grades')}
      {this.dropdownField('finalUsage')}
      {this.getTextareaRow('observations', 'Observations', false, this.getItem().observations)}
      <br />
    </div>
  );

  submitButtons = () => (
    <div className="text-center">
      {this.getButton('Back', (event) => this.props.continue(event, 1))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Continue', (event) => this.props.continue(event, 3))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Cancel', this.closeModal)}
    </div>
  );

  modalPage = () => (
    <div>
      { this.modalBodyRows() }
      { this.showButtonsAndErrors() ? this.buttonsAndErrors() : '' }
      { this.underButtons() }
    </div>
  )
}
