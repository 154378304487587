import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { CrudForm, HtmlHelpers, WmLabel } from '@westernmilling/eu_react_shared';

import CommodityBoards from 'components/Options/CommodityBoards';
import ContractType from 'components/Options/ContractType';
import Currencies from 'components/Options/Currencies';
import UnitOfMeasure from 'components/Options/UnitOfMeasure';

export default class Pricing extends CrudForm {
  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.data || {};

  price = (field, label, buttonAfter, required = true) => this.getNumericFieldRow(
    field,
    label,
    required,
    { value: this.getItem()[field], buttonAfter: (buttonAfter === ' ()' ? '' : buttonAfter) },
  );

  getFlatPrice = () => (
    this.getItem().contract_type === 'Flat' ? (
      <div>
        {this.price('contract_price', 'Contract Price', `${this.getItem().flatUom} (${this.getItem().currency})`)}
      </div>
    ) : ''
  );

  isBasis = () => HtmlHelpers.isNotEmpty(this.getItem().contract_type)
    && !['NPE', 'Flat'].includes(this.getItem().contract_type);

  isPremium = () => this.getItem().contract_type === 'Premium Over Premium';

  getBasisPricing = () => (
    this.isBasis() ? (
      <div>
        { this.isPremium() ? this.dropdownField('currency') : '' }
        { this.dropdownField('uoms') }
        { this.isPremium() ? this.price('contract_premium', 'Premium', ' ()') : '' }
        { this.price(
          'basis',
          'Basis',
          `${this.getItem().basisUom || ''} (${this.getItem().basisCurrency || ''})`,
          this.getItem().contract_type !== 'Premium Over Premium',
        ) }
      </div>
    ) : ''
  );

  options = () => (
    {
      board: {
        component: CommodityBoards,
        field: 'commodity_board_uuid',
        currentValue: this.getItem().commodity_board_uuid,
        props: { isRequired: this.isBasis() },
      },
      currency: {
        component: Currencies,
        field: 'basis_currency_uuid',
        label: 'Basis Currency',
        props: { isRequired: true },
        currentValue: this.getItem().basis_currency_uuid,
      },
      types: {
        component: ContractType,
        field: 'contract_type',
        props: { isRequired: true },
        currentValue: this.getItem().contract_type,
      },
      uoms: {
        component: UnitOfMeasure,
        field: 'basis_unit_of_measure_uuid',
        label: 'Basis UOM',
        props: { isRequired: true },
        currentValue: this.getItem().basis_unit_of_measure_uuid,
      },
    }
  );

  modalBodyRows = () => (
    <div>
      { this.dropdownField('types') }
      { this.dropdownField('board') }
      { this.getFlatPrice() }
      { this.getBasisPricing() }
      <br />
    </div>
  );

  submitButtons = () => (
    <div className="text-center">
      {this.getButton('Back', (event) => this.props.continue(event, 4))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Continue', (event) => this.props.continue(event, 6))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Cancel', this.closeModal)}
    </div>
  );

  modalPage = () => (
    <div>
      { this.modalBodyRows() }
      { this.showButtonsAndErrors() ? this.buttonsAndErrors() : '' }
      { this.underButtons() }
    </div>
  )
}
