import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Time Slot',
    accessor: 'time_slot_range',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Weekday',
    accessor: 'weekday',
    className: 'text-center',
    width: 100,
  },
  {
    id: 'truck_order__pickup',
    Header: 'Pickup Date',
    accessor: 'pickup_date',
    className: 'text-center',
    width: 100,
  },
  {
    id: 'time_slot_group__name',
    Header: 'Group',
    accessor: 'group_name',
    className: 'text-left',
  },
  {
    id: 'time_slot__source_location',
    Header: 'Source Location',
    accessor: 'source_location',
    className: 'text-left',
  },
  {
    id: 'truck_order__id',
    Header: 'Order ID',
    accessor: 'order_id',
    Cell: (row) => (row.original.company_blocked ? 'BLOCKED' : row.original.order_id),
    className: 'text-center',
    width: 100,
  },
  {
    id: 'bill_to_entity__name',
    Header: 'Bill To',
    accessor: 'bill_to',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (<pre>{row.original.ship_to}</pre>),
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => (
      row.original.company_blocked
        ? <ColumnButton onClick={() => context.availability(row.original.uuid, 'Available')} text="Make Available" />
        : (
          <ColumnButton
            onClick={() => context.availability(row.original.uuid, 'Unavailable')}
            text="Make Time Slot Unavailable"
          />
        )
    ),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 200,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default Columns;
