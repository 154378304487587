import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Button, Col, Row } from 'reactstrap';
import {
  Api, CrudList, FunctionsList, HtmlHelpers, ModalConfirmation,
} from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TimeSlotSpecialDay';
import TimeSlotDay from 'components/Forms/TimeSlotDay';

export default withAuth(withAlert()(class TimeSlotSpecialDay extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/time_slot_day_times';

  getFormTag = () => true;

  getListParams = () => ({
    query: `time_slot_day_uuid='${this.props.uuid}'`,
  });

  getStorageName = () => 'otto_time_slot_days_list';

  getAddGroups = () => this.props.addGroups || [];

  getPageTitle = () => this.props.item.special_date;

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

  getWidthCenter = () => 8;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;

  getContent = () => this.contentCollapsible();

  updateData = () => {
    this.setState({
      showModal: false, selectedItem: null, loading: true, showModalInactivate: false, selectedItemInactivate: null,
    });
    this.props.updateData();
    this.refreshAfterUpdate();
  }

  handleResponse = (response, message) => {
    if (response.data !== undefined) {
      this.props.alert.show(message, { type: 'success' });
      this.updateData();
    } else if (response.errors !== undefined) {
      const errors = HtmlHelpers.convertRailsErrors(response.errors);
      this.props.alert.show(errors.join(', '), { type: 'error' });
    } else {
      this.props.alert.show(
        'System error, please try again. If you continue to get this error, open a Help Desk ticket',
        { type: 'error' },
      );
    }
  };

  deleteTimeSlot = () => {
    const data = { uuid: this.props.item.uuid };
    this.setState({ disableDelete: true });
    Api.crudDelete(this.props.auth, 'contracts/time_slot_days', data)
      .then((response) => { this.handleResponse(response, 'Sucessfully deleted the special day'); })
      .then(() => this.handleCloseModal('Delete'));
  };

  deleteButton = () => (
    this.props.item.can_delete ? (
      <Button className="btn-sm" onClick={() => this.handleOpenModal(this.props.item, 'Delete')} outline>
        Delete
      </Button>
    ) : ''
  )

  createButton = () => (
    this.getFormTag() === '' ? '' : (
      <Button className="btn-sm" outline onClick={this.handleOpenModal}>
        Add Time Slot
      </Button>
    )
  )

  additionalContent = () => (
    this.state.showModalDelete ? (
      <ModalConfirmation
        msg="Are you sure you want to delete this special day?"
        onConfirmation={this.deleteTimeSlot}
        showModal={this.state.showModalDelete}
        closeModal={() => this.handleCloseModal('Delete')}
        disabled={this.state.disableDelete}
      />
    ) : ''
  );

  smallTitle = (caret = false) => (
    <Row>
      <Col md="8" className="text-left">
        { caret ? this.getTitle('table') : this.getTitle('page')}
      </Col>
      <Col md="4" className="align-self-center text-right">
        { this.isAddAdmin() ? this.deleteButton() : '' }
        &nbsp;&nbsp;&nbsp;
        { this.isAddAdmin() ? this.createButton() : ''}
      </Col>
    </Row>
  );

  getForm = () => (
    <TimeSlotDay
      userGroups={this.state.userGroups}
      weekday={this.props.item.special_date}
      timeSlotDayUuid={this.props.item.uuid}
      showModal={this.state.showModal}
      closeModal={this.handleCloseModal}
      updateData={this.updateData}
      specialDate={this.props.item.special_date}
      specialDay
    />
  );
}));
