import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, FunctionsList, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TimeSlotDays';
import TimeSlotDay from 'components/Forms/TimeSlotDay';
import Inactivate from 'components/Forms/Inactivate';

export default withAuth(withAlert()(class TimeSlotDays extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/time_slot_day_times';

  getFormTag = () => true;

  getListParams = () => ({
    query: `cast(weekday as text) = '${this.props.weekday}' and time_slot_uuid='${this.props.timeSlotUuid}'`,
  });

  getStorageName = () => 'otto_time_slot_days_list';

  getAddGroups = () => ['time_slot_admin'];

  getPageTitle = () => this.props.weekday;

  getAddText = () => 'Add Time Slot';

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

  getWidthCenter = () => 10;

  getWidthLeft = () => 1;

  getWidthRight = () => 1;

  getContent = () => this.contentCollapsible();

  createButton = () => this.createButtonSmall();

  updateData = () => {
    this.setState({
      showModal: false, selectedItem: null, loading: true, showModalInactivate: false, selectedItemInactivate: null,
    });
    this.refreshAfterUpdate();
  }

  getForm = () => (
    <TimeSlotDay
      userGroups={this.state.userGroups}
      weekday={this.props.weekday}
      timeSlotDayUuid={this.props.dayUuid}
      showModal={this.state.showModal}
      closeModal={this.handleCloseModal}
      updateData={this.updateData}
    />
  );

  additionalContent = () => (
    this.state.showModalInactivate ? (
      <Inactivate
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItemInactivate}
        showModal={this.state.showModalInactivate}
        closeModal={() => this.handleCloseModal('Inactivate')}
        updateData={this.updateData}
      />
    ) : ''
  );
}));
