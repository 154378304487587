import { HtmlHelpers, LinksActionData } from '@westernmilling/eu_react_shared';

export default class ContractView extends LinksActionData {
  menuItems = () => {
    const uuid = this.props.uuid;
    const info = this.props.linkInfo;
    return HtmlHelpers.isEmpty(uuid) ? [] : [
      this.editInfo(info.edit),
      this.accrualInfo(info.add_contract_accruals),
      this.signedInfo(info.signed),
    ];
  }

  editInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal());

  cancelInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('Cancel'));

  accrualInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('Accrural'));

  signedInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('Signed'));
}
