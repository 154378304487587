import React from 'react';
import { ColumnHelpers, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Contract ID',
    accessor: 'contract_id',
    Cell: (row) => Links.getLink('contracts', `contracts/view/${row.original.uuid}`, row.original.contract_id),
    className: 'text-left',
  },
  {
    Header: 'Type',
    accessor: 'purchase_sales',
    className: 'text-center',
    width: 40,
  },
  {
    Header: 'Contract Date',
    accessor: 'contract_date_formatted',
    className: 'text-center',
    sortMethod: HtmlHelpers.dateSort,
    width: 175,
  },
  {
    Header: 'Delivery Start',
    accessor: 'delivery_start_formatted',
    className: 'text-center',
    sortMethod: HtmlHelpers.dateSort,
    width: 175,
  },
  {
    Header: 'Delivery End',
    accessor: 'delivery_end_formatted',
    className: 'text-center',
    sortMethod: HtmlHelpers.dateSort,
    width: 175,
  },
  {
    Header: 'Counterparty',
    accessor: 'counterparty_name',
    className: 'text-left',
  },
  {
    Header: 'Source',
    accessor: 'source_names',
    Cell: (row) => (
      <div>
        {row.original.source_names.map((n, idx) => (<div key={`sn_${idx + 1}`}>{n}</div>))}
      </div>
    ),
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_names',
    Cell: (row) => (
      <div>
        {row.original.item_names.map((n, idx) => (<div key={`in_${idx + 1}`}>{n}</div>))}
      </div>
    ),
    className: 'text-left',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    className: 'text-right',
    sortMethod: HtmlHelpers.numberSort,
  },
  {
    Header: 'Unnominated',
    accessor: 'quantity_balance',
    className: 'text-right',
    sortMethod: HtmlHelpers.numberSort,
  },
  {
    Header: 'Shipped',
    accessor: 'quantity_shipped',
    className: 'text-right',
    sortMethod: HtmlHelpers.numberSort,
  },
  {
    Header: 'Unshipped',
    accessor: 'quantity_unshipped',
    className: 'text-right',
    sortMethod: HtmlHelpers.numberSort,
  },
  {
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-center',
  },
  ColumnHelpers.activeColumn(),
];

export default Columns;
