import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default class OptionYears extends OptionsBaseList {
  getDropdownLabelText = () => this.props.fieldLabel || 'Option Year';

  getDropdownFieldName = () => this.props.fieldName || 'option_year';

  getOptions = () => {
    const year = new Date().getFullYear();
    return (
      [
        { value: year, label: year },
        { value: year + 1, label: year + 1 },
        { value: year + 2, label: year + 2 },
      ]
    );
  }
}
