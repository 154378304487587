import React from 'react';
import { Button } from 'reactstrap';
import { ColumnButton, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Field',
    accessor: 'field_name',
    className: 'text-left',
  },
  {
    Header: 'Previous Value',
    accessor: 'previous_value',
    className: 'text-left',
  },
  {
    Header: 'Current Value',
    accessor: 'current_value',
    className: 'text-left',
  },
  {
    Header: 'Changed By',
    accessor: 'changed_by',
    className: 'text-left',
  },
  {
    Header: 'Change Date',
    accessor: 'created_at',
    className: 'text-center',
    width: 120,
  },
];

export default Columns;
