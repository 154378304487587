import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import ContractItems from 'components/Options/ContractItems';
import Customers from 'components/Options/Customers';
import FinalUsages from 'components/Options/FinalUsages';
import Nominations from 'components/Options/Nominations';
import TransportationModes from 'components/Options/TransportationModes';
import Vendors from 'components/Options/VendorEntities';

export default withAuth(withAlert()(class DirectOrderForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.formKey = 1;
    this.state.purchaseKey = 1;
    this.state.salesKey = 1;
  }

  formType = () => 'Direct Order';

  getCrudType = () => 'contracts/direct_orders';

  successAlertMsg = () => this.successMsg('Direct Order');

  itemQuery = () => {
    const purchase = this.getItem().purchase_contract_uuid;
    const sales = this.getItem().sales_contract_uuid;
    const select = 'select item_uuid from contract_items';
    return (
      `contract_uuid = '${purchase}' and item_uuid in (${select} where contract_uuid = '${sales}')`
    );
  };

  options = () => (
    {
      customer: {
        component: Customers,
        field: 'customer_entity_uuid',
        label: 'Customer',
        props: { isRequired: true, valueField: 'entity_uuid' },
      },
      finalUsage: {
        component: FinalUsages,
        field: 'final_usage',
        props: { isRequired: true },
      },
      items: {
        component: ContractItems,
        field: 'item_uuid',
        props: {
          isRequired: true,
          key: this.state.formKey,
          apiParams: { query: this.itemQuery() },
          valueField: 'item_uuid',
        },
        currentValue: this.getItem().item_uuid,
      },
      purchaseContract: {
        component: Nominations,
        disabled: HtmlHelpers.isEmpty(this.getItem().vendor_entity_uuid),
        field: 'purchase_nomination_uuid',
        label: 'Purchase Contract',
        props: {
          isRequired: true,
          key: this.state.purchaseKey,
          apiParams: {
            filtered: JSON.stringify([
              { id: 'contract__counterparty_uuid', value: `${this.getItem().vendor_entity_uuid}` },
              { id: 'contract__transportation_mode_name', value: `${this.getItem().transportation_mode_code}` },
              { id: 'contract__purchase_sales', value: 'P' },
              { id: 'active', value: true },
            ]),
            sorted: JSON.stringify([{ id: 'contract__contract_id', desc: true }]),
            show_all: 'true',
          },
        },
      },
      salesContract: {
        component: Nominations,
        disabled: HtmlHelpers.isEmpty(this.getItem().customer_entity_uuid),
        field: 'sales_nomination_uuid',
        label: 'Sales Contract',
        props: {
          isRequired: true,
          key: this.state.salesKey,
          apiParams: {
            filtered: JSON.stringify([
              { id: 'contract__counterparty_uuid', value: `${this.getItem().customer_entity_uuid}` },
              { id: 'contract__transportation_mode_name', value: `${this.getItem().transportation_mode_code}` },
              { id: 'contract__purchase_sales', value: 'S' },
              { id: 'active', value: true },
            ]),
            sorted: JSON.stringify([{ id: 'contract__contract_id', desc: true }]),
            show_all: 'true',
          },
        },
      },
      transportation: {
        component: TransportationModes,
        field: 'transportation_mode_code',
        props: { isRequired: true },
        currentValue: this.getItem().transportation_mode_code,
      },
      vendor: {
        component: Vendors,
        field: 'vendor_entity_uuid',
        props: { valueField: 'entity_uuid', isRequired: true },
      },
    }
  );

  showVesselName = () => (
    HtmlHelpers.isNotEmpty(this.getItem().transportation_mode_code) && this.getItem().transportation_mode_code === 'Vessel'
  )

  showItems = () => (
    HtmlHelpers.isNotEmpty(this.getItem().purchase_nomination_uuid)
      && HtmlHelpers.isNotEmpty(this.getItem().sales_nomination_uuid)
  );

  destinationWeight = () => (
    HtmlHelpers.isEmpty(this.getItem().destination_weight)
      ? ''
      : this.getNumericFieldRow('destination_weight', 'Destination Weight (KG)')
  );

  arrivalDate = () => (
    HtmlHelpers.isEmpty(this.getItem().destination_weight)
      ? ''
      : this.getDateFieldRow('arrival_date', 'Arrival Date', true, { value: this.getItem().arrival_date_formatted })
  );

  showTruck = () => this.getItem().transportation_mode_code === 'Truck';

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;

    let formKey = this.state.formKey;
    let purchaseKey = this.state.purchaseKey;
    let salesKey = this.state.salesKey;

    if (name === 'vendor_entity_uuid') {
      purchaseKey += 1;
    }

    if (name === 'customer_entity_uuid') {
      salesKey += 1;
    }

    if (name === 'purchase_nomination_uuid') {
      newData.purchase_contract_uuid = event.target.value?.item.contract_uuid;
      if (this.showItems()) {
        formKey += 1;
      }
    }

    if (name === 'sales_nomination_uuid') {
      newData.sales_contract_uuid = event.target.value?.item.contract_uuid;
      newData.final_usage = event.target.value?.item.final_usage;
      if (this.showItems()) {
        formKey += 1;
      }
    }

    this.setVariables({
      item: newData, formKey, purchaseKey, salesKey,
    });
  }

  modalBodyRows = () => (
    <div>
      {this.isNew() ? this.dropdownField('transportation') : ''}
      {this.showVesselName() ? this.getTextFieldRow('vessel_name', 'Vessel Name') : ''}
      {this.isNew() ? this.dropdownField('vendor') : ''}
      {this.dropdownField('purchaseContract')}
      {this.isNew() ? this.dropdownField('customer') : ''}
      {this.dropdownField('salesContract')}
      {this.getTextFieldRow('customer_po', 'Customer PO', false)}
      {this.showItems() ? this.dropdownField('items') : ''}
      {this.getNumericFieldRow('origin_weight', 'Origin Weight (KG)')}
      {this.destinationWeight()}
      {this.getDateFieldRow('shipped_date', 'Shipped Date', true, { value: this.getItem().shipped_date_formatted })}
      {this.showTruck() ? this.getTextFieldRow('bill_of_lading_number', 'BOL #') : ''}
      {this.showTruck() ? this.getTextFieldRow('carrier', 'Carrier') : ''}
      {this.showTruck() ? this.getTextFieldRow('truck_plate', 'Truck Plate') : ''}
      {this.showTruck() ? this.getTextFieldRow('trailer_plate', 'Trailer Plate', false) : ''}
      {this.isNew() ? this.dropdownField('finalUsage') : ''}
    </div>
  )
}));
