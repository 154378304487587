import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Api, CrudList, HtmlHelpers, ModalConfirmation,
} from '@westernmilling/eu_react_shared';
import { Button, Col, Row } from 'reactstrap';

import Columns from 'helpers/columns/TruckOrdersBulkCancel';

export default withAuth(withAlert()(class TruckOrderBulkCancelListContainer extends CrudList {
  constructor(props) {
    super(props);
    this.state.bulkCancel = {};
    this.state.selectAll = false;
    this.state.disabled = true;
  }

  getColumns = () => Columns(this);

  getListApi = () => 'contracts/truck_orders';

  getListParams = () => ({
    filtered: [{ id: 'status_in', value: ['Available', 'Scheduled', 'Price Hold', 'Credit Hold'] }],
    show_all: 'true',
  });

  getStorageName = () => 'otto_contracts_truck_orders_bulk_cancel_list';

  getPageTitle = () => 'Truck Orders - Bulk Cancelation';

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  getAddText = () => 'Cancel Truck Orders';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  getAddText = () => 'Cancel Truck Orders';

  getFormTag = () => true;

  createButton = () => (
    <div>
      <Button
        outline
        color="info"
        disabled={this.state.disabled}
        onClick={() => this.handleOpenModal(1, 'Cancel')}
        title="Add"
        className="align-self-center otto__margin_btm_30"
      >
        Cancel Truck Orders
      </Button>
    </div>
  )

  cancelModal = () => {
    let contents = '';
    if (this.state.showModalCancel) {
      const length = this.getBulkUuids(this.state.bulkCancel).length;
      const these = length > 1 ? 'these' : 'this';
      const order = length > 1 ? 'orders' : 'order';
      const msg = `Are you sure you want to cancel ${these} ${length} truck ${order}?`;
      contents = (
        <ModalConfirmation
          msg={msg}
          onConfirmation={this.bulkCancelApi}
          showModal={this.state.showModalCancel}
          closeModal={() => this.handleCloseModal('Cancel')}
          disabled={this.state.disableCancel}
        />
      );
    }
    return contents;
  };

  additionalContent = () => (
    <div>
      {this.cancelModal()}
    </div>
  );

  disabledMsg = () => (
    HtmlHelpers.isEmpty(this.getBulkUuids(this.state.bulkCancel))
      ? 'The Cancel Truck Orders button will be enabled when you select at least one Truck Order'
      : ''
  );

  smallTitle = (caret = false) => (
    <Row>
      <Col md="5" className="text-left">
        { caret ? this.getTitle('table') : this.getTitle('page')}
      </Col>
      <Col md="5" className="text-right" style={{ marginTop: '10px' }}>
        { this.disabledMsg() }
      </Col>
      <Col md="2" className="align-self-center text-right">
        { this.isAddAdmin() ? this.createButton() : ''}
      </Col>
    </Row>
  );

  toggleSelectAll = () => {
    const bulk = { ...this.state.bulkCancel };
    let disabled = this.state.disabled;

    this.tableRef.current.filterData().forEach((d) => {
      bulk[d._original.uuid] = !this.state.selectAll;
    });

    if (HtmlHelpers.isNotEmpty(this.getBulkUuids(bulk))) {
      disabled = false;
    } else {
      disabled = true;
    }
    this.setState({ bulkCancel: bulk, selectAll: !this.state.selectAll, disabled });
  };

  toggleSelectedTruck = (uuid) => {
    const bulk = { ...this.state.bulkCancel };
    let disabled = this.state.disabled;
    bulk[uuid] = bulk[uuid] === undefined || bulk[uuid] === false;

    if (HtmlHelpers.isNotEmpty(this.getBulkUuids(bulk))) {
      disabled = false;
    } else {
      disabled = true;
    }
    this.setState({ bulkCancel: bulk, disabled });
  }

  getBulkUuids = (data) => (
    Object.keys(data).filter((key) => data[key] === true)
  );

  bulkCancelApi = () => {
    const data = { bulk_uuids: this.getBulkUuids(this.state.bulkCancel), uuid: 1 };
    Api.crudDelete(this.props.auth, 'contracts/truck_orders', data)
      .then((response) => { this.handleResponse(response, 'Sucessfully canceled bulk truck orders'); })
      .then(() => this.updateData())
      .then(() => this.setState({ bulkCancel: {}, showModalCancel: false, selectedItemCancel: null }));
  };

  errorDisplay = (errors) => (
    errors.map((error) => (
      Object.entries(error).map((err) => (
        <div>
          {`${err[0]}: ${err[1].join(', ')}` }
        </div>
      ))
    ))
  );

  handleResponse = (response, msg) => {
    if (HtmlHelpers.isNotEmpty(response.errors)) {
      this.props.alert.show(this.errorDisplay(response.errors), { type: 'error', timeout: 180000 });
    } else if (response.data !== undefined) {
      this.props.alert.show(msg, { type: 'success' });
    } else {
      this.props.alert.show(
        'System error, please try again. If you continue to get this error, open a Help Desk ticket',
        { type: 'error' },
      );
    }
  }
}));
