import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, FunctionsList, HtmlHelpers } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/SpecialDay';
import TimeSlotSpecialDay from 'components/Tables/TimeSlotSpecialDay';

export default withAuth(withAlert()(class TimeSlotSpecialDayList extends CrudList {
  getListApi = () => 'contracts/time_slot_days';

  getFormTag = () => ItemForm;

  getListParams = () => ({
    query: `time_slot_uuid = '${this.props.uuid}' and special_date is not null and special_date >= CURRENT_DATE`,
    order_literal: 'special_date desc',
  });

  getStorageName = () => 'otto_time_slot_special_day_list';

  getAddGroups = () => ['time_slot_admin'];

  getPageTitle = () => 'Special Days';

  getAddText = () => 'Add Special Day';

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

  getWidthCenter = () => 10;

  getWidthLeft = () => 1;

  getWidthRight = () => 1;

  getContent = () => this.contentCollapsible();

  createButton = () => this.createButtonSmall();

  additionalFormProps = () => ({ timeSlotUuid: this.props.uuid });

  indexTable = () => (
    this.state.data.map((day, idx) => (
      <div key={`day_${idx + 1}`}>
        <br />
        <TimeSlotSpecialDay addGroups={this.getAddGroups()} uuid={day.uuid} item={day} updateData={this.updateData} />
      </div>
    ))
  );
}));
