import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class SpecialDayForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.time_slot_uuid = props.additionalFormProps.timeSlotUuid;
    this.state.item.special_day = true;
  }

  formType = () => 'Special Day';

  getCrudType = () => 'contracts/time_slot_days';

  successAlertMsg = () => this.successMsg('Special Day');

  modalBodyRows = () => (
    <div>
      { this.getDateFieldRow('special_date', 'Pickup Date') }
    </div>
  )
}));
