import React from 'react';
import { HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const orderLink = (row) => {
  let link = '';
  if (row.original.order_number.startsWith('D')) {
    link = `direct_orders/view/${row.original.uuid}`;
  } else if (row.original.order_number.startsWith('V')) {
    link = `vessel_orders/view/sales/${row.original.uuid}`;
  } else {
    link = `truck_orders/view/${row.original.uuid}`;
  }
  return link;
};

const Columns = (context) => [
  {
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
  },
  {
    Header: 'Shipment Date',
    accessor: 'shipped_date',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Commodity',
    accessor: 'commodity_name_with_code',
    className: 'text-left',
  },
  {
    Header: 'Shipped (kg)',
    accessor: 'net_weight',
    className: 'text-right',
  },
  {
    Header: 'Name Id',
    accessor: 'bill_to_reference',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Bill To',
    accessor: 'bill_to_name',
    className: 'text-left',
  },
  {
    Header: 'Customer',
    accessor: 'customer_name',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (
      <pre>
        {row.original.ship_to}
      </pre>
    ),
    className: 'text-left',
  },
  {
    Header: 'Grain Ticket',
    accessor: 'grain_ticket',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Order ID',
    accessor: 'order_number',
    Cell: (row) => Links.getLink('contracts', orderLink(row), row.original.order_number),
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Contract Schedule',
    accessor: 'contract_schedule_number',
    Cell: (row) => {
      const name = row.original.contract_schedule_number;
      return HtmlHelpers.isEmpty(row.original.contract_schedule_uuid) ? '' : (
        Links.getLink('contracts', `schedules/view/${row.original.contract_schedule_uuid}`, name)
      );
    },
    className: 'text-center',
    width: 130,
  },
  {
    Header: 'Payment Terms',
    accessor: 'payment_term',
    className: 'text-left',
  },
];

export default Columns;
