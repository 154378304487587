import { Api, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Locations extends OptionsBaseApi {
  getApiName = () => 'entities/locations';

  getApiParams = () => this.props.apiParams || [];

  getDropdownLabelText = () => this.props.fieldLabel || 'Ship To';

  getDropdownFieldName = () => this.props.fieldName || 'ship_to_location_uuid';

  buildLabel = (obj) => (
    `${obj.name} - ${obj.street_address}, ${obj.city}, ${obj.region}, ${obj.region_code} ${obj.country}`
  );

  getOptions = async (auth) => {
    let options = [];
    if (!this.props.data) {
      await Api.crudList(auth, this.getApiName(), this.getApiParams(), this.getApiVersion())
        .then((response) => {
          if (response.data !== undefined) {
            const optionList = response.data.map((d) => {
              const item = {};
              this.additionalFields().forEach((key) => { item[key] = d.attributes[key]; });
              return {
                label: this.buildLabel(d.attributes),
                value: d.attributes[this.getValueFieldName()],
                item,
              };
            });
            options = this.uniqueArray(optionList);
          }
        });
    }

    this.setState({ items: options });
  }
}
