import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class InactivateForm extends CrudForm {
  formType = () => 'Time Slot Day';

  getCrudType = () => 'contracts/time_slot_day_times';

  successAlertMsg = () => this.successMsg('Time Slot Day');

  buildApiVariables = () => ({ end_date: this.getItem().end_date, uuid: this.getItem().uuid });

  onSubmit = (event) => {
    this.setVariables({ errors: [], disabled: true });

    if (HtmlHelpers.validateForm(event.formEl)
      && this.additionalValidations().length === 0
    ) {
      const type = this.getCrudType();
      Api.crudDelete(this.props.auth, type, this.buildApiVariables(), this.getApiVersion())
        .then(async (response) => this.handleResponse(response));
    } else {
      this.setVariables({ disabled: false });
    }
  }

  modalBodyRows = () => (
    <div>
      {this.getDateFieldRow('end_date', 'End Date')}
    </div>
  );
}));
