import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    id: 'time_slot_appointment__time_slot_starts_at',
    Header: 'Time Slot',
    accessor: 'time_slot_range',
    className: 'text-center',
  },
  {
    id: 'pickup_shipped',
    Header: 'Pickup Date',
    accessor: (d) => d.shipped_date || d.pickup_date || '',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Order ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Truck Plate',
    accessor: 'truck_plate',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Customer PO',
    accessor: 'po_number',
    className: 'text-left',
  },
  {
    id: 'carrier__name',
    Header: 'Carrier',
    accessor: 'carrier_name',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (<pre>{row.original.ship_to}</pre>),
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    Header: 'Check In Time',
    accessor: 'check_in_at',
    className: 'text-center',
  },
  {
    Header: 'Check Out Time',
    accessor: 'check_out_at',
    className: 'text-center',
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => (
      HtmlHelpers.isEmpty(row.original.bill_of_lading_pdf_url)
        ? ''
        : <ColumnButton onClick={() => context.reprintBillOfLading(row.original.uuid)} text="Bill of Lading" />
    ),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 120,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default Columns;
