import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Button, Col, Row } from 'reactstrap';
import { CrudView, HtmlHelpers, PageTitle } from '@westernmilling/eu_react_shared';

import TimeSlotDays from 'components/Tables/TimeSlotDays';
import TimeSlotSpecialDaysList from 'components/Tables/TimeSlotSpecialDaysList';

export default withAuth(withAlert()(class TimeSlotViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = { type: 'contracts/time_slots' };
  }

  title = () => (
    HtmlHelpers.isEmpty(this.getItem()) ? '' : `- ${this.getItem().source_location} - ${this.getItem().group_name}`
  );

  dayTable = (day) => (
    HtmlHelpers.isEmpty(this.getItem()) ? '' : (
      <TimeSlotDays dayUuid={this.getDayUuid(day)} weekday={day} timeSlotUuid={this.getItem().uuid} />
    )
  )

  getDayUuid = (day) => this.getItem().normal_time_slot_days[day]?.uuid;

  days = () => (
    HtmlHelpers.isEmpty(this.getItem()) ? '' : (
      <div className="col-md-12">
        { this.dayTable('Monday') }
        <br />
        <hr />
        { this.dayTable('Tuesday') }
        <br />
        <hr />
        { this.dayTable('Wednesday') }
        <br />
        <hr />
        { this.dayTable('Thursday') }
        <br />
        <hr />
        { this.dayTable('Friday') }
        <br />
        <hr />
        { this.dayTable('Saturday') }
        <br />
        <hr />
        { this.dayTable('Sunday') }
        <br />
        <hr />
        <TimeSlotSpecialDaysList uuid={this.getItem().uuid} />
      </div>
    )
  );

  render() {
    return (
      <div className="col-md-12">
        <Row>
          <div className="col-md-10 text-left">
            <PageTitle title={`Time Slots ${this.title()}`} />
          </div>
          <div className="col-md-2 align-self-center text-right">
            <Button className="otto__margin_right" outline href="../../time_slots">
              Time Slot List
            </Button>
          </div>
        </Row>
        <br />
        <Row>
          { this.days() }
        </Row>
      </div>
    );
  }
}));
