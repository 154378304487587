import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/ContractSchedulesList';

export default withAuth(class ContractScheduleListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/contract_schedules';

  getStorageName = () => 'otto_contracts_contract_schedule_list';

  getPageTitle = () => 'Contract Schedules';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
