import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Api, CrudView, HtmlHelpers, ModalConfirmation,
} from '@westernmilling/eu_react_shared';

import ActionLinks from 'components/ActionMenus/DirectOrderView';
import ItemForm from 'components/Forms/DirectOrder';
import ItemView from 'components/Forms/DirectOrderView';
import DestinationWeightModal from 'components/Modals/DestinationWeightModal';

export default withAuth(withAlert()(class DirectOrderViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
  }

  editForm = () => ItemForm;

  getItem = () => (this.state.data || [])[0] || {};

  getSection = () => (
    {
      type: 'contracts/direct_orders',
      form: ItemView,
      title: 'View Direct Order',
      listRoute: '../../direct_orders',
      listLabel: 'Order List',
      edit_admins: [],
    }
  );

  additionalButtonsAfterList = () => (
    <span>
      <ActionLinks
        linkInfo={this.state.linkInfo || {}}
        uuid={this.getItem().uuid}
        item={this.getItem()}
        openModal={this.handleOpenModal}
        createExport={this.createExport}
        createInboundExport={this.createExport}
      />
      {this.destinationWeightModal()}
      {this.reverseGrainTicket('Inbound')}
      {this.reverseGrainTicket('Outbound')}
    </span>
  );

  updateData = () => {
    this.setState({
      data: null,
      showModalDestinationWeight: null,
      showModalReverseInboundExport: null,
      showModalReverseOutboundExport: null,
    });
    this.fetchItem();
  }

  createExport = (type) => {
    const data = { uuid: this.getUuid(), type };
    Api.crudCreate(this.props.auth, 'contracts/direct_order_grain_tickets', data)
      .then((response) => { this.handleResponse(response, `${type} Grain ticket Export started successfully`); });
  }

  destinationWeightModal = () => {
    let contents = '';
    if (this.state.showModalDestinationWeight) {
      contents = (
        <DestinationWeightModal
          showModal={this.state.showModalDestinationWeight}
          closeModal={() => this.handleCloseModal('DestinationWeight')}
          disabled={this.state.disableDestinationWeight}
          item={this.state.data[0]}
          includes={this.state.includes}
          orderType="Direct"
          path="direct_orders"
          type="edit"
          updateData={this.updateData}
        />
      );
    }
    return contents;
  };

  reverseExportApi = (type) => {
    const data = { uuid: this.getUuid(), type };
    this.setState({ [`disableReverse${type}Export`]: true });
    Api.crudUpdate(this.props.auth, 'contracts/direct_order_grain_tickets', data)
      .then((response) => { this.handleResponse(response, 'Export successfully reversed'); })
      .then(() => this.handleCloseModal(`Reverse${type}Export`));
  };

  reverseGrainTicket = (type) => {
    let contents = '';
    if (this.state[`showModalReverse${type}Export`]) {
      contents = (
        <ModalConfirmation
          msg={`Are you sure you want to reverse the ${type} export on this order?`}
          onConfirmation={() => this.reverseExportApi(type)}
          showModal={this.state[`showModalReverse${type}Export`]}
          closeModal={() => this.handleCloseModal(`Reverse${type}Export`)}
          disabled={this.state[`disableReverse${type}Export`]}
        />
      );
    }
    return contents;
  };
}));
