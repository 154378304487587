import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Button, Row, Col, Collapse,
} from 'reactstrap';
import { AddIcon, Api, CrudList } from '@westernmilling/eu_react_shared';
import * as moment from 'moment';

import Columns from 'helpers/columns/ContractBrokerageReport';
import MonthYear from 'components/Options/MonthYear';

export default withAuth(withAlert()(class ContractBrokerageReportListContainer extends CrudList {
  constructor(props) {
    super(props);
    this.state.month = this.getDefaultDate().month() + 1;
    this.state.year = this.getDefaultDate().year();
    this.state.startMonth = this.getDefaultDate().format('MM/DD/YYYY');
  }

  getDefaultDate = () => moment().startOf('month').subtract(1, 'months');

  getColumns = () => Columns(this);

  getListApi = () => 'contracts/contract_brokerage_views';

  getListParams = () => ({ query: `shipped_month = ${this.state.month} and shipped_year = ${this.state.year}` });

  getStorageName = () => 'otto_contract_brokerage_report_list';

  getPageTitle = () => 'Monthly Brokerage Report';

  getAddGroups = () => ['execution_admin'];

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  onChange = async (e) => {
    const value = e.target.value?.value || this.getDefaultDate();
    await this.setState({
      month: moment(value, 'MM/DD/YYYY').month() + 1,
      year: moment(value, 'MM/DD/YYYY').year(),
      startMonth: value,
    });
    this.getList();
  }

  smallTitle = () => (
    <div>
      <Row>
        <Col md="8" className="text-left">
          { this.getTitle('page') }
        </Col>
      </Row>
      <Row>
        <Col md="3" />
        <Col md="4">
          <MonthYear
            onChange={this.onChange}
            labelColumns={6}
            fieldColumns={6}
            currentValue={this.state.startMonth}
          />
        </Col>
      </Row>
    </div>
  );
}));
