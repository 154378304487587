import { Api, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Nominations extends OptionsBaseApi {
  getApiName = () => 'contracts/nominations';

  getApiParams = () => this.props.apiParams || ({ for_truck_order: 'Y', truck_order_uuid: this.props.truckOrderUuid });

  getDropdownLabelText = () => this.props.fieldLabel || 'Nomination';

  getDropdownFieldName = () => this.props.fieldName || 'nomination_uuid';

  getLabelFieldName = (nom) => `${nom.contract_id}-${nom.contract_schedule_number} ~ ${nom.id} ~ ${nom.endorsee_name}`;

  additionalFields = () => ['contract_uuid', 'final_usage'];

  getOptions = async (auth) => {
    let options = [];
    if (!this.props.data) {
      await Api.crudList(auth, this.getApiName(), this.getApiParams(), this.getApiVersion())
        .then((response) => {
          if (response.data !== undefined) {
            const optionList = response.data.map((d) => {
              const item = {};
              this.additionalFields().forEach((key) => { item[key] = d.attributes[key]; });
              return {
                label: this.getLabelFieldName(d.attributes),
                value: d.attributes[this.getValueFieldName()],
                item,
              };
            });
            options = this.uniqueArray(optionList);
          }
        });
    }

    this.setState({ items: options });
  }
}
