import { HtmlHelpers, OptionsBaseList } from '@westernmilling/eu_react_shared';
import moment from 'moment';

export default class MonthYear extends OptionsBaseList {
  getDropdownLabelText = () => this.props.fieldLabel || 'Shipped Date Month/Year';

  getDropdownFieldName = () => this.props.fieldName || 'start_month';

  getOptions = () => {
    const options = [];
    const monthCount = this.props.monthCount || 24;
    const dt = moment().startOf('month').subtract(monthCount, 'months');

    for (let i = 0; i <= monthCount; i += 1) {
      const optionText = dt.format('MMMM YYYY');
      const optionValue = dt.format('L');
      options.push({ label: optionText, value: optionValue });
      dt.add(1, 'months');
    }
    return options.reverse();
  };
}
