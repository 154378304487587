import React from 'react';
import { withAuth } from '@okta/okta-react';
import { CrudList, HtmlHelpers, WmAuth } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/UpcomingOrders';
import ItemForm from 'components/Forms/UpcomingOrders';

export default withAuth(class UpcomingTruckOrdersListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/truck_orders';

  getListParams = () => ({
    sorted: [
      { id: 'pickup_date', desc: false },
      { id: 'time_slot_appointment__time_slot_starts_at', desc: false },
      { id: 'id', desc: false },
    ],
    filter_source: this.props.match.params.loc,
    upcoming_orders: 'Y',
    transfers: 'I',
    show_all: 'true',
  });

  getStorageName = () => 'otto_contracts_upcoming_trucks_list';

  getPageTitle = () => (
    `Upcoming Orders - ${HtmlHelpers.capitalize(this.props.match.params.loc)}`
  );

  getFormTag = () => ItemForm;

  getEditGroups = () => ['barcelona_operations', 'verdu_operations'];

  isReception = () => WmAuth.isEditAdmin(this.state, ['reception']);

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  additionalFormProps = () => ({
    checkIn: true,
    submitLabel: 'Check In Truck',
    headerTitle: 'Truck Check In',
    barcelona: this.state.additonalInfo.barcelona,
    verdu: this.state.additonalInfo.verdu,
  });

  additionalContent = () => (
    this.state.showModalReception ? (
      <ItemForm
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItemReception}
        showModal={this.state.showModalReception}
        closeModal={() => this.handleCloseModal('Reception')}
        updateData={this.updateData}
        headerTitle="Truck Registration"
        submitLabel="Register Truck"
        reception
      />
    ) : ''
  );
});
