import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudView, ModalConfirmation } from '@westernmilling/eu_react_shared';

import ActionLinks from 'components/ActionMenus/NominationView';
import ItemForm from 'components/Forms/NominationEdit';
import ItemView from 'components/Forms/NominationView';

export default withAuth(withAlert()(class NominationViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
  }

  editForm = () => ItemForm;

  // TODO: getItem exists already
  getItem = () => (this.state.data || [])[0] || {};

  contractScheduleUuid = () => this.getItem().contract_schedule_uuid;

  getSection = () => (
    {
      type: 'contracts/nominations',
      form: ItemView,
      title: `View ${this.props.type}`,
      listRoute: '../../nominations',
      listLabel: 'Nomination List',
      edit_admins: [],
    }
  );

  additionalButtonsAfterList = () => (
    <span>
      <ActionLinks
        linkInfo={this.state.linkInfo || {}}
        uuid={this.getItem().uuid}
        openModal={this.handleOpenModal}
      />
      {this.cancelModal()}
    </span>
  );

  cancelApi = () => {
    const data = { uuid: this.getUuid() };
    this.setState({ disableCancel: true });
    Api.crudDelete(this.props.auth, 'contracts/nominations', data)
      .then((response) => { this.handleResponse(response, `Sucessfully canceled the ${this.props.type}`); })
      .then(() => {
        this.handleCloseModal('Cancel');
        this.setState({ disableCancel: false });
      });
  };

  cancelModal = () => {
    let contents = '';
    if (this.state.showModalCancel) {
      contents = (
        <ModalConfirmation
          msg={`Are you sure you want to cancel this ${this.props.type}?`}
          onConfirmation={this.cancelApi}
          showModal={this.state.showModalCancel}
          closeModal={() => this.handleCloseModal('Cancel')}
          disabled={this.state.disableCancel}
        />
      );
    }
    return contents;
  };

  additionalFormProps = () => (
    {
      type: this.props.type,
      nomination: this.getItem(),
    }
  );

  updateData = () => {
    this.setState({ data: null });
    this.fetchItem();
  }
}));
