import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class ContractItems extends OptionsBaseApi {
  getApiName = () => 'contracts/contract_items';

  getApiParams = () => this.props.apiParams || [];

  getLabelFieldName = () => 'name_with_ref';

  getDropdownLabelText = () => 'Item';

  getDropdownFieldName = () => this.props.fieldName || 'contract_item_uuid';
}
