import React from 'react';
import { Col, Row } from 'reactstrap';
import { CrudForm, HtmlHelpers, OptionsCountry } from '@westernmilling/eu_react_shared';

import Locations from 'components/Options/Locations';

export default class ShipToField extends CrudForm {
  constructor(props) {
    super(props);
    this.state.addNewLoc = false;
  }

  openAddNewLoc = () => this.setState({ addNewLoc: true });

  formType = () => 'Locations';

  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.item;

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  buildApiParams = () => {
    const params = {
      tms_locations: 'Y', with_systems: 'Y', show_all: 'true', sorted: JSON.stringify([{ id: 'name', desc: false }]),
    };
    if (HtmlHelpers.isNotEmpty(this.props.entityUuid)) {
      params.entity_uuid = this.props.entityUuid;
    }
    return params;
  }

  options = () => (
    {
      country: {
        component: OptionsCountry,
        field: 'new_loc_country',
        label: 'Country',
        currentValue: this.getItem().new_loc_country,
        props: { isRequired: true },
      },
      locations: {
        component: Locations,
        field: 'ship_to_location_uuid',
        label: 'Ship To',
        currentValue: this.getItem().ship_to_location_uuid,
        disabled: this.state.addNewLoc,
        buttonAfter: this.getButton('Add New Ship To', this.openAddNewLoc),
        props: { apiParams: this.buildApiParams(), isRequired: this.props.isRequired },
      },
    }
  );

  streetAddr = () => this.getItem().new_loc_street_address;

  secondLine = () => this.getItem().new_loc_additional_address_line;

  locationFields = () => (
    this.state.addNewLoc ? (
      <div>
        {this.getTextFieldRow('new_loc_name', 'Ship To Name', true, { value: this.getItem().new_loc_name })}
        {this.getTextFieldRow('new_loc_street_address', 'Address Line 1', true, { value: this.streetAddr() })}
        {this.getTextFieldRow('new_loc_additional_address_line', 'Address Line 2', false, { value: this.secondLine() })}
        {this.getTextFieldRow('new_loc_city', 'City', true, { value: this.getItem().new_loc_city })}
        {this.getTextFieldRow('new_loc_region', 'Province', true, { value: this.getItem().new_loc_region })}
        {this.getTextFieldRow('new_loc_region_code', 'Postal Code', true, { value: this.getItem().new_loc_region_code })}
        {this.dropdownField('country')}
      </div>
    ) : ''
  );

  getContents = () => (
    <div>
      {this.dropdownField('locations')}
      {this.locationFields()}
    </div>
  )
}
