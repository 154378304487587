import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class BatchNumbers extends OptionsBaseApi {
  getApiName = () => 'items/item_batches';

  getApiParams = () => ({
    query: `status = 'Released' and item_uuid = '${this.props.itemUuid}' and agris_bin_desc = '${this.props.agrisBin}'`,
  });

  getDropdownLabelText = () => this.props.fieldLabel || 'Batch #';

  getDropdownFieldName = () => this.props.fieldName || 'uuid';

  getValueFieldName = () => this.props.valueField || 'batch_number';

  getLabelFieldName = () => 'batch_number';
}
