import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/VesselOrdersList';

export default withAuth(class VesselOrderSalesListContainer extends CrudList {
  getColumns = () => Columns(this, 'sales');

  getListApi = () => 'contracts/vessel_orders';

  getListParams = () => ({
    sorted: [{ id: 'id', desc: true }],
    filtered: [{ id: 'contract__purchase_sales', value: 'S' }],
  });

  getStorageName = () => 'otto_contracts_vessel_orders_sales_list';

  getPageTitle = () => 'Vessel Orders - Sales';

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getEditGroups = () => ['execution_admin'];

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
