import { HtmlHelpers, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class TimeSlots extends OptionsBaseApi {
  componentDidMount = async () => {
    if (HtmlHelpers.isNotEmpty(this.props.orderParams.pickup_date)) {
      await this.getOptions(this.props.auth);
    }
  }

  getApiName = () => 'contracts/time_slot_appointments';

  getApiParams = () => ({ ...this.props.orderParams });

  getDropdownLabelText = () => this.props.fieldLabel || 'Time Slot';

  getDropdownFieldName = () => this.props.fieldName || 'time_slot_uuid';

  getLabelFieldName = () => 'time_slot_range';
}
