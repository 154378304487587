import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Button, Col, Row } from 'reactstrap';
import {
  Attachments, CrudForm, ExportTable, ExternalIdTable, HtmlHelpers,
} from '@westernmilling/eu_react_shared';

import ContractItems from 'components/Tables/ContractItems';
import ContractNotes from 'components/Tables/ContractNotes';
import ContractSchedules from 'components/Tables/ContractSchedules';
import ContractSources from 'components/Tables/ContractSources';

export default withAuth(withAlert()(class ContractViewForm extends CrudForm {
  formType = () => 'Contract';

  getCrudType = () => 'contracts/contract';

  getItems = () => this.getItem().item_names.map((item) => (
    <div key={`item_${item.id}`}>
      { item }
    </div>
  ));

  showPercent = () => (
    this.getItem().limit_type === 'Percentage' ? ' %' : ''
  );

  hideBrokerField = () => (
    HtmlHelpers.isEmpty(this.getItem().accrual_brokerage) || this.getItem().accrual_brokerage === '0.00'
  );

  col1 = () => (
    <Col md={4} sm={12}>
      {this.getRow('contract_id', 'Contract ID')}
      {this.getRow('counterparty_name', 'Counterparty')}
      {this.getRow('purchase_sales_text', 'Type')}
      {this.getRow('transportation_mode_name', 'Transportation Mode')}
      {this.getRow('merchandiser_name', 'Merchandiser')}
      {this.getRow('market_zone_name', 'Market Zone')}
      {this.getRow('grade_base_text', 'Grade Base')}
      {this.getRow('weight_base_text', 'Weight Base')}
    </Col>
  );

  col2 = () => (
    <Col md={4} sm={12}>
      {this.getRow('quantity', 'Quantity')}
      {this.getRow('quantity_balance', 'Unnominated')}
      {this.getRow('quantity_shipped', 'Shipped')}
      {this.getRow('quantity_unshipped', 'Unshipped')}
      {this.getRowWithValue('Lower Limit', `${this.getItem().lower_limit}${this.showPercent()}`)}
      {this.getRowWithValue('Higher Limit', `${this.getItem().higher_limit}${this.showPercent()}`)}
      {this.getRow('unit_of_measure_symbol', 'UOM')}
      {this.getRow('final_usage', 'Final Usage')}
    </Col>
  );

  col3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('active_text', 'Active')}
      {this.getRow('contract_date_formatted', 'Contract Date')}
      {this.getRow('signed_date', 'Signed Date')}
      {this.getRow('delivery_start_formatted', 'Delivery Start')}
      {this.getRow('delivery_end_formatted', 'Delivery End')}
      {this.getRow('plancha', 'Plancha')}
      {this.getRow('payment_method_name', 'Payment Method')}
      {this.getRow('term_code', 'Payment Terms')}
      {this.getRow('currency_name', 'Currency')}
    </Col>
  );

  accCol1 = () => (
    <Col md={4} sm={12}>
      {this.getRow('accrual_freight', 'Freight')}
      {this.getRow('accrual_brokerage', 'Brokerage')}
      {this.hideBrokerField() ? '' : this.getRow('broker_name', 'Broker')}
      {this.getRow('accrual_d_a_s', 'Port Expenses')}
    </Col>
  );

  accCol2 = () => (
    <Col md={4} sm={12}>
      {this.getRow('accrual_cert', 'Cert')}
      {this.getRow('accrual_analysis', 'Analysis')}
      {this.getRow('accrual_fob_disch', 'Fob/ Disch')}
      {this.getRow('accrual_insurance', 'Insurance')}
    </Col>
  );

  accCol3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('accrual_customs', 'Customs')}
      {this.getRow('accrual_tax', 'Tax')}
      {this.getRow('accrual_other', 'Others')}
    </Col>
  );

  attachments = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <Attachments
      auth={this.props.auth}
      alert={this.props.alert}
      modelType="Contracts::Contract"
      modelUuid={this.getItem().uuid}
      isAddAdmin
    />
  ));

  external = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <ExternalIdTable
      auth={this.props.auth}
      modelType="Contracts::Contract"
      modelUuid={this.getItem().uuid}
      canVerify
    />
  ));

  exports = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <ExportTable
      auth={this.props.auth}
      modelType="Contracts::Contract"
      modelUuid={this.getItem().uuid}
      showExportButton={false}
    />
  ));

  modalBodyRows = () => (
    <div>
      <Row>
        {this.col1()}
        {this.col2()}
        {this.col3()}
      </Row>
      <br />
      <hr />
      <h4>
        Accruals
      </h4>
      <Row>
        {this.accCol1()}
        {this.accCol2()}
        {this.accCol3()}
      </Row>
      <br />
      <hr />
      <ContractItems contract_uuid={this.getItem().uuid} active={this.getItem().active} />
      <hr />
      <ContractSources
        contract_uuid={this.getItem().uuid}
        entity_uuid={this.getItem().counterparty_uuid}
        purchaseSales={this.getItem().purchase_sales}
        active={this.getItem().active}
      />
      <hr />
      <ContractSchedules contract={this.getItem()} />
      <ContractNotes auth={this.props.auth} uuid={this.getItem().uuid} />
      {this.attachments()}
      {this.external()}
      {this.exports()}
    </div>
  )
}));
