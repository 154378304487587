import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class TruckOrderManualApptModal extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.truck_order_uuid = props.uuid;
  }

  formType = () => '';

  headerTitle = () => 'Manual Appointment Entry'

  submitLabel = () => 'Create Appointment'

  getCrudType = () => 'contracts/time_slot_appointments';

  successAlertMsg = () => this.successMsg('Appointment');

  buildApiVariables = () => ({
    date: this.getItem().date,
    end_hour: this.getItem().end_hour,
    end_minutes: this.getItem().end_minutes,
    start_hour: this.getItem().start_hour,
    start_minutes: this.getItem().start_minutes,
    truck_order_uuid: this.getItem().truck_order_uuid,
  });

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    this.props.updateData();
    this.props.closeModal();
  }

  modalBodyRows = () => (
    <div>
      { this.getDateFieldRow('date', 'Date') }
      { this.getHour15Min('start', 'Start Time') }
      { this.getHour15Min('end', 'End Time') }
    </div>
  )
}));
