import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default class FinalUsages extends OptionsBaseList {
  getDropdownLabelText = () => 'Final Usage';

  getDropdownFieldName = () => this.props.fieldName || 'final_usage';

  getOptions = () => (
    [
      { value: 'Biodiesel', label: 'Biodiesel' },
      { value: 'Feed', label: 'Feed' },
      { value: 'Food', label: 'Food' },
      { value: 'Industrial', label: 'Industrial' },
    ]
  )
}
