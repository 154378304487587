import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Counterparty from 'components/Options/Counterparty';
import Items from 'components/Options/ContractItems';
import SourceLocations from 'components/Options/ContractSourceLocations';
import ShipToField from 'components/Forms/ShipToField';

export default withAuth(withAlert()(class Nomination extends CrudForm {
  constructor(props) {
    super(props);

    const info = this.isNomination() ? this.schedule() : props.nomination;
    this.state.item.contract_schedule_uuid ||= this.schedule().uuid || info.contract_schedule_uuid;
    this.state.item.contract_uuid ||= info.contract_uuid;
    this.state.item.counterparty_uuid ||= info.counterparty_uuid;
    this.state.item.unit_of_measure_uuid ||= info.unit_of_measure_uuid;
    this.state.item.bill_to_entity_uuid ||= info.counterparty_uuid;
    this.state.formKey = 1;

    if (!this.isNomination()) {
      this.state.item.parent_uuid = info.uuid;
      this.state.item.bill_to_entity_uuid = info.bill_to_entity_uuid;
      this.state.item.contract_item_uuid = info.contract_item_uuid;
      this.state.item.contract_source_location_uuid = info.contract_source_location_uuid;
    }
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  formType = () => (this.isNomination() ? 'Nomination' : 'Endorsement');

  getCrudType = () => 'contracts/nominations';

  successAlertMsg = () => this.successMsg(this.formType());

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  getModalSize = () => 'xl';

  options = () => (
    {
      billTo: {
        component: Counterparty,
        field: 'bill_to_entity_uuid',
        label: 'Bill To',
        props: {
          isRequired: true, apiParams: { related: 'Y', entity_uuid: this.schedule().counterparty_uuid, show_all: 'true' },
        },
      },
      endorsee: {
        component: Counterparty,
        field: 'endorsee_entity_uuid',
        label: 'Endorsee',
        props: {
          isRequired: true,
          apiParams: {
            filtered: JSON.stringify([{ id: 'active', value: 'true' }]),
            show_all: 'true',
            sorted: JSON.stringify([{ id: 'name', desc: false }]),
          },
          used: this.isNomination() ? [] : [this.nomination().endorsee_entity_uuid, this.nomination().bill_to_entity_uuid],
        },
      },
      items: {
        component: Items,
        field: 'contract_item_uuid',
        label: 'Item',
        props: {
          apiParams: {
            query: `contract_uuid = '${this.getItem().contract_uuid}'`,
            order: 'reference',
          },
        },
      },
      source: {
        component: SourceLocations,
        field: 'contract_source_location_uuid',
        label: 'Source Location',
        props: {
          isRequired: true,
          apiParams: {
            query: `contract_source_locations.contract_uuid = '${this.getItem().contract_uuid}'`,
          },
        },
      },
    }
  );

  schedule = () => this.props.additionalFormProps.schedule || {};

  nomination = () => this.props.nomination;

  isNomination = () => this.props.additionalFormProps.type === 'nomination';

  balance = (item) => `${item.balance} ${item.unit_of_measure_symbol}`;

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    let formKey = this.state.formKey;
    if (['bill_to_entity_uuid', 'endorsee_entity_uuid'].includes(event.target.name)) {
      formKey += 1;
    }
    this.setVariables({ item: newData, formKey });
  }

  nominationTop = () => (
    <div>
      {this.getTextRow('Counterparty', this.schedule().counterparty_name)}
      {this.getTextRow('Schedule Delivery Start', this.schedule().delivery_start_formatted)}
      {this.getTextRow('Schedule Delivery End', this.schedule().delivery_end_formatted)}
      {this.getTextRow('Schedule Balance', this.balance(this.schedule()))}
      <hr />
      {this.dropdownField('billTo')}
      <ShipToField
        key={this.state.formKey}
        auth={this.props.auth}
        entityUuid={this.getItem().bill_to_entity_uuid}
        item={this.getItem()}
        onChange={this.onChange}
      />
      {this.dropdownField('source')}
      {this.dropdownField('items')}
    </div>
  );

  endorsementTop = () => (
    <div>
      {this.getTextRow('Endorser', this.nomination().endorsee_name)}
      {this.getTextRow('Source Location', this.nomination().agris_bin_desc)}
      {this.getTextRow('Item', this.nomination().item_name)}
      {this.getTextRow('Nomination Delivery Start', this.nomination().delivery_start_date)}
      {this.getTextRow('Nomination Delivery End', this.nomination().delivery_end_date)}
      {this.getTextRow('Nomination Balance', this.balance(this.nomination()))}
      <hr />
      {this.dropdownField('endorsee')}
      <ShipToField
        key={this.state.formKey}
        auth={this.props.auth}
        entityUuid={this.getItem().endorsee_entity_uuid}
        item={this.getItem()}
        onChange={this.onChange}
      />
    </div>
  );

  modalBodyRows = () => (
    <div>
      {this.isNomination() ? this.nominationTop() : this.endorsementTop()}
      {this.getTextFieldRow('quantity', 'Quantity')}
      {this.getDateFieldRow('delivery_start_date', 'Delivery Start')}
    </div>
  )
}));
