import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CarrierAgencies extends OptionsBaseApi {
  getApiName = () => 'entities/entities';

  getApiParams = () => ({
    show_all: 'true',
    sorted: JSON.stringify([{ id: 'name', desc: false }]),
    filtered: JSON.stringify([
      { id: 'active', value: 'yes' },
      { id: 'carrier_agency', value: 'yes' },
    ]),
  });

  getDropdownLabelText = () => this.props.fieldLabel || 'Carrier Agency';

  getDropdownFieldName = () => this.props.fieldName || 'carrier_agency_entity_uuid';
}
