import { Api, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Merchandisers extends OptionsBaseApi {
  getApiName = () => 'agris/merchandisers';

  getDropdownLabelText = () => this.props.fieldLabel || 'Merchandiser';

  getDropdownFieldName = () => this.props.fieldName || 'merchandiser_code';

  getValueFieldName = () => 'name';

  additionalFields = () => ['code'];
}
