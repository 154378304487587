import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Incoterms extends OptionsBaseApi {
  getApiName = () => 'contracts/incoterms';

  getDropdownLabelText = () => this.props.fieldLabel || 'Incoterms';

  getDropdownFieldName = () => this.props.fieldName || 'incoterm_uuid';

  getLabelFieldName = () => 'code';

  additionalFields = () => ['destination_required'];
}
