import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const buttonDisplay = (context, row) => (
  <div>
    { context.isEditAdmin()
      ? (
        <>
          <ColumnButton
            className="otto__margin_btm_10"
            onClick={() => context.handleOpenModal(row.index)}
            text="Check Out"
          />
          <ColumnButton
            className="otto__margin_btm_10"
            onClick={() => context.handleOpenModal(row.original, 'CancelCheckIn')}
            text="Cancel Check In"
          />
          <ColumnButton
            className="otto__margin_btm_10"
            onClick={() => context.reprintPickupTicket(row.original.uuid)}
            text="Pickup Ticket"
          />
          <ColumnButton
            className="otto__margin_btm_10"
            onClick={() => context.handleOpenModal(row.index, 'ManualWeight')}
            text="Tare Weight Entry"
          />
        </>
      )
      : '' }
    { context.isBarcelona() && context.isLoader()
      ? <ColumnButton onClick={() => context.handleOpenModal(row.index, 'Loaders')} text="Add Storage Unit" />
      : '' }
  </div>
);

const Columns = (context) => [
  {
    Header: 'Check In Time',
    accessor: 'check_in_at',
    className: 'text-center',
  },
  {
    Header: 'Order ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'RFID',
    accessor: 'rfid',
    className: 'text-center',
    width: 50,
    show: context.isBarcelona(),
  },
  {
    Header: 'Customer PO',
    accessor: 'po_number',
    className: 'text-left',
    width: 120,
  },
  {
    Header: 'Truck Plate',
    accessor: 'truck_plate',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Driver Name',
    accessor: 'driver_name',
    className: 'text-left',
    width: 130,
  },
  {
    Header: 'Driver Phone',
    accessor: 'driver_cell_phone',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Carrier',
    accessor: 'carrier_name',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (<pre>{row.original.ship_to}</pre>),
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => buttonDisplay(context, row),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 170,
    show: context.isEditAdmin() || context.isLoader(),
    csvShow: false,
  },
];

export default Columns;
