import { OptionsBaseApi, HtmlHelpers } from '@westernmilling/eu_react_shared';

export default class CommodityBoards extends OptionsBaseApi {
  getApiName = () => 'items/commodity_boards';

  getApiParams = () => this.props.apiParams || [];

  getDropdownLabelText = () => this.props.fieldLabel || 'Commodity Board';

  getDropdownFieldName = () => this.props.fieldName || 'commodity_board_uuid';

  additionalFields = () => ['code'];
}
