import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, TableTitle } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/DirectOrders';

export default withAuth(withAlert()(class DirectOrders extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/direct_orders';

  nominationStr = () => (
    this.props.nomination.purchase_sales === 'S' ? 'sales_nomination_uuid' : 'purchase_nomination_uuid'
  );

  getListParams = () => ({ show_all: true, filtered: [{ id: this.nominationStr(), value: this.props.nomination.uuid }] });

  getStorageName = () => 'otto_direct_orders_table_list';

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  getPageTitle = () => ('Direct Orders');

  auditLogModelType = () => 'Contracts::DirectOrder';

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

  additionalFormProps = () => ({ fromNomination: true, nomination: this.props.nomination });

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  updateData = () => this.props.updateData();

  smallTitle = () => (
    <Row>
      <Col md="8" className="text-left">
        <TableTitle title={this.getPageTitle()} />
      </Col>
    </Row>
  );
}));
