import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Order ID',
    accessor: 'id',
    Cell: (row) => Links.getLink('contracts', `truck_orders/view/${row.original.uuid}`, row.original.id),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Nomination ID',
    accessor: 'nomination_id',
    className: 'text-center',
    show: false,
    csvShow: true,
  },
  {
    id: 'contract_schedule_number',
    Header: 'Contract Schedule',
    accessor: (d) => `${d.contract_id} - ${d.contract_schedule_number}`,
    Cell: (row) => {
      let display = '';
      if (HtmlHelpers.isNotEmpty(row.original.contract_schedule_number)) {
        const name = `${row.original.contract_id} - ${row.original.contract_schedule_number}`;
        display = Links.getLink('contracts', `schedules/view/${row.original.contract_schedule_uuid}`, name);
      }
      return display;
    },
    className: 'text-center',
    width: 130,
  },
  {
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (
      <pre>
        {row.original.ship_to}
      </pre>
    ),
    className: 'text-left',
  },
  {
    id: 'bill_to_entity__name',
    Header: 'Bill To',
    accessor: 'bill_to_name',
    className: 'text-left',
  },
  {
    id: 'customer__name',
    Header: 'Endorsee',
    accessor: 'customer_name',
    className: 'text-left',
  },
  {
    id: 'carrier__name',
    Header: 'Carrier',
    accessor: 'carrier_name',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'quantity',
    Header: 'Quantity',
    accessor: (d) => d.quantity_shipped || d.quantity_ordered,
    className: 'text-right',
    width: 120,
  },
  {
    id: 'unit_of_measure__symbol',
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-right',
    width: 60,
  },
  {
    id: 'pickup_shipped',
    Header: 'Pickup Date',
    accessor: (d) => d.shipped_date || d.pickup_date || '',
    className: 'text-center',
    width: 100,
  },
  {
    id: 'time_slot_appointment__time_slot_starts_at',
    Header: 'Time Slot',
    accessor: 'time_slot_range',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Truck Plate',
    accessor: 'truck_plate',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Invoice #',
    accessor: 'invoice_number',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Customer PO',
    accessor: 'po_number',
    className: 'text-center',
    show: false,
    csvShow: true,
  },
  {
    Header: 'Albaran',
    accessor: 'document_number',
    className: 'text-center',
    show: false,
    csvShow: true,
  },
];

export default Columns;
