import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import BatchNumbers from 'components/Options/BatchNumbers';
import CarrierField from 'components/Forms/CarrierField';
import ShipToField from 'components/Forms/ShipToField';

export default withAuth(withAlert()(class UpcomingOrdersForm extends CrudForm {
  formType = () => '';

  getCrudType = () => 'contracts/truck_orders';

  successAlertMsg = () => this.successMsg('Truck Order');

  submitLabel = () => this.props.additionalFormProps?.submitLabel || this.props.submitLabel;

  headerTitle = () => this.props.additionalFormProps?.headerTitle || this.props.headerTitle;

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  getModalSize = () => 'xl';

  options = () => (
    {
      batch: {
        component: BatchNumbers,
        field: 'batch_number',
        props: { isRequired: true, itemUuid: this.getItem().item_uuid, agrisBin: this.getItem().agris_bin_desc },
      },
    }
  );

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    if (this.props.additionalFormProps?.checkIn) {
      const link = document.createElement('a');
      link.href = data.attributes.pickup_ticket_pdf_url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    } else {
      this.props.closeModal();
    }
    this.props.updateData(data);
  }

  shipToEntityUuid = () => (
    HtmlHelpers.isEmpty(this.getItem().endorsee_entity_uuid)
      ? this.getItem().bill_to_entity_uuid
      : this.getItem().endorsee_entity_uuid
  );

  carrierField = () => (
    <CarrierField
      auth={this.props.auth}
      entityUuid={this.getItem().carrier_entity_uuid}
      item={this.getItem()}
      onChange={this.onChange}
      isRequired={!this.props.reception}
    />
  );

  shipTo = () => (
    this.getItem().transfer ? '' : (
      <ShipToField
        auth={this.props.auth}
        entityUuid={this.shipToEntityUuid()}
        item={this.getItem()}
        onChange={this.onChange}
        isRequired={!this.props.reception}
      />
    )
  );

  transferTo = () => (
    this.getItem().transfer ? (
      this.getTextRow('Transfer To', this.getItem().transfer_to_agris_bin_desc)
    ) : ''
  );

  buildApiVariables = () => {
    const data = { ...this.getItem() };
    if (this.props.additionalFormProps?.checkIn) {
      data.truck_check_in = 'Y';
    } else if (this.props.reception) {
      data.truck_reception = 'Y';
    }
    return data;
  };

  isBarcelona = () => this.getItem().agris_bin_desc === this.props.additionalFormProps.barcelona;

  modalBodyRows = () => (
    <div>
      {this.getTextRow('Order ID', this.getItem().id)}
      {this.getTextRow('Item', this.getItem().item_name)}
      {this.getTextRow('Quantity', `${this.getItem().quantity_ordered} ${this.getItem().unit_of_measure_symbol}`)}
      {this.transferTo()}
      <hr />
      {this.carrierField()}
      {this.getTextFieldRow('driver_name', 'Driver', !this.props.reception)}
      {this.getTextFieldRow('driver_id', 'Driver ID', !this.props.reception)}
      {this.getTextFieldRow('driver_cell_phone', 'Driver Cell Phone', false)}
      {this.getTextFieldRow('truck_plate', 'Truck Plate', !this.props.reception)}
      {this.props.additionalFormProps?.checkIn ? this.getTextFieldRow('trailer_plate', 'Trailer Plate', false) : ''}
      {this.shipTo()}
      {this.props.additionalFormProps?.checkIn && this.isBarcelona() ? this.getTextFieldRow('rfid', 'RFID') : ''}
      {this.props.additionalFormProps?.checkIn && this.isBarcelona() ? this.getTextFieldRow('seals', 'Seal', false) : ''}
      {this.props.additionalFormProps?.checkIn ? this.dropdownField('batch') : ''}
    </div>
  )
}));
