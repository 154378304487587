import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';
import moment from 'moment';

export default withAuth(withAlert()(class DestinationWeightModal extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item = props.item;
    this.state.item.arrival_date = moment().format('DD/MM/YYYY');
  }

  formType = () => '';

  headerTitle = () => 'Destination Weight Entry'

  submitLabel = () => 'Update Destination Weight'

  getCrudType = () => `contracts/${this.props.path}`;

  successAlertMsg = () => this.successMsg(`${this.props.orderType} Order`);

  buildApiVariables = () => ({
    action_destination_weight: 'Y',
    arrival_date: this.getItem().arrival_date,
    destination_weight: this.getItem().destination_weight,
    uuid: this.getItem().uuid,
  });

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    this.props.updateData();
    this.props.closeModal();
  }

  modalBodyRows = () => (
    <div>
      { this.getNumericFieldRow('destination_weight', 'Destination Weight (kg)', true, { step: 1, min: 1 }) }
      { this.getDateFieldRow('arrival_date', 'Arrival Date', false) }
    </div>
  )
}));
