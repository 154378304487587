import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class BrokerEntities extends OptionsBaseApi {
  getApiName = () => 'entities/entities';

  getApiParams = () => ({
    filtered: JSON.stringify([
      { id: 'active', value: 'yes' },
      { id: 'broker', value: 'yes' },
    ]),
    sorted: JSON.stringify([{ id: 'name', desc: false }]),
    show_all: 'true',
  });

  getDropdownLabelText = () => this.props.fieldLabel || 'Broker';

  getDropdownFieldName = () => this.props.fieldName || 'broker_entity_uuid';
}
