import { Api, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class TransportationModes extends OptionsBaseApi {
  getApiName = () => 'agris/transportation_modes';

  getDropdownLabelText = () => this.props.fieldLabel || 'Transportation Mode';

  getDropdownFieldName = () => this.props.fieldName || 'transportation_mode_name';

  getValueFieldName = () => 'name';

  additionalFields = () => ['code'];
}
