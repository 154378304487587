import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  AlertErrors, CrudForm, HtmlHelpers, UserGroups, WmAuth, WmForm, WmReactTable,
} from '@westernmilling/eu_react_shared';
import { Col } from 'reactstrap';

import InboundTemplate from 'components/Options/InboundTemplates';
import TruckTransferUploadResults from 'components/Tables/TruckTransferUploadResults';

export default withAuth(withAlert()(class InboundTransferUploadContainer extends CrudForm {
  async componentDidMount() {
    const userInfo = await UserGroups.UserInfo(this.props.auth);
    if (this.props.setUserInfo !== undefined) {
      this.props.setUserInfo(userInfo);
    }
    await this.setState({ userGroups: userInfo.groups });
  }

  getCrudType = () => 'contracts/truck_order_inbound_transfer_uploads';

  formType = () => '';

  onSubmit = (event) => this.formDataSubmit(event);

  getLabelColumns = () => 4;

  getFieldColumns = () => 4;

  getAddGroups = () => ['execution_admin'];

  options = () => (
    {
      template: {
        component: InboundTemplate,
        field: 'template',
        props: { isRequired: true },
      },
    }
  );

  onChangeFileEvent = async (event) => {
    const newData = { ...this.state.item };
    const target = event.target;

    newData[target.name] = target.files[0];
    return newData;
  }

  formData = () => {
    const form = new FormData();
    if (this.state.item.attachment !== undefined) {
      form.append('attachment', this.state.item.attachment);
    }
    return form;
  }

  disclaimerNotes = () => (
    <div>
      Notes:
      <ul>
        <li>The file type must be xlsx</li>
        <li>The column headers must be the 1st row and match the following order exactly</li>
        <li>
          After the file has been uploaded, if the Updated to Otto column is Yes, the export to Agris has been created.
          To verify the export status, please view the export section of the truck order view page.
        </li>
      </ul>
      <br />
      <b>Verdu Template Columns:</b>
      <br />
      FECHA, ALBARAN, SU ALB., MATRICULA, KGS ORIGEN, KGS VERDU, PRODUCTO, LOTE
    </div>
  )

  handleSuccess = (data) => {
    const info = data !== undefined ? data.map((d) => d.attributes) : [];

    this.setState((prevState) => (
      {
        data: info, disabled: false, item: { attachment: null }, formKey: prevState.formKey + 1,
      }
    ));
  }

  results = () => (HtmlHelpers.isEmpty(this.state.data) ? '' : (
    <div>
      <hr />
      <TruckTransferUploadResults data={this.state.data} />
    </div>
  ));

  additionalValidations = () => {
    const errors = [];
    const extension = this.state.item.attachment.name.split('.').pop();
    if (extension !== 'xlsx') {
      errors.push('The file type must be .xlsx');
    }
    this.setState({ errors });
    return errors;
  };

  getContents = () => (
    <div>
      <h2>Inbound Transfer Upload</h2>
      <WmForm key={this.state.formKey} submit={this.onSubmit} ref={this.formRef} id="form__crud">
        {this.dropdownField('template')}
        {this.getUploadFileRow('attachment', '', true)}
        <Col md="12" xs="12" className="text-center">
          {WmAuth.isEditAdmin(this.state, this.getAddGroups()) ? this.getButton('Upload File') : 'You are not authorized'}
        </Col>
      </WmForm>
      <br />
      {this.disclaimerNotes()}
      <AlertErrors errors={this.state.errors} />
      {this.results()}
    </div>
  );
}));
