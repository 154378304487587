import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const buttonDisplay = (context, row) => {
  const arriveBtn = context.isReception() && HtmlHelpers.isEmpty(row.original.arrived_at);
  const checkInBtn = context.isEditAdmin();
  return (
    <div>
      { checkInBtn
        ? (
          <ColumnButton
            className="otto__margin_btm_10"
            onClick={() => context.handleOpenModal(row.index)}
            text="Check In"
          />
        )
        : '' }
      { arriveBtn
        ? (
          <ColumnButton
            onClick={() => context.handleOpenModal(row.index, 'Reception')}
            text="Register Truck"
          />
        )
        : '' }
    </div>
  );
};

const Columns = (context) => [
  {
    Header: 'Pickup Date',
    accessor: 'pickup_date',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Time Slot',
    accessor: 'time_slot_range',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Arrived At',
    accessor: 'arrived_at',
    className: 'text-center',
    width: 160,
  },
  {
    Header: 'Order ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Customer PO',
    accessor: 'po_number',
    className: 'text-left',
  },
  {
    Header: 'Customer',
    accessor: 'customer_name',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (<pre>{row.original.ship_to}</pre>),
    className: 'text-left',
  },
  {
    Header: 'Carrier',
    accessor: 'carrier_name',
    className: 'text-left',
  },
  {
    Header: 'Driver Name',
    accessor: 'driver_name',
    className: 'text-left',
    width: 130,
  },
  {
    Header: 'Driver Cell Phone',
    accessor: 'driver_cell_phone',
    className: 'text-center',
    width: 130,
  },
  {
    Header: 'Truck Plate',
    accessor: 'truck_plate',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    Header: 'Final Usage',
    accessor: 'final_usage',
    className: 'text-center',
    width: 100,
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => buttonDisplay(context, row),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 150,
    show: context.isEditAdmin() || context.isReception(),
    csvShow: false,
  },
];

export default Columns;
