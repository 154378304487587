import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Group Name',
    accessor: 'name',
    className: 'text-left',
    width: 350,
  },
  {
    Header: 'Items',
    accessor: 'item_names',
    className: 'text-left',
  },
  {
    Header: 'Customers',
    accessor: 'customer_names',
    className: 'text-left',
  },
  {
    Header: 'Days in Advance',
    accessor: 'days_in_advance',
    className: 'text-center',
    width: 150,
  },
  ColumnHelpers.activeColumn(),
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
