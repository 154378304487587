import React from 'react';
import { CrudForm } from '@westernmilling/eu_react_shared';

import Currencies from 'components/Options/Currencies';
import Counterparty from 'components/Options/Counterparty';
import Items from 'components/Options/Items';
import MarketZones from 'components/Options/MarketZones';
import Merchandisers from 'components/Options/Merchandisers';
import PaymentMethods from 'components/Options/PaymentMethods';
import PurchaseSales from 'components/Options/PurchaseSales';
import Terms from 'components/Options/Terms';
import TransportationModes from 'components/Options/TransportationModes';

export default class BasicInfo extends CrudForm {
  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.data || {};

  options = () => (
    {
      currency: {
        component: Currencies,
        field: 'currency_uuid',
        label: 'Currency',
        props: { isRequired: true },
        currentValue: this.getItem().currency_uuid,
      },
      customers: {
        component: Counterparty,
        field: 'counterparty_uuid',
        label: 'Counterparty',
        props: {
          isRequired: true,
          apiParams: { customerVendors: 'Y', show_all: 'true', sorted: JSON.stringify([{ id: 'name', desc: false }]) },
        },
        currentValue: this.getItem().counterparty_uuid,
      },
      items: {
        component: Items,
        field: 'item_uuids',
        props: { isRequired: true, commodityCodeUuid: this.props.commodityCodeUuid, apiParams: { show_all: 'true' } },
        multiple: true,
        currentValue: this.getItem().item_uuids,
      },
      marketZone: {
        component: MarketZones,
        field: 'market_zone_name',
        props: { isRequired: true },
        currentValue: this.getItem().market_zone_name,
      },
      merchandiser: {
        component: Merchandisers,
        field: 'merchandiser_name',
        props: { isRequired: true },
        currentValue: this.getItem().merchandiser_name,
      },
      paymentMethods: {
        component: PaymentMethods,
        field: 'payment_method_uuid',
        props: { isRequired: true },
        currentValue: this.getItem().payment_method_uuid,
      },
      purchaseSales: {
        component: PurchaseSales,
        field: 'purchase_sales',
        props: { isRequired: true },
        currentValue: this.getItem().purchase_sales,
      },
      terms: {
        component: Terms,
        field: 'term_uuid',
        props: { isRequired: true },
        currentValue: this.getItem().term_uuid,
      },
      transportation: {
        component: TransportationModes,
        field: 'transportation_mode_name',
        props: { isRequired: true },
        currentValue: this.getItem().transportation_mode_name,
      },
    }
  );

  modalBodyRows = () => (
    <div>
      {this.getDateFieldRow('contract_date', 'Contract Date', true, { value: this.getItem().contract_date })}
      {this.dropdownField('purchaseSales')}
      {this.dropdownField('transportation')}
      {this.dropdownField('merchandiser')}
      {this.dropdownField('customers')}
      {this.dropdownField('marketZone')}
      {this.dropdownField('terms')}
      {this.dropdownField('paymentMethods')}
      {this.dropdownField('currency')}
      {this.getNumericFieldRow('plancha', 'Plancha (days)', true, { min: 1, max: 30, value: this.getItem().plancha })}
      <br />
    </div>
  );

  submitButtons = () => (
    <div className="text-center">
      {this.getButton('Continue', (event) => this.props.continue(event, 2))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Cancel', this.closeModal)}
    </div>
  );

  modalPage = () => (
    <div>
      { this.modalBodyRows() }
      { this.showButtonsAndErrors() ? this.buttonsAndErrors() : '' }
      { this.underButtons() }
    </div>
  )
}
