import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/UpcomingTimeSlots';

export default withAuth(withAlert()(class UpcomingTimeSlotsListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/time_slot_appointments';

  getListParams = () => ({ upcoming_list: 'Y' });

  getStorageName = () => 'otto_contracts_upcoming_time_slots_list';

  getPageTitle = () => 'Upcoming Time Slots';

  getEditGroups = () => ['execution_admin'];

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  availability = (uuid, type) => (
    Api.crudUpdate(this.props.auth, 'contracts/time_slot_appointments', { uuid, type })
      .then((response) => {
        if (response.data !== undefined) {
          this.props.alert.show(`Time Slot Made ${type} Successfully`, { type: 'success' });
          this.updateData();
        }
      })
  );
}));
