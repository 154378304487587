import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import {
  Attachments, CrudForm, HtmlHelpers, ExportTable, Links,
} from '@westernmilling/eu_react_shared';

import VesselOrderNotes from 'components/Tables/VesselOrderNotes';

export default withAuth(withAlert()(class VesselOrderViewForm extends CrudForm {
  formType = () => 'Vessel Order';

  getCrudType = () => 'contracts/vessel_orders';

  contractLink = () => Links.getLink(
    'contracts',
    `contracts/view/${this.getItem().contract_uuid}`,
    this.getItem().contract_id,
  );

  nominationLink = () => Links.getLink(
    'contracts',
    `nominations/view/${this.getItem().nomination_uuid}`,
    this.getItem().nomination_id,
  );

  itemLink = () => Links.getLink(
    'items',
    `items/view/${this.getItem().item_uuid}`,
    this.getItem().item_name,
  );

  uom = () => this.getItem().unit_of_measure_symbol;

  formatQty = (label, amt) => this.getRowWithValue(label, (HtmlHelpers.isEmpty(amt) ? '' : `${amt} ${this.uom()}`));

  isPurchase = () => this.getItem().purchase_sales === 'P';

  isDestination = () => this.getItem().weight_base === 'D'

  additionalFormProps = () => ({
    nomination_uuid: this.getItem().nomination_uuid,
    contract_uuid: this.getItem().contract_uuid,
  });

  dateDisplay = () => (
    this.isPurchase()
      ? this.getRow('arrival_date_formatted', 'Arrival Date')
      : this.getRow('shipped_date_formatted', 'Shipped Date')
  )

  col1 = () => (
    <Col md={4} sm={12}>
      {this.getRow('order_number', 'Order ID')}
      {this.getRowWithValue('Nomination', this.nominationLink())}
      {this.getRowWithValue('Contract', this.contractLink())}
      {this.getRow('counterparty_name', this.isPurchase() ? 'Remit To' : 'Bill To')}
    </Col>
  );

  col2 = () => (
    <Col md={4} sm={12}>
      {this.formatQty('Origin Qty', this.getItem().origin_quantity)}
      {this.formatQty('Destination Qty', this.getItem().destination_quantity)}
      {this.getRow('weight_base', 'Weight Base')}
      {this.dateDisplay()}
      {this.isDestination() ? this.getRow('arrival_date_formatted', 'Arrival Date') : ''}
    </Col>
  );

  col3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('status', 'Order Status')}
      {this.getRow('agris_bin_desc', 'Source')}
      {this.getRowWithValue('Item', this.itemLink())}
      {this.getRow('vessel_name', 'Vessel Name')}
    </Col>
  );

  agCol1 = () => (
    <Col md={4} sm={12}>
      {this.getRow('grain_ticket', 'Grain Ticket')}
      {this.getRow('delivery_sheet', 'Delivery Sheet')}
    </Col>
  );

  agCol2 = () => (
    <Col md={4} sm={12}>
      {this.getRow('invoice_date', 'Invoice Date')}
      {this.getRow('invoice_due_date', 'Invoice Due Date')}
    </Col>
  );

  agCol3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('invoice_number', 'Invoice Number')}
      {this.getRow('settlement_number', 'Settlement Number')}
    </Col>
  );

  exports = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <ExportTable
      auth={this.props.auth}
      modelType="Contracts::VesselOrder"
      modelUuid={this.getItem().uuid}
      showExportButton={false}
    />
  ));

  modalBodyRows = () => (
    <div>
      <Row>
        {this.col1()}
        {this.col2()}
        {this.col3()}
      </Row>
      <br />
      <hr />
      <h4>
        Agris Details
      </h4>
      <Row>
        {this.agCol1()}
        {this.agCol2()}
        {this.agCol3()}
      </Row>
      {this.exports()}
      <VesselOrderNotes auth={this.props.auth} uuid={this.getItem().uuid} />
    </div>
  )
}));
