import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Order ID',
    accessor: 'id',
    Cell: (row) => Links.getLink('contracts', `transfer_truck_orders/view/${row.original.uuid}`, row.original.id),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
  },
  {
    id: 'transfer_order__agris_bin_desc',
    Header: 'Transfer To',
    accessor: 'transfer_to_agris_bin_desc',
    className: 'text-left',
  },
  {
    id: 'carrier__name',
    Header: 'Carrier',
    accessor: 'carrier_name',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'quantity',
    Header: 'Quantity',
    accessor: (d) => d.quantity_shipped || d.quantity_ordered,
    className: 'text-right',
    width: 150,
  },
  {
    id: 'unit_of_measure__symbol',
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-right',
    width: 150,
  },
  {
    id: 'pickup_shipped',
    Header: 'Pickup Date',
    accessor: (d) => d.shipped_date || d.pickup_date || '',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Truck Plate',
    accessor: 'truck_plate',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Albaran',
    accessor: 'document_number',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    width: 150,
  },
];

export default Columns;
