import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class ContractSourceLocations extends OptionsBaseApi {
  getApiName = () => 'contracts/contract_source_locations';

  getApiParams = () => this.props.apiParams || [];

  getDropdownLabelText = () => 'Source Location';

  getDropdownFieldName = () => this.props.fieldName || 'contract_source_location_uuid';

  getLabelFieldName = () => this.props.labelField || 'source_with_incoterm';

  getValueFieldName = () => this.props.valueField || 'uuid';

  additionalFields = () => ['agris_bin_desc'];
}
