import React from 'react';
import {
  ColumnButton, HtmlHelpers, TableFilters, Links,
} from '@westernmilling/eu_react_shared';

const OrderColumns = (context) => [
  {
    Header: 'Order ID',
    accessor: 'id',
    Cell: (row) => Links.getLink('contracts', `truck_orders/view/${row.original.uuid}`, row.original.id),
    className: 'text-center',
    width: 100,
  },
  {
    id: 'contract__merchandiser_name',
    Header: 'Merchandiser',
    accessor: 'merchandiser_name',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'quantity',
    Header: 'Quantity',
    accessor: (d) => d.quantity_shipped || d.quantity_ordered,
    className: 'text-right',
    width: 150,
  },
  {
    id: 'bill_to_entity__name',
    Header: 'Bill To',
    accessor: 'bill_to_name',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (
      <pre>
        {row.original.ship_to}
      </pre>
    ),
    className: 'text-left',
  },
  {
    Header: 'Pickup Date',
    accessor: 'pickup_date',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Time Slot',
    accessor: 'time_slot_range',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    width: 150,
  },
  {
    id: 'edit',
    Header: '',
    Cell: (row) => (<ColumnButton onClick={() => context.removeCreditHold(row.original.uuid)} text="Clear" />),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 100,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default OrderColumns;
