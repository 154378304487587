const Columns = (context, showCode = true) => [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    Header: 'Code',
    accessor: 'code',
    className: 'text-center',
    show: showCode,
  },
];

export default Columns;
