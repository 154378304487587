import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import {
  Attachments, CrudForm, HtmlHelpers, ExportTable, Links,
} from '@westernmilling/eu_react_shared';

import DirectOrderNotes from 'components/Tables/DirectOrderNotes';

export default withAuth(withAlert()(class DirectOrderViewForm extends CrudForm {
  formType = () => 'Direct Order';

  getCrudType = () => 'contracts/direct_orders';

  nominationLink = (id, uuid) => Links.getLink('contracts', `nominations/view/${uuid}`, id);

  itemLink = () => Links.getLink(
    'items',
    `items/view/${this.getItem().item_uuid}`,
    this.getItem().item_name,
  );

  uom = () => this.getItem().unit_of_measure_symbol;

  formatQty = (label, amt) => this.getRowWithValue(label, (HtmlHelpers.isEmpty(amt) ? '' : `${amt} ${this.uom()}`));

  isPurchase = () => this.getItem().purchase_sales === 'P';

  isDestination = () => this.getItem().weight_base === 'D';

  isVessel = () => this.getItem().transportation_mode_code === 'Vessel';

  additionalFormProps = () => ({
    nomination_uuid: this.getItem().nomination_uuid,
    contract_uuid: this.getItem().contract_uuid,
  });

  purchaseNomination = () => (
    this.getRowWithValue(
      'Purchase Nomination',
      this.nominationLink(
        this.getItem().purchase_nomination_id,
        this.getItem().purchase_nomination_uuid,
      ),
    )
  );

  salesNomination = () => (
    this.getRowWithValue(
      'Sales Nomination',
      this.nominationLink(
        this.getItem().sales_nomination_id,
        this.getItem().sales_nomination_uuid,
      ),
    )
  );

  vesselBol = () => (
    this.isVessel() ? this.getRow('vessel_name', 'Vessel Name') : this.getRow('bill_of_lading_number', 'BOL #')
  );

  col1 = () => (
    <Col md={4} sm={12}>
      {this.getRow('order_number', 'Order ID')}
      {this.purchaseNomination()}
      {this.getRow('vendor', 'Vendor')}
      {this.salesNomination()}
      {this.getRow('customer', 'Customer')}
    </Col>
  );

  col2 = () => (
    <Col md={4} sm={12}>
      {this.vesselBol()}
      {this.getRow('origin_weight', 'Origin Weight (kg)')}
      {this.getRow('destination_weight', 'Destination Weight (kg)')}
      {this.getRow('shipped_date_formatted', 'Shipped Date')}
      {this.getRow('arrival_date_formatted', 'Arrival Date')}
    </Col>
  );

  col3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('status', 'Order Status')}
      {this.getRowWithValue('Item', this.itemLink())}
      {this.getRow('final_usage', 'Final Usage')}
      {this.getRow('customer_po', 'Customer PO')}
    </Col>
  );

  shippingSection = () => (
    this.isVessel() ? '' : (
      <div>
        <br />
        <hr />
        <h4>
          Shipper Details
        </h4>
        <Row>
          <Col md={4} sm={12}>
            {this.getRow('carrier', 'Carrier')}
          </Col>
          <Col md={4} sm={12}>
            {this.getRow('truck_plate', 'Truck Plate')}
          </Col>
          <Col md={4} sm={12}>
            {this.getRow('trailer_plate', 'Trailer Plate')}
          </Col>
        </Row>
      </div>
    )
  );

  agCol1 = () => (
    <Col md={4} sm={12}>
      {this.getRow('outbound_grain_ticket', 'Grain Ticket')}
      {this.getRow('inbound_grain_ticket', 'Inbound Grain Ticket')}
    </Col>
  );

  agCol2 = () => (
    <Col md={4} sm={12}>
      {this.getRow('invoice_date_formatted', 'Invoice Date')}
      {this.getRow('invoice_due_date_formatted', 'Invoice Due Date')}
    </Col>
  );

  agCol3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('invoice_number', 'Invoice Number')}
      {this.getRow('settlement_number', 'Settlement Number')}
    </Col>
  );

  exports = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <ExportTable
      auth={this.props.auth}
      modelType="Contracts::DirectOrder"
      modelUuid={this.getItem().uuid}
      showExportButton={false}
    />
  ));

  attachments = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <Attachments
      auth={this.props.auth}
      alert={this.props.alert}
      modelType="Contracts::DirectOrder"
      modelUuid={this.getItem().uuid}
      isAddAdmin
    />
  ));

  modalBodyRows = () => (
    <div>
      <Row>
        {this.col1()}
        {this.col2()}
        {this.col3()}
      </Row>
      {this.shippingSection()}
      <br />
      <hr />
      <h4>
        Agris Details
      </h4>
      <Row>
        {this.agCol1()}
        {this.agCol2()}
        {this.agCol3()}
      </Row>
      {this.attachments()}
      {this.exports()}
      <DirectOrderNotes auth={this.props.auth} uuid={this.getItem().uuid} />
    </div>
  )
}));
