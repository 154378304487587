import { OptionsBaseApi, HtmlHelpers } from '@westernmilling/eu_react_shared';

export default class Counterparty extends OptionsBaseApi {
  getApiName = () => 'entities/entities';

  getApiParams = () => this.props.apiParams || [];

  getDropdownLabelText = () => this.props.fieldLabel || 'Counterparty';

  getDropdownFieldName = () => this.props.fieldName || 'counterparty_uuid';
}
