import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudForm, DeleteIcon, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Customers from 'components/Options/Counterparty';
import Items from 'components/Options/Items';

export default withAuth(withAlert()(class TimeSlotGroupForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.customers ||= [{}];
    this.state.item.items ||= [{}];
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
    this.state.item.customer_specific ||= false;
  }

  formType = () => 'Time Slot Group';

  getCrudType = () => 'contracts/time_slot_groups';

  successAlertMsg = () => this.successMsg('Time Slot Group');

  usedData = (data, uuid) => (
    HtmlHelpers.isEmpty(this.getItem()[data])
      ? []
      : this.getItem()[data].map((i) => i[uuid]).filter((d) => d)
  );

  deleteIcon = (idx, onChange) => (
    <DeleteIcon iconSize="1x" onClick={onChange} />
  );

  getCustomers = () => (
    this.getItem().customers.map((row, idx) => this.getCustomerRow(idx, row))
  );

  getItems = () => (
    this.getItem().items.map((row, idx) => this.getItemRow(idx, row))
  );

  getCustomerRow = (idx, row) => (
    <Row key={`cust_field_${idx + 1}`}>
      <Col md="4" className="text-right otto__label" style={{ paddingTop: '9px' }}>
        {idx === 0 ? 'Customer' : ''}
      </Col>
      <Col md="5">
        {this.dropdownField('customers', idx)}
      </Col>
      <Col md="3" style={{ paddingTop: '4px' }}>
        { idx > 0
          ? this.deleteIcon(idx, () => this.removeData(idx, 'customers', 'customer_entity'))
          : this.getButton('Add Another Customer', this.addCustomers) }
      </Col>
    </Row>
  );

  getItemRow = (idx, row) => (
    <Row key={`item_field_${idx + 1}`}>
      <Col md="4" className="text-right otto__label" style={{ paddingTop: '9px' }}>
        {idx === 0 ? 'Item' : ''}
      </Col>
      <Col md="5">
        {this.dropdownField('items', idx)}
      </Col>
      <Col md="3" style={{ paddingTop: '4px' }}>
        { idx > 0
          ? this.deleteIcon(idx, () => this.removeData(idx, 'items', 'item'))
          : this.getButton('Add Another Item', this.addItems) }
      </Col>
    </Row>
  );

  addCustomers = () => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.customers.push({});
    return { item };
  });

  addItems = () => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.items.push({});
    return { item };
  });

  removeData = (idx, arr, str) => this.setState((prevState) => {
    const item = { ...prevState.item };
    item[arr].splice(idx, 1);
    delete item[`${str}_uuid_${idx}`];
    return { item };
  });

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;
    const last = name.lastIndexOf('_');
    const index = name.substring(last + 1);

    if (name.includes('customer_entity_uuid_')) {
      newData.customers[index].customer_entity_uuid = newData[name];
    }

    if (name.includes('item_uuid_')) {
      newData.items[index].item_uuid = newData[name];
    }

    this.setVariables({ item: newData });
  }

  options = (idx) => (
    {
      customers: {
        component: Customers,
        field: `customer_entity_uuid_${idx}`,
        label: 'Customer',
        currentValue: this.getItem().customers[idx]?.uuid,
        disabled: !this.isNew() && HtmlHelpers.isNotEmpty(this.getItem().customers[idx]?.uuid),
        props: {
          isRequired: true,
          apiParams: { show_all: 'true', sorted: JSON.stringify([{ id: 'name', desc: false }]) },
          used: this.usedData('customers', 'customer_entity_uuid'),
        },
        labelColumns: -1,
        fieldColumns: 12,
      },
      items: {
        component: Items,
        field: `item_uuid_${idx}`,
        label: 'Item',
        currentValue: this.getItem().items[idx]?.uuid,
        disabled: !this.isNew() && HtmlHelpers.isNotEmpty(this.getItem().items[idx]?.uuid),
        props: {
          isRequired: true,
          apiParams: { show_all: 'true' },
          used: this.usedData('items', 'item_uuid'),
        },
        labelColumns: -1,
        fieldColumns: 12,
      },
    }
  );

  showCustomers = () => this.getItem().customer_specific === true || this.getItem().customer_specific === 'true';

  modalBodyRows = () => (
    <div>
      { this.getTextFieldRow('name', 'Group Name') }
      { this.getNumericFieldRow('days_in_advance', 'Days In Advance', true, { min: 1, max: 30, step: 1 }) }
      { this.getItems() }
      { this.isNew() ? this.yesNoField('customer_specific', 'Customer Specific') : '' }
      { this.showCustomers() ? this.getCustomers() : '' }
      { this.activeField() }
    </div>
  )
}));
