import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Col, Modal, ModalHeader, ModalBody, Row,
} from 'reactstrap';
import {
  AlertErrors, CrudForm, HtmlHelpers, Links, WmForm,
} from '@westernmilling/eu_react_shared';
import * as moment from 'moment';

import Page1 from 'components/Forms/NewContract/BasicInfo';
import Page2 from 'components/Forms/NewContract/Quantity';
import Page3 from 'components/Forms/NewContract/Items';
import Page4 from 'components/Forms/NewContract/SourceLocations';
import Page5 from 'components/Forms/NewContract/Pricing';
import Page6 from 'components/Forms/NewContract/Schedules';

export default withAuth(withAlert()(class Contract extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
    this.state.item.sourceLocations = [{ source_premium: '0' }];
    this.state.item.items = [{ item_premium: '0' }];
    this.state.item.schedules = [{}];
    this.state.item.plancha = 12;
    this.state.step = 1;
    this.state.updateDates = true;
  }

  getCrudType = () => 'contracts/contracts';

  getModalClassName = () => 'otto__modal-xl';

  getPageName = () => ({
    1: Page1, 2: Page2, 3: Page3, 4: Page4, 5: Page5, 6: Page6,
  });

  handleSuccess = (data) => {
    if (this.props.type === 'edit') {
      this.props.updateData([data.attributes]);
    } else {
      window.location = Links.baseUrl('contracts', `contracts/view/${data.attributes.uuid}`);
    }
  }

  continue = (event, page) => {
    if (page < this.state.step || event.currentTarget.form.checkValidity()) {
      if (page === 6) {
        this.setState((prevState) => this.setSchedules(prevState));
      } else {
        this.setState({ step: page });
      }
    } else {
      event.currentTarget.form.reportValidity();
    }
  }

  setSchedules = (prevState) => {
    let qty = parseFloat(this.getItem().quantity);
    const qtyPer = (Math.ceil((qty / this.getItem().num_schedules) * 100) / 100).toFixed(2);
    const info = { ...prevState.item };
    info.schedules = [];

    for (let i = 0; i < this.getItem().num_schedules; i += 1) {
      const newQty = qty > qtyPer ? qtyPer : qty.toFixed(2);
      info.schedules.push({
        delivery_start_date: (this.getItem().schedules[i] || {}).delivery_start_date,
        delivery_end_date: (this.getItem().schedules[i] || {}).delivery_end_date,
        quantity: (this.getItem().schedules[i] || {}).quantity || newQty,
        contract_premium: (this.getItem().schedules[i] || {}).contract_premium || this.getItem().contract_premium,
        basis: (this.getItem().schedules[i] || {}).basis || this.getItem().basis,
        contract_price: (this.getItem().schedules[i] || {}).contract_price || this.getItem().contract_price,
      });
      qty -= qtyPer;
    }
    return { item: info, step: 6 };
  }

  addSchedule = () => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.num_schedules = item.schedules.length + 1;
    item.schedules.push({});
    return { item };
  });

  removeSchedule = (idx) => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.num_schedules = item.schedules.length - 1;
    item.schedules.splice(idx, 1);
    delete item[`delivery_start_date_${idx}`];
    delete item[`delivery_end_date_${idx}`];
    delete item[`quantity_${idx}`];
    delete item[`basis_${idx}`];
    delete item[`contract_price_${idx}`];
    delete item[`contract_premium_${idx}`];
    return { item };
  });

  addSourceLocation = () => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.sourceLocations.push({});
    return { item };
  });

  removeSourceLocation = (idx) => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.sourceLocations.splice(idx, 1);
    delete item[`agris_bin_desc_${idx}`];
    delete item[`incoterm_uuid_${idx}`];
    delete item[`source_premium_${idx}`];
    delete item[`incoterm_destination_${idx}`];
    return { item };
  });

  addItem = () => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.items.push({});
    return { item };
  });

  removeItem = (idx) => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.items.splice(idx, 1);
    delete item[`item_uuid_${idx}`];
    delete item[`item_premium_${idx}`];
    return { item };
  });

  getStep = (num) => {
    const PageName = this.getPageName()[this.state.step];
    const codeUuid = this.getItem().commodityCodeUuid || '';

    return (this.state.step === num ? (
      <PageName
        auth={this.props.auth}
        data={this.state.item}
        onChange={this.onChange}
        continue={this.continue}
        addItem={this.addItem}
        removeItem={this.removeItem}
        addSchedule={this.addSchedule}
        removeSchedule={this.removeSchedule}
        addSourceLocation={this.addSourceLocation}
        removeSourceLocation={this.removeSourceLocation}
        closeModal={this.closeModal}
        showModal={this.state.step === num}
        commodityCodeUuid={codeUuid}
        disabled={this.state.disabled}
      />
    ) : '');
  }

  onChange = (event) => {
    let newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const last = name.lastIndexOf('_');
    const index = name.substring(last + 1);
    let updateDates = this.state.updateDates;

    if (name.includes('item_premium_')) {
      newData.items[index].item_premium = newData[name];
    } else if (name.includes('item_uuid_')) {
      newData.items[index].item_uuid = newData[name];
      if (name === 'item_uuid_0' && newData.commodityCodeUuid !== event.target.value?.commodityCodeUuid) {
        newData.items = [newData.items[0]];
      }
      newData.commodityCodeUuid = event.target.value?.commodityCodeUuid;
    } else if (name.includes('source_premium_')) {
      newData.sourceLocations[index].source_premium = newData[name];
    } else if (name.includes('agris_bin_desc_')) {
      newData.sourceLocations[index].agris_bin_desc = newData[name];
    } else if (name.includes('incoterm_uuid_')) {
      newData.sourceLocations[index].incoterm_uuid = newData[name];
      newData.sourceLocations[index].destinationRequired = event.target.value.item.destination_required;
    } else if (name.includes('incoterm_destination_')) {
      newData.sourceLocations[index].incoterm_destination = newData[name];
    } else if (name.includes('delivery_start_')) {
      newData.schedules[index].delivery_start_date = newData[name];
      if (updateDates && name === 'delivery_start_date_0') {
        newData = this.calculateDates(newData);
      }
      updateDates = false;
    } else if (name.includes('delivery_end_')) {
      newData.schedules[index].delivery_end_date = newData[name];
      updateDates = false;
    } else if (name.includes('quantity_')) {
      newData.schedules[index].quantity = newData[name];
    } else if (name.includes('basis_') && name.split('_').length === 2) {
      newData.schedules[index].basis = newData[name];
    } else if (name.includes('contract_price_')) {
      newData.schedules[index].contract_price = newData[name];
    } else if (name.includes('contract_premium_')) {
      newData.schedules[index].contract_premium = newData[name];
    } else if (name.includes('option_month_year_')) {
      newData.schedules[index].option_month_year = newData[name];
    } else if (name === 'unit_of_measure_uuid') {
      newData = this.getDropdownLabel(newData, event, 'flatUom');
    } else if (name === 'basis_unit_of_measure_uuid') {
      newData = this.getDropdownLabel(newData, event, 'basisUom');
    } else if (name === 'basis_currency_uuid') {
      newData = this.getDropdownLabel(newData, event, 'basisCurrency');
    } else if (name === 'currency_uuid') {
      newData = this.getDropdownLabel(newData, event, 'currency');
    } else if (name === 'contract_type') {
      if (newData[name] === 'US Basis') {
        newData.basisCurrency = 'USD';
      } else if (['Spanish Basis', 'Euro Basis'].includes(newData[name])) {
        newData.basisCurrency = 'EUR';
      } else {
        newData.basisCurrency = '';
        newData.basis_currency_uuid = '';
      }
    } else if (name === 'market_zone_name') {
      newData.market_zone_code = event.target.value.item.code;
    } else if (name === 'merchandiser_name') {
      newData.merchandiser_code = event.target.value.item.code;
    } else if (name === 'transportation_mode_name') {
      newData.transportation_mode_code = event.target.value.item.code;
    }

    this.setVariables({ item: newData, updateDates });
  }

  calculateDates = (newData) => {
    const data = { ...newData };
    const startDate = moment(data.delivery_start_date_0, 'DD/MM/YYYY');

    this.getItem().schedules.forEach((s, idx) => {
      if (idx > 0) {
        data.schedules[idx].delivery_start_date = moment(startDate.startOf('month')).format('DD/MM/YYYY');
      }
      data.schedules[idx].delivery_end_date = moment(startDate.endOf('month')).format('DD/MM/YYYY');
      startDate.add(1, 'months');
    });
    return data;
  }

  getDropdownLabel = (newData, event, field, multi = false) => {
    const data = { ...newData };
    if (HtmlHelpers.isNotEmpty(event.target.value)) {
      const info = multi ? event.target.value[0] : event.target.value;
      data[field] = info.label;
    } else {
      data[field] = '';
    }
    return data;
  }

  modalPage = () => (
    <Modal
      className={this.getModalClassName()}
      size="xl"
      isOpen={this.props.showModal}
      toggle={this.closeModal}
      backdrop="static"
    >
      <ModalHeader toggle={this.closeModal}>
        Add Contract
      </ModalHeader>
      <ModalBody>
        <WmForm submit={this.onSubmit} ref={this.formRef} id="form__crud">
          {this.getStep(this.state.step)}
          <br />
          <Row>
            <Col md="12" xs="12" className="text-center">
              <AlertErrors errors={this.state.errors} />
            </Col>
          </Row>

        </WmForm>
      </ModalBody>
    </Modal>
  )
}));
