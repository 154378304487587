import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

import ContractSourceLocations from 'components/Options/ContractSourceLocations';
import Nominations from 'components/Options/Nominations';

export default withAuth(withAlert()(class AddContractForm extends CrudForm {
  formType = () => '';

  getCrudType = () => 'contracts/truck_orders';

  buildApiVariables = () => ({
    uuid: this.getItem().uuid,
    contract_source_location_uuid: this.getItem().contract_source_location_uuid,
    nomination_uuid: this.getItem().nomination_uuid,
    add_nomination: 'Y',
  });

  headerTitle = () => 'Add to Contract';

  submitLabel = () => 'Add to Contract';

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  getModalSize = () => 'xl';

  sourceQuery = () => {
    const uuid = this.state.contractUuid;
    const bin = this.getItem().agris_bin_desc;
    return `contract_source_locations.contract_uuid = '${uuid}' and contract_source_locations.agris_bin_desc = '${bin}'`;
  };

  options = () => (
    {
      nominations: {
        component: Nominations,
        field: 'nomination_uuid',
        label: 'Nomination',
        props: { isRequired: true, truckOrderUuid: this.getItem().uuid },
      },
      contractSource: {
        component: ContractSourceLocations,
        field: 'contract_source_location_uuid',
        label: 'Source Location',
        props: { apiParams: { query: this.sourceQuery() }, isRequired: true },
      },
    }
  );

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';

    let contractUuid = this.state.contractUuid;
    if (name === 'nomination_uuid') {
      contractUuid = event.target.value.item.contract_uuid;
    }

    this.setVariables({ item: newData, contractUuid });
  }

  modalBodyRows = () => (
    <div>
      { this.getRow('bill_to_name', 'Bill To') }
      { this.getRow('item_name', 'Item') }
      { this.getRow('quantity_ordered', 'Quantity') }
      <hr />
      { this.dropdownField('nominations') }
      { HtmlHelpers.isNotEmpty(this.state.contractUuid) ? this.dropdownField('contractSource') : '' }
      <br />
    </div>
  )
}));
