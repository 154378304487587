import React from 'react';
import { ColumnButton, ColumnHelpers, HtmlHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Start Time',
    accessor: 'start_time',
    className: 'text-center',
  },
  {
    Header: 'End Time',
    accessor: 'end_time',
    className: 'text-center',
  },
  {
    Header: '# of Appointments',
    accessor: 'num_appts',
    className: 'text-center',
  },
  {
    Header: 'Effective Date',
    accessor: 'effective_date_formatted',
    className: 'text-center',
  },
  {
    Header: 'Inactive Date',
    accessor: 'end_date_formatted',
    Cell: (row) => {
      let content = '';
      if (HtmlHelpers.isNotEmpty(row.original.end_date_formatted)) {
        content = row.original.end_date_formatted;
      } else {
        content = (
          <ColumnButton onClick={() => context.handleOpenModal(row.index, 'Inactivate')} text="Inactivate" />
        );
      }
      return content;
    },
    className: 'text-center',
  },
];

export default Columns;
