import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, TableTitle } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/ContractSources';
import ItemForm from 'components/Forms/ContractSourceLocation';

export default withAuth(withAlert()(class ContractSources extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/contract_source_locations';

  getListParams = () => ({ query: `contract_source_locations.contract_uuid='${this.props.contract_uuid}'` })

  getStorageName = () => 'otto_contract_contract_sources_table_list';

  getFormTag = () => ItemForm;

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  getPageTitle = () => 'Contract Source Locations';

  getAddText = () => 'Add Contract Source Location';

  auditLogModelType = () => 'Contracts::ContractSourceLocation';

  getTableClassSettings = () => 'otto__short_table';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  additionalFormProps = () => ({
    contractUuid: this.props.contract_uuid,
    entityUuid: this.props.entity_uuid,
    purchaseSales: this.props.purchaseSales,
  });

  showAddButton = () => this.props.active && this.isAddAdmin();

  smallTitle = () => (
    <Row>
      <Col md="8" className="text-left">
        <TableTitle title={this.getPageTitle()} />
      </Col>
      <Col md="4" className="align-self-center text-right">
        { this.showAddButton() ? this.createButton() : ''}
      </Col>
    </Row>
  );
}));
