import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { CrudForm, DeleteIcon, HtmlHelpers } from '@westernmilling/eu_react_shared';

import SourceLocation from 'components/Options/SourceLocation';
import Incoterm from 'components/Options/Incoterms';

export default class SourceLocations extends CrudForm {
  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.data || {};

  usedSources = () => (
    HtmlHelpers.isEmpty(this.getItem().sourceLocations)
      ? []
      : this.getItem().sourceLocations.map((i) => i.agris_bin_desc).filter((item) => item)
  );

  options = (idx) => (
    {
      sourceLocations: {
        component: SourceLocation,
        field: `agris_bin_desc_${idx}`,
        props: { isRequired: true },
        currentValue: this.getItem().sourceLocations[idx].agris_bin_desc,
        labelColumns: -1,
        fieldColumns: 12,
      },
      incoterm: {
        component: Incoterm,
        field: `incoterm_uuid_${idx}`,
        props: { isRequired: true },
        currentValue: this.getItem().sourceLocations[idx].incoterm_uuid,
        labelColumns: -1,
        fieldColumns: 12,
      },
    }
  );

  getSourceLocationFields = () => (
    this.getItem().sourceLocations.map((row, idx) => this.getSourceLocationRow(idx, row))
  );

  deleteIcon = (idx) => (
    <DeleteIcon iconSize="1x" onClick={() => this.props.removeSourceLocation(idx)} />
  );

  destReqAny = () => {
    let show = false;
    this.getItem().sourceLocations.forEach((sl) => {
      if (sl.destinationRequired && this.getItem().purchase_sales === 'S') {
        show = true;
      }
    });
    return show;
  };

  incotermDestination = (idx, row) => {
    let display = '';
    if (row.destinationRequired && this.getItem().purchase_sales === 'S') {
      const opts = {
        fieldColumns: 12,
        labelColumns: -1,
        value: row.incoterm_destination,
      };
      display = (
        <Col md="2">
          {this.getTextFieldRow(`incoterm_destination_${idx}`, '', true, opts)}
        </Col>
      );
    } else if (this.destReqAny()) {
      display = <Col md="2" />;
    }

    return display;
  };

  incotermDestTitle = () => (
    this.destReqAny() ? (
      <Col md="2">
        <b>Incoterm Destination</b>
      </Col>
    ) : ''
  );

  getSourceLocationRow = (idx, row) => (
    <Row key={`sl_field_${idx + 1}`}>
      <Col md={this.destReqAny() ? '4' : '5'}>
        {this.dropdownField('sourceLocations', idx)}
      </Col>
      <Col md={this.destReqAny() ? '2' : '3'}>
        {this.dropdownField('incoterm', idx)}
      </Col>
      {this.incotermDestination(idx, row || {})}
      <Col md="2" className="text-center">
        { idx > 0 ? this.getQtyRow('source_premium', idx, (row || {}).source_premium) : this.getMainLabel() }
      </Col>
      <Col md="1" className="text-center" style={{ paddingTop: '8px' }}>
        {idx > 0 ? `${this.getItem().currency} (${this.getItem().flatUom})` : ''}
      </Col>
      <Col md="1">
        { idx > 0 ? this.deleteIcon(idx) : '' }
      </Col>
    </Row>
  );

  getMainLabel = () => (
    <h5 style={{ marginTop: '10px' }}>Main</h5>
  );

  getQtyRow = (field, idx, value) => (
    this.getNumericFieldRow(`${field}_${idx}`, '', true, { value, fieldColumns: 12, labelColumns: -1 })
  );

  modalBodyRows = () => (
    <div>
      <Row className="text-center">
        <Col md={this.destReqAny() ? '4' : '5'}>
          <b>Source Location</b>
        </Col>
        <Col md={this.destReqAny() ? '2' : '3'}>
          <b>IncoTerm</b>
        </Col>
        {this.incotermDestTitle()}
        <Col md="3">
          <b>Premium/ Discount</b>
        </Col>
        <Col md="1" />
      </Row>
      {this.getSourceLocationFields()}

      <Row key="addSourceLocation" className="text-right">
        <Col md="11">
          {this.getButton('Add Another Source Location', this.props.addSourceLocation)}
        </Col>
      </Row>
    </div>
  );

  submitButtons = () => (
    <div className="text-center">
      {this.getButton('Back', (event) => this.props.continue(event, 3))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Continue', (event) => this.props.continue(event, 5))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Cancel', this.closeModal)}
    </div>
  );

  modalPage = () => (
    <div>
      { this.modalBodyRows() }
      { this.showButtonsAndErrors() ? this.buttonsAndErrors() : '' }
      { this.underButtons() }
    </div>
  )
}
