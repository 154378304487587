import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudForm, WmLabel } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class ReverseExportModal extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item = props.item;
  }

  formType = () => '';

  headerTitle = () => 'Grain Ticket Reversal';

  submitLabel = () => 'Reverse Grain Ticket';

  getCrudType = () => 'contracts/truck_order_grain_tickets';

  successAlertMsg = () => 'Grain Ticket Reversed Successfully';

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    this.props.updateData();
    this.props.closeModal();
  }

  optionRow = (value, text) => (
    <Row>
      <Col md="1" />
      <Col md="1" className="text-right">
        <input
          type="radio"
          name="truck_action"
          id={`truck_action_${value}`}
          checked={this.getItem().truck_action === value}
          value={value}
          onChange={this.onChange}
        />
      </Col>
      <Col md="8">
        {text}
      </Col>
    </Row>
  );

  buildApiVariables = () => ({ uuid: this.getItem().uuid, truck_action: this.getItem().truck_action });

  modalBodyRows = () => (
    <div>
      {this.optionRow('cancel_truck', 'Reverse Grain Ticket and Cancel Truck Order')}
      <br />
      {this.optionRow('truck_shipped', 'Reverse Grain Ticket and leave status as Shipped')}
      <br />
      {this.optionRow('truck_available', 'Reverse Grain Ticket and change status to Available')}
      <br />
      <br />
    </div>
  );
}));
