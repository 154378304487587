import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, TableTitle } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TruckOrders';
import ItemForm from 'components/Forms/TruckOrder';

export default withAuth(withAlert()(class TruckOrders extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'contracts/truck_orders';

  getListParams = () => ({ show_all: true, filtered: [{ id: 'nomination_uuid', value: this.props.nomination.uuid }] });

  getStorageName = () => 'otto_truck_orders_table_list';

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  getPageTitle = () => ('Truck Orders');

  getAddText = () => 'Add Truck Order';

  auditLogModelType = () => 'Contracts::TruckOrder';

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

  additionalFormProps = () => ({ fromNomination: true, nomination: this.props.nomination });

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  updateData = () => this.props.updateData();

  showAddButton = () => (
    this.props.nomination.active && parseFloat(this.props.nomination.quantity_balance) > 0 && this.isAddAdmin()
  );

  smallTitle = () => (
    <Row>
      <Col md="8" className="text-left">
        <TableTitle title={this.getPageTitle()} />
      </Col>
      <Col md="4" className="align-self-center text-right">
        { this.showAddButton() ? this.createButton() : ''}
      </Col>
    </Row>
  );
}));
