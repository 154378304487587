import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class TruckOrderManualWeightModal extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item = props.item;
  }

  formType = () => '';

  headerTitle = () => 'Manual Weight Entry'

  submitLabel = () => 'Update Weight'

  getCrudType = () => 'contracts/truck_order_manual_weights';

  successAlertMsg = () => this.successMsg('Truck Order');

  netWeight = () => this.getItem().gross_weight - this.getItem().tare_weight;

  buildApiVariables = () => ({
    document_number: this.getItem().document_number,
    gross_weight: this.getItem().gross_weight,
    gross_at: this.getItem().gross_weight_at,
    net_weight: this.netWeight(),
    tare_weight: this.getItem().tare_weight,
    tare_at: this.getItem().tare_weight_at,
    uuid: this.getItem().uuid,
  });

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    this.props.updateData();
    this.props.closeModal();
  }

  modalBodyRows = () => (
    <div>
      { this.getTextFieldRow('document_number', 'BOL #') }
      { this.getNumericFieldRow('tare_weight', 'Tare Weight (kg)', true, { step: 1, min: 1 }) }
      { this.getDateTimeFieldRow('tare_weight_at', 'Tare Weight At') }
      { this.getNumericFieldRow('gross_weight', 'Gross Weight (kg)', true, { step: 1, min: 1 }) }
      { this.getDateTimeFieldRow('gross_weight_at', 'Gross Weight At') }
      { this.getDisplayFieldRow('net_weight', 'Net Weight (kg)', this.netWeight()) }
    </div>
  )
}));
