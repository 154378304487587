import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudList, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/LowToleranceSchedules';

export default withAuth(withAlert()(class LowToleranceScheduleListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/contract_schedules';

  getStorageName = () => 'otto_low_tolerance_schedules_list';

  getPageTitle = () => 'Low Tolerance Report';

  getEditGroups = () => ['execution_admin'];

  getListParams = () => ({ low_tolerance: 'Y' });

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  adjustSchedule = (uuid) => {
    Api.crudUpdate(this.props.auth, 'contracts/contract_schedule_deductions', { uuid })
      .then((response) => {
        this.handleResponse(response, 'Contract Schedule adjusted successfully', () => this.removeRow(uuid));
      });
  }
}));
