import { Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'ID',
    accessor: 'id',
    Cell: (row) => Links.getLink('contracts', `time_slots/view/${row.original.uuid}`, row.original.id),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Source Location',
    accessor: 'source_location',
    className: 'text-left',
  },
  {
    Header: 'Group',
    accessor: 'group_name',
    className: 'text-left',
  },
  {
    Header: 'Number of Appointments',
    columns: [
      {
        Header: 'Monday',
        accessor: 'appointment_counts.Monday',
        className: 'text-center',
        width: 100,
        filterable: false,
      },
      {
        Header: 'Tuesday',
        accessor: 'appointment_counts.Tuesday',
        className: 'text-center',
        width: 100,
        filterable: false,
      },
      {
        Header: 'Wednesday',
        accessor: 'appointment_counts.Wednesday',
        className: 'text-center',
        width: 100,
        filterable: false,
      },
      {
        Header: 'Thursday',
        accessor: 'appointment_counts.Thursday',
        className: 'text-center',
        width: 100,
        filterable: false,
      },
      {
        Header: 'Friday',
        accessor: 'appointment_counts.Friday',
        className: 'text-center',
        width: 100,
        filterable: false,
      },
      {
        Header: 'Saturday',
        accessor: 'appointment_counts.Saturday',
        className: 'text-center',
        width: 100,
        filterable: false,
      },
      {
        Header: 'Sunday',
        accessor: 'appointment_counts.Sunday',
        className: 'text-center',
        width: 100,
        filterable: false,
      },
    ],
  },
];

export default Columns;
