import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

import FinalUsages from 'components/Options/FinalUsages';
import GradeBases from 'components/Options/GradeBase';
import WeightBases from 'components/Options/WeightBase';
import MarketZones from 'components/Options/MarketZones';
import Merchandisers from 'components/Options/Merchandisers';
import PaymentMethods from 'components/Options/PaymentMethods';
import Terms from 'components/Options/Terms';
import TransportationModes from 'components/Options/TransportationModes';

export default withAuth(withAlert()(class Contract extends CrudForm {
  formType = () => 'Contract';

  getCrudType = () => 'contracts/contracts';

  options = () => (
    {
      finalUsage: {
        component: FinalUsages,
        field: 'final_usage',
        props: { isRequired: true },
        currentValue: this.getItem().final_usage,
      },
      grades: {
        component: GradeBases,
        field: 'grade_base',
        label: 'Grade Base',
      },
      weights: {
        component: WeightBases,
        field: 'weight_base',
        label: 'Weight Bases',
      },
      marketZone: {
        component: MarketZones,
        field: 'market_zone_name',
        props: { isRequired: true },
        currentValue: this.getItem().market_zone_name,
      },
      merchandiser: {
        component: Merchandisers,
        field: 'merchandiser_name',
        props: { isRequired: true },
        currentValue: this.getItem().merchandiser_name,
      },
      paymentMethods: {
        component: PaymentMethods,
        field: 'payment_method_uuid',
        props: { isRequired: true },
        currentValue: this.getItem().payment_method_uuid,
      },
      terms: {
        component: Terms,
        field: 'term_uuid',
        props: { isRequired: true },
        currentValue: this.getItem().term_uuid,
      },
      transportation: {
        component: TransportationModes,
        field: 'transportation_mode_name',
        props: { isRequired: true },
        currentValue: this.getItem().transportation_mode_name,
      },
    }
  );

  buildApiVariables = () => ({
    uuid: this.getItem().uuid,
    final_usage: this.getItem().final_usage,
    grade_base: this.getItem().grade_base,
    plancha: this.getItem().plancha,
    market_zone_name: this.getItem().market_zone_name,
    merchandiser_name: this.getItem().merchandiser_name,
    payment_method_uuid: this.getItem().payment_method_uuid,
    term_uuid: this.getItem().term_uuid,
    transportation_mode_name: this.getItem().transportation_mode_name,
    weight_base: this.getItem().weight_base,
    flag_for_review: true,
  });

  modalBodyRows = () => (
    <div>
      {this.getNumericFieldRow('plancha', 'Plancha (days)', true, { min: 1, max: 30, value: this.getItem().plancha })}
      {this.dropdownField('transportation')}
      {this.dropdownField('merchandiser')}
      {this.dropdownField('marketZone')}
      {this.dropdownField('terms')}
      {this.dropdownField('paymentMethods')}
      {this.dropdownField('weights')}
      {this.dropdownField('grades')}
      {this.getItem().hide_final_usage ? '' : this.dropdownField('finalUsage')}
      <br />
    </div>
  )
}));
