import React from 'react';
import { ColumnButton } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
  },
  {
    Header: 'Incoterm',
    accessor: 'incoterm',
    className: 'text-center',
  },
  {
    Header: 'Incoterm Destination',
    accessor: 'incoterm_destination',
    className: 'text-left',
  },
  {
    Header: 'Premium/ Discount',
    accessor: 'premium',
    className: 'text-center',
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => {
      let content = '';
      if (row.original.is_main_source) {
        content = 'Main';
      } else if (row.original.can_edit) {
        content = <ColumnButton onClick={() => context.handleOpenModal(row.index)} text="edit" />;
      }
      return content;
    },
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 100,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default Columns;
