import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, TableTitle } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/ContractItems';
import ItemForm from 'components/Forms/ContractItem';

export default withAuth(withAlert()(class ContractItems extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/contract_items';

  getListParams = () => ({ query: `contract_uuid='${this.props.contract_uuid}'` })

  getStorageName = () => 'otto_contract_contract_items_table_list';

  getFormTag = () => ItemForm;

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  getPageTitle = () => 'Contract Items';

  getAddText = () => 'Add Contract Item';

  auditLogModelType = () => 'Contracts::ContractItem';

  getTableClassSettings = () => 'otto__short_table';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  additionalFormProps = () => ({
    contractUuid: this.props.contract_uuid,
    commodityCodeUuid: this.state.data[0]?.commodity_code_uuid,
    usedItems: this.state.data.map((i) => i.item_uuid),
  });

  showAddButton = () => this.props.active && this.isAddAdmin();

  smallTitle = () => (
    <Row>
      <Col md="8" className="text-left">
        <TableTitle title={this.getPageTitle()} />
      </Col>
      <Col md="4" className="align-self-center text-right">
        { this.showAddButton() ? this.createButton() : ''}
      </Col>
    </Row>
  );
}));
