import { HtmlHelpers, LinksActionData } from '@westernmilling/eu_react_shared';

export default class ScheduleView extends LinksActionData {
  menuItems = () => {
    const uuid = this.props.uuid;
    const info = this.props.linkInfo;
    return HtmlHelpers.isEmpty(uuid) ? [] : [
      this.cancelInfo(info.cancel),
      this.editInfo(info.edit),
      this.updatePriceInfo(info.update_price),
    ];
  }

  cancelInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('Cancel'));

  editInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal());

  updatePriceInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('Price'));
}
