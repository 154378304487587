import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import SourceLocations from 'components/Options/SourceLocation';
import Incoterms from 'components/Options/Incoterms';

export default withAuth(withAlert()(class ContractSourceLocation extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.contract_uuid = props.additionalFormProps.contractUuid;
    this.state.item.destReq = HtmlHelpers.isNotEmpty(this.state.item.incoterm_destination);
  }

  formType = () => 'Contract Source Location';

  getCrudType = () => 'contracts/contract_source_locations';

  successAlertMsg = () => this.successMsg('Contract Source Location');

  options = () => (
    {
      locs: {
        component: SourceLocations,
        field: 'agris_bin_desc',
        props: { isRequired: true },
        currentValue: this.getItem().item_uuid,
        disabled: !this.isNew(),
      },
      incoterm: {
        component: Incoterms,
        field: 'incoterm_uuid',
        props: { isRequired: true },
      },
    }
  );

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    if (name.includes('incoterm_uuid')) {
      newData.destReq = event.target.value.item.destination_required;
      newData.incoterm_destination = '';
    }
    this.setVariables({ item: newData });
  }

  showDestination = () => (
    this.getItem().destReq && this.props.additionalFormProps.purchaseSales === 'S'
  );

  modalBodyRows = () => (
    <div>
      {this.props.additionalFormProps.entityUuid ? this.dropdownField('locs') : ''}
      {this.dropdownField('incoterm')}
      {this.showDestination() ? this.getTextFieldRow('incoterm_destination', 'Incoterm Destination') : ''}
      {this.getNumericFieldRow('premium', 'Premium / Discount')}
    </div>
  )
}));
