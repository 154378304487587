import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class SignedModal extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item = props.item;
  }

  formType = () => 'Signed Date';

  getCrudType = () => 'contracts/contracts';

  successAlertMsg = () => this.successMsg(this.formType());

  buildApiVariables = () => ({ signed: true, signed_date: this.getItem().signed_date, uuid: this.getItem().uuid });

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    this.props.updateData();
    this.props.closeModal();
  }

  modalBodyRows = () => (
    <div>
      { this.getDateFieldRow('signed_date', 'Signed Date') }
    </div>
  )
}));
