import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class NoteCategories extends OptionsBaseApi {
  getApiName = () => 'entities/entity_note_categories';

  getApiParams = () => ({ order: 'name', query: 'active = true' });

  getDropdownLabelText = () => 'Note Category';

  getDropdownFieldName = () => this.props.fieldName || 'category';

  getValueFieldName = () => 'name';
}
