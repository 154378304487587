import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/StorageUnits';
import ItemForm from 'components/Forms/StorageUnit';

export default withAuth(class StorageUnitListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/storage_units';

  getStorageName = () => 'otto_contracts_storage_units_list';

  getPageTitle = () => 'Storage Units';

  getFormTag = () => ItemForm;

  getAddGroups = () => ['qa_team'];

  getEditGroups = () => ['qa_team'];

  getAddText = () => 'Create Storage Unit';

  auditLogModelType = () => 'Contracts::StorageUnit';

  getWidthCenter = () => 8;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;

  canDownloadCsv = () => true;
});
