import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Contracts';
import ItemForm from 'components/Forms/NewContract';

export default withAuth(class ContractListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/contracts';

  getStorageName = () => 'otto_contracts_contract_list';

  getPageTitle = () => 'Contracts';

  getFormTag = () => ItemForm;

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  getAddText = () => 'Create Contract';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  canDownloadCsv = () => true;
});
