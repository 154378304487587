import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

import GradeBases from 'components/Options/GradeBase';
import WeightBases from 'components/Options/WeightBase';

export default withAuth(withAlert()(class Schedule extends CrudForm {
  constructor(props) {
    super(props);
    this.state.originalQuantity = this.state.item.quantity;
  }

  formType = () => 'Schedule';

  getCrudType = () => 'contracts/contract_schedules';

  buildApiVariables = () => ({ uuid: this.getItem().uuid, quantity: this.getItem().quantity });

  amtUsed = () => (parseFloat(this.state.originalQuantity) - parseFloat(this.getItem().balance));

  modalBodyRows = () => (
    <div>
      { this.getTextRow('Counterparty', this.getItem().counterparty_name) }
      { this.getTextRow('Schedule Delivery Start', this.getItem().delivery_start_formatted) }
      { this.getTextRow('Schedule Delivery End', this.getItem().delivery_end_formatted) }
      { this.getTextRow('Amount Used', `${this.amtUsed()} ${this.getItem().unit_of_measure_symbol}`) }
      <hr />
      { this.getNumericFieldRow('quantity', 'Schedule Quantity') }
      <br />
    </div>
  )
}));
