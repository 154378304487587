import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'ID',
    accessor: 'id',
    Cell: (row) => (
      Links.getLink(
        'contracts',
        `${HtmlHelpers.isEmpty(row.original.parent_uuid) ? 'nominations' : 'endorsements'}/view/${row.original.uuid}`,
        row.original.id,
      )
    ),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Delivery Start',
    accessor: 'delivery_start_date',
    className: 'text-center',
  },
  {
    Header: 'Delivery End',
    accessor: 'delivery_end_date',
    className: 'text-center',
  },
  {
    id: 'endorsee__name',
    Header: 'Endorsee',
    accessor: 'endorsee_name',
    className: 'text-left',
  },
  {
    Header: 'Endorser',
    accessor: 'parent_name',
    className: 'text-left',
  },
  {
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    className: 'text-right',
  },
  {
    id: 'quantity_balance',
    Header: 'Balance',
    accessor: 'balance',
    className: 'text-right',
  },
  {
    Header: 'Shipped',
    accessor: 'quantity_shipped',
    className: 'text-right',
  },
  {
    id: 'quantity_unshipped',
    Header: 'Unshipped',
    accessor: 'unshipped',
    className: 'text-right',
  },
  {
    id: 'unit_of_measure__symbol',
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-center',
    width: 80,
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => (
      <div>
        {row.original.active ? (
          <>
            <ColumnButton
              className="otto__margin_btm_10"
              onClick={() => context.handleOpenModal(
                row.index,
                (HtmlHelpers.isEmpty(row.original.parent_uuid) ? 'EditNomination' : 'EditEndorsement'),
                { nomination: row.original },
              )}
              text="edit"
            />
            <br />
          </>
        ) : ''}
        {row.original.active && parseFloat(row.original.quantity_balance) > 0 ? (
          <ColumnButton
            onClick={() => context.handleOpenModal(-1, 'Endorsement', { nomination: row.original })}
            text="Add Endorsement"
          />
        ) : ''}
      </div>
    ),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 150,
  },
];

export default Columns;
