import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context, url, isPurchase = false) => [
  {
    Header: 'Order ID',
    accessor: 'order_number',
    Cell: (row) => (
      Links.getLink('contracts', `direct_orders/view/${row.original.uuid}`, row.original.order_number)
    ),
    className: 'text-center',
    width: 100,
  },
  {
    id: 'purchase_nomination__id',
    Header: 'Purchase Nomination',
    accessor: 'purchase_nomination_id',
    Cell: (row) => (
      Links.getLink(
        'contracts',
        `nominations/view/${row.original.purchase_nomination_uuid}`,
        row.original.purchase_nomination_id,
      )
    ),
    className: 'text-center',
    width: 150,
  },
  {
    id: 'bill_to_entity__name',
    Header: 'Purchase Contract',
    accessor: 'purchase_contract_name',
    className: 'text-left',
  },
  {
    id: 'sales_nomination__id',
    Header: 'Sales Nomination',
    accessor: 'sales_nomination_id',
    Cell: (row) => (
      Links.getLink(
        'contracts',
        `nominations/view/${row.original.sales_nomination_uuid}`,
        row.original.sales_nomination_id,
      )
    ),
    className: 'text-center',
    width: 130,
  },
  {
    id: 'bill_to_entity_0__name',
    Header: 'Sales Contract',
    accessor: 'sales_contract_name',
    className: 'text-left',
  },
  {
    Header: 'Customer PO',
    accessor: 'customer_po',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    Header: 'Origin WT (KG)',
    accessor: 'origin_weight',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Destination WT (KG)',
    accessor: 'destination_weight',
    className: 'text-center',
    width: 140,
  },
  {
    id: 'shipped_date',
    Header: 'Shipped Date',
    accessor: 'shipped_date_formatted',
    className: 'text-center',
    width: 120,
  },
  {
    id: 'none_vessel_name_truck_plate',
    Header: 'Vessel Name/Truck Plate',
    accessor: 'vessel_name_truck_plate',
    className: 'text-center',
    width: 160,
  },
  {
    Header: 'Final Usage',
    accessor: 'final_usage',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    width: 100,
  },
];

export default Columns;
