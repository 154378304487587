import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Alert } from 'reactstrap';
import { Api, CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import BatchNumbers from 'components/Options/BatchNumbers';
import StorageUnits from 'components/Options/StorageUnits';

export default withAuth(withAlert()(class LoadersForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.disabled = HtmlHelpers.isEmpty(this.getItem().tare_weight);
  }

  componentDidMount() {
    if (HtmlHelpers.isEmpty(this.getItem().tare_weight)) {
      this.getUniwinWeights();
    }
  }

  formType = () => '';

  headerTitle = () => `Add Storage Unit - Truck Order ${this.getItem().id}`;

  submitLabel = () => 'Add Storage Unit';

  getCrudType = () => 'contracts/truck_orders';

  successAlertMsg = () => this.successMsg('Truck Order');

  buildApiVariables = () => ({ ...this.getItem(), loader_fields: 'Y' });

  getUniwinWeights = () => {
    this.setState({ loading: true });
    Api.crudView(this.props.auth, 'uniwin/uniwin_move', this.getItem().id)
      .then((response) => {
        if (response.data !== undefined) {
          const disabled = HtmlHelpers.isEmpty(response.data?.attributes.tare_weight);
          this.setState({
            tareWeight: response.data?.attributes.tare_weight,
            disabled,
            loading: false,
          });
        }
      });
  }

  options = () => (
    {
      batch: {
        component: BatchNumbers,
        field: 'batch_number',
        props: { isRequired: true, itemUuid: this.getItem().item_uuid, agrisBin: this.getItem().agris_bin_desc },
      },
      storageUnit: {
        component: StorageUnits,
        field: 'storage_unit_uuid',
        props: { isRequired: true },
      },
    }
  );

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    this.props.closeModal();
    this.props.updateData(data);
  }

  tareWeightAlert = () => (
    HtmlHelpers.isEmpty(this.getItem().tare_weight) && HtmlHelpers.isEmpty(this.state.tareWeight) ? (
      <Alert color="danger" className="text-center">
        Tare Weight is needed to add storage unit
      </Alert>
    ) : ''
  );

  modalBodyRows = () => (
    <div>
      {this.dropdownField('batch')}
      {this.dropdownField('storageUnit')}
      {this.getTextFieldRow('seals', 'Seal', false)}
      {this.tareWeightAlert()}
    </div>
  );
}));
