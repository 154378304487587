import React from 'react';
import { ColumnHelpers, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    id: 'contract__contract_id',
    Header: 'Contract ID',
    accessor: 'contract_id',
    Cell: (row) => Links.getLink('contracts', `contracts/view/${row.original.contract_uuid}`, row.original.contract_id),
    className: 'text-left',
    width: 100,
  },
  {
    Header: 'Nomination ID',
    accessor: 'id',
    Cell: (row) => (
      Links.getLink(
        'contracts',
        `${HtmlHelpers.isEmpty(row.original.parent_uuid) ? 'nominations' : 'endorsements'}/view/${row.original.uuid}`,
        row.original.id,
      )
    ),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Delivery Start',
    accessor: 'delivery_start_date',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Delivery End',
    accessor: 'delivery_end_date',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'none_parent_bill_name',
    Header: 'Endorser',
    accessor: 'parent_name',
    className: 'text-left',
  },
  {
    id: 'none_endorsee_bill_name',
    Header: 'Endorsee',
    accessor: 'endorsee_name',
    className: 'text-left',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    className: 'text-right',
    width: 120,
  },
  {
    Header: 'Shipped',
    accessor: 'quantity_shipped',
    className: 'text-right',
    width: 120,
  },
  {
    Header: 'Unshipped',
    accessor: 'quantity_unshipped',
    className: 'text-right',
    width: 120,
  },
  {
    id: 'quantity_balance',
    Header: 'Balance',
    accessor: 'balance',
    className: 'text-right',
    width: 120,
  },
  {
    id: 'unit_of_measure__symbol',
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-center',
    width: 80,
  },
  {
    id: 'contract_source_location__agris_bin_desc',
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
    width: 225,
  },
];

export default Columns;
