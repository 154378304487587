import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Order ID',
    accessor: 'id',
    Cell: (row) => Links.getLink('contracts', `transfer_truck_orders/view/${row.original.uuid}`, row.original.id, true),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Received Date',
    accessor: 'fecha',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'BOL #',
    accessor: 'su_alb',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Truck Plate',
    accessor: 'matricula',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Updated in Otto',
    accessor: 'uploaded',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Message',
    accessor: 'msg',
    className: 'text-left',
  },
];

export default Columns;
