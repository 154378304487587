import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Customers from 'components/Options/Customers';
import ContractItems from 'components/Options/ContractItems';
import ContractSourceLocations from 'components/Options/ContractSourceLocations';
import Items from 'components/Options/Items';
import SourceLocation from 'components/Options/SourceLocation';
import UnitOfMeasure from 'components/Options/UnitOfMeasure';
import Carriers from 'components/Options/Carriers';
import CarrierAgencies from 'components/Options/CarrierAgencies';
import FinalUsages from 'components/Options/FinalUsages';

import ShipToField from 'components/Forms/ShipToField';

export default withAuth(withAlert()(class TruckOrderForm extends CrudForm {
  constructor(props) {
    super(props);
    if (this.nomination()) {
      this.state.item.nomination_uuid = this.nomination().uuid;
      this.state.item.agris_bin_desc = this.nomination().agris_bin_desc;
      this.state.item.contract_source_location_uuid = this.nomination().contract_source_location_uuid;
      this.state.item.bill_to_entity_uuid = this.nomination().bill_to_entity_uuid;
      this.state.item.item_uuid = this.nomination().item_uuid;
      this.state.item.unit_of_measure_uuid = this.nomination().unit_of_measure_uuid;
      this.state.item.customer_entity_uuid = this.shipToEntityUuid();
      this.state.item.endorsee_entity_uuid = this.nomination().endorsee_entity_uuid;
      this.state.item.ship_to_location_uuid = this.nomination().ship_to_location_uuid;
      this.state.item.contract_uuid = this.nomination().contract_uuid;
    }
    this.state.formKey = 1;
  }

  formType = () => 'Truck Order(s)';

  getCrudType = () => 'contracts/truck_orders';

  successAlertMsg = () => this.successMsg('Truck Order(s)');

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  getModalSize = () => 'xl';

  options = () => (
    {
      carrierAgency: {
        component: CarrierAgencies,
        field: 'carrier_agency_entity_uuid',
      },
      contractSource: {
        component: ContractSourceLocations,
        field: 'contract_source_location_uuid',
        label: 'Source Location',
        props: {
          apiParams: { query: `contract_source_locations.contract_uuid = '${this.getItem().contract_uuid}'` },
          isRequired: true,
        },
      },
      customer: {
        component: Customers,
        field: 'customer_entity_uuid',
        label: 'Counterparty',
        props: { isRequired: true },
      },
      finalUsage: {
        component: FinalUsages,
        field: 'final_usage',
        props: { isRequired: true },
        currentValue: this.getItem().final_usage,
      },
      item: {
        component: Items,
        field: 'item_uuid',
        props: {
          isRequired: true,
          apiParams: {
            filtered: JSON.stringify([{ id: 'active', value: 'yes' }]),
            show_all: 'true',
            order: 'reference',
          },
        },
      },
      contractItems: {
        component: ContractItems,
        field: 'item_uuid',
        label: 'Item',
        props: {
          valueField: 'item_uuid',
          apiParams: {
            query: `contract_uuid = '${this.nomination().contract_uuid}'`,
            order: 'reference',
          },
        },
      },
      source: {
        component: SourceLocation,
        field: 'agris_bin_desc',
        props: { isRequired: true },
      },
      uom: {
        component: UnitOfMeasure,
        field: 'unit_of_measure_uuid',
        props: {
          isRequired: true,
          apiParams: ({ filtered: JSON.stringify([{ id: 'active', value: 'yes' }]), show_all: 'true' }),
        },
      },
      vendors: {
        component: Carriers,
        field: 'carrier_entity_uuid',
      },
    }
  );

  nomination = () => this.props.additionalFormProps.nomination || {};

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;

    if (HtmlHelpers.isNotEmpty(newData.quantity_ordered) && HtmlHelpers.isNotEmpty(newData.truck_can_carry)) {
      newData.truck_num = Math.round(newData.quantity_ordered / newData.truck_can_carry);
    } else {
      newData.truck_num = null;
    }

    let formKey = this.state.formKey;
    if (name === 'customer_entity_uuid') {
      formKey += 1;
    }

    if (name === 'contract_source_location_uuid') {
      newData.agris_bin_desc = event.target.value.item.agris_bin_desc;
    }

    newData.bill_to_entity_uuid ||= this.getItem().customer_entity_uuid;
    this.setVariables({ item: newData, formKey });
  }

  nominationText = () => (HtmlHelpers.isEmpty(this.nomination().parent_uuid) ? 'Nomination' : 'Endorsement');

  deliveryStart = () => (
    this.getTextRow(
      `${this.nominationText()} Start`,
      this.nomination().delivery_start_date,
    )
  );

  deliveryEnd = () => (
    this.getTextRow(
      `${this.nominationText()} End`,
      this.nomination().delivery_end_date,
    )
  );

  nominationBalance = () => (
    this.getTextRow(
      `${this.nominationText()} Balance`,
      `${this.nomination().quantity_balance} ${this.nomination().unit_of_measure_symbol}`,
    )
  );

  topSection = () => (
    this.props.additionalFormProps.fromNomination ? (
      <div>
        {this.getTextRow('Bill To', this.nomination().bill_to_name)}
        {this.getTextRow('Source Location', this.nomination().agris_bin_desc)}
        {this.deliveryStart()}
        {this.deliveryEnd()}
        {this.nominationBalance()}
        <hr />
        {this.dropdownField('contractSource')}
        {this.dropdownField('contractItems')}
      </div>
    ) : (
      <div>
        {this.dropdownField('customer')}
        {this.dropdownField('source')}
        {this.dropdownField('item')}
        {this.dropdownField('uom')}
        {this.dropdownField('finalUsage')}
      </div>
    )
  );

  displayTruckNum = () => (
    HtmlHelpers.isEmpty(this.getItem().truck_num) ? '' : (
      `${this.getItem().truck_num}`
    )
  );

  shipToEntityUuid = () => {
    let uuid = '';
    if (this.props.additionalFormProps.fromNomination) {
      uuid = HtmlHelpers.isEmpty(this.nomination().parent_uuid)
        ? this.nomination().bill_to_entity_uuid
        : this.nomination().endorsee_entity_uuid;
    } else {
      uuid = this.getItem().customer_entity_uuid;
    }
    return uuid;
  }

  modalBodyRows = () => (
    <div>
      {this.topSection()}
      {this.getNumericFieldRow('quantity_ordered', 'Quantity')}
      {this.getNumericFieldRow('truck_can_carry', 'Truck Can Carry')}
      {this.getDisplayFieldRow('truck_num', '# of Trucks', this.displayTruckNum())}
      {this.getTextFieldRow('po_number', 'Customer PO', false)}
      {this.dropdownField('carrierAgency')}
      {this.dropdownField('vendors')}
      {this.getDateFieldRow('pickup_date', 'Pickup Date', false)}
      <ShipToField
        key={this.state.formKey}
        auth={this.props.auth}
        entityUuid={this.shipToEntityUuid()}
        item={this.getItem()}
        onChange={this.onChange}
      />
    </div>
  )
}));
