import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Customers extends OptionsBaseApi {
  getApiName = () => 'entities/customers';

  getApiParams = () => ({ filtered: JSON.stringify([{ id: 'active', value: true }]), show_all: 'true' });

  getDropdownLabelText = () => this.props.fieldLabel || 'Customer';

  getDropdownFieldName = () => this.props.fieldName || 'customer_entity_uuid';

  getValueFieldName = () => this.props.valueField || 'entity_uuid';
}
