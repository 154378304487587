import { Api, HtmlHelpers, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class SourceLocation extends OptionsBaseApi {
  getApiName = () => 'agris/source_locations';

  getApiParams = () => this.props.apiParams || ({ active: 'Y' });

  getDropdownLabelText = () => this.props.fieldLabel || 'Source Location';

  getDropdownFieldName = () => this.props.fieldName || 'agris_bin_desc';

  getValueFieldName = () => 'name';
}
