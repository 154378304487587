import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  AddIcon, Api, CrudList, HtmlHelpers,
} from '@westernmilling/eu_react_shared';
import { Col, Row } from 'reactstrap';

import UpdateTablesListContainer from 'containers/UpdateTablesListContainer';
import Columns from 'helpers/columns/AgrisMaintenance';

export default withAuth(withAlert()(class MarketZoneListContainer extends UpdateTablesListContainer {
  getColumns = () => Columns(this);

  getListApi = () => 'agris/market_zones';

  getStorageName = () => 'otto_contracts_market_zone_list';

  getPageTitle = () => 'Market Zones';
}));
