import { HtmlHelpers, LinksActionData } from '@westernmilling/eu_react_shared';

export default class VesselOrderView extends LinksActionData {
  menuItems = () => {
    const uuid = this.props.uuid;
    const info = this.props.linkInfo;
    return HtmlHelpers.isEmpty(uuid) ? [] : [
      this.destinationWeightInfo(info.destination_weight),
      this.editInfo(info.edit),
      this.createExportInfo(info.create_export),
      this.reverseExportInfo(info.reverse_export),
    ];
  }

  createExportInfo = (info) => this.buildInfo(info, info.label, () => this.props.createExport());

  destinationWeightInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('DestinationWeight'));

  editInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal());

  reverseExportInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('ReverseExport'));
}
