import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudForm, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

import DirectOrders from 'components/Tables/DirectOrders';
import Endorsements from 'components/Tables/Nominations';
import EndorseeTruckOrders from 'components/Tables/EndorseeTruckOrders';
import NominationNotes from 'components/Tables/NominationNotes';
import TruckOrders from 'components/Tables/TruckOrders';
import VesselOrders from 'components/Tables/VesselOrders';

export default withAuth(withAlert()(class NominationViewForm extends CrudForm {
  formType = () => 'Item';

  getCrudType = () => 'contracts/nominations';

  scheduleLink = () => Links.getLink(
    'contracts',
    `schedules/view/${this.getItem().contract_schedule_uuid}`,
    `${this.getItem().contract_id} - ${this.getItem().contract_schedule_number}`,
  );

  endorserLink = () => Links.getLink(
    'contracts',
    `nominations/view/${this.getItem().parent_uuid}`,
    this.getItem().parent_name,
  );

  col1 = () => (
    <Col md={4} sm={12}>
      {this.getRow('id', 'Nomination ID')}
      {this.getRowWithValue('Contract Schedule', this.scheduleLink())}
      {this.getRow('agris_bin_desc', 'Source')}
      {HtmlHelpers.isEmpty(this.getItem().parent_uuid)
        ? this.getRow('counterparty_name', 'Counterparty')
        : this.getRowWithValue('Endorser', this.endorserLink())}
      {HtmlHelpers.isEmpty(this.getItem().parent_uuid)
        ? this.getRow('bill_to_name', 'Bill To')
        : this.getRow('endorsee_name', 'Endorsee')}
    </Col>
  )

  col2 = () => (
    <Col md={4} sm={12}>
      {this.getRow('quantity', 'Quantity')}
      {this.getRow('balance', 'Balance')}
      {this.getRow('quantity_shipped', 'Shipped')}
      {this.getRow('unshipped', 'Unshipped')}
      {this.getRow('unit_of_measure_symbol', 'UOM')}
    </Col>
  )

  col3 = () => (
    <Col md={4} sm={12}>
      {this.getRow('item_name', 'Item')}
      {this.getRow('delivery_start_date', 'Delivery Start')}
      {this.getRow('delivery_end_date', 'Delivery End')}
      {this.getRow('active_text', 'Active')}
    </Col>
  )

  endorsementDisplay = () => (
    this.getItem().transportation_mode === 'T' ? (
      <div>
        <hr />
        <Endorsements nomination={this.getItem()} pageType="endorsement" updateData={this.props.updateData} />
        <br />
      </div>
    ) : ''
  );

  truckOrders = () => (
    this.getItem().transportation_mode === 'T' ? (
      <div>
        <hr />
        <TruckOrders nomination={this.getItem()} updateData={this.props.updateData} />
        <br />
        <hr />
        <EndorseeTruckOrders nomination={this.getItem()} />
      </div>
    ) : ''
  )

  vesselOrders = () => (
    this.getItem().transportation_mode === 'V' ? (
      <div>
        <hr />
        <VesselOrders nomination={this.getItem()} updateData={this.props.updateData} />
        <br />
      </div>
    ) : ''
  );

  directOrders = () => (
    <div>
      <hr />
      <DirectOrders nomination={this.getItem()} updateData={this.props.updateData} />
      <br />
    </div>
  );

  modalBodyRows = () => (
    <div>
      <Row>
        {this.col1()}
        {this.col2()}
        {this.col3()}
      </Row>
      <br />
      {this.endorsementDisplay()}
      {this.truckOrders()}
      {this.directOrders()}
      {this.vesselOrders()}
      <NominationNotes auth={this.props.auth} uuid={this.getItem().uuid} />
    </div>
  )
}));
