import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudList, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TruckOrdersOnHold';

export default withAuth(withAlert()(class TruckOrdersOnHoldListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/truck_orders';

  getListParams = () => ({ filtered: [{ id: 'status', value: 'Credit Hold' }] });

  getEditGroups = () => ['credit_admin'];

  getStorageName = () => 'otto_truck_orders_on_hold_list';

  getPageTitle = () => 'Truck Orders - Credit Status (Hold)';

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  removeCreditHold = (uuid) => {
    Api.crudUpdate(this.props.auth, 'contracts/truck_order_release_holds', { uuid })
      .then((response) => {
        this.handleResponse(response, 'Truck Order updated successfully', () => this.removeRow(uuid));
      });
  }
}));
