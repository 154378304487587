import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { CrudForm, DeleteIcon, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Items from 'components/Options/Items';

export default class SourceLocations extends CrudForm {
  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.data || {};

  usedItems = () => (
    HtmlHelpers.isEmpty(this.getItem().items)
      ? []
      : this.getItem().items.map((i) => i.item_uuid).filter((item) => item)
  );

  options = (idx) => {
    const uuid = idx === 0 ? null : this.props.commodityCodeUuid;
    return (
      {
        items: {
          component: Items,
          field: `item_uuid_${idx}`,
          props: {
            isRequired: true,
            used: this.usedItems(),
            apiParams: ({
              filtered: JSON.stringify([{ id: 'commodity_code_uuid', value: uuid }, { id: 'active', value: 'yes' }]),
              show_all: 'true',
            }),
          },
          currentValue: this.getItem().items[idx].item_uuid,
          labelColumns: -1,
          fieldColumns: 12,
        },
      }
    );
  }

  getItemFields = () => (
    this.getItem().items.map((row, idx) => this.getItemRow(idx, row))
  );

  deleteIcon = (idx) => (
    <DeleteIcon iconSize="1x" onClick={() => this.props.removeItem(idx)} />
  );

  getItemRow = (idx, row) => (
    <Row key={`sl_field_${idx + 1}`}>
      <Col md="6">
        {this.dropdownField('items', idx)}
      </Col>
      <Col md="3" className="text-center">
        { idx > 0 ? this.getQtyRow('item_premium', idx, (row || {}).item_premium) : this.getMainLabel() }
      </Col>
      <Col md="1" className="text-center" style={{ paddingTop: '8px' }}>
        {idx > 0 ? `${this.getItem().currency} (${this.getItem().flatUom})` : ''}
      </Col>
      <Col md="1">
        { idx > 0 ? this.deleteIcon(idx) : '' }
      </Col>
    </Row>
  );

  getMainLabel = () => (
    <h5 style={{ marginTop: '10px' }}>Main</h5>
  );

  getQtyRow = (field, idx, value) => (
    this.getNumericFieldRow(`${field}_${idx}`, '', true, { value, fieldColumns: 12, labelColumns: -1 })
  );

  modalBodyRows = () => (
    <div>
      <Row className="text-center">
        <Col md="6">
          <b>Item</b>
        </Col>
        <Col md="3">
          <b>Premium/ Discount</b>
        </Col>
        <Col md="1" />
      </Row>
      {this.getItemFields()}

      <Row key="addItem" className="text-right">
        <Col md="11">
          {this.getButton('Add Another Item', this.props.addItem)}
        </Col>
      </Row>
    </div>
  );

  submitButtons = () => (
    <div className="text-center">
      {this.getButton('Back', (event) => this.props.continue(event, 2))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Continue', (event) => this.props.continue(event, 4))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Cancel', this.closeModal)}
    </div>
  );

  modalPage = () => (
    <div>
      { this.modalBodyRows() }
      { this.showButtonsAndErrors() ? this.buttonsAndErrors() : '' }
      { this.underButtons() }
    </div>
  )
}
