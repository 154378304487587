import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
  Api, CrudForm, DeleteIcon, HtmlHelpers,
} from '@westernmilling/eu_react_shared';
import * as moment from 'moment';

import OptionYears from 'components/Options/OptionYears';
import OptionMonthsYears from 'components/Options/OptionMonthsYears';

export default class Schedules extends CrudForm {
  componentDidMount = async () => {
    if (HtmlHelpers.isNotEmpty(this.props.data.commodity_board_uuid)) {
      await this.getOptionMonthYears();
    }
  }

  getOptionMonthYears = async () => {
    let monthYearOptions = [];
    const path = 'items/commodity_code_commodity_boards?months=Y';

    const params = {
      board_uuid: this.props.data.commodity_board_uuid,
      commodity_uuid: this.props.data.commodityCodeUuid,
      contract_date: this.props.data.contract_date,
    };

    await Api.crudList(this.props.auth, path, params).then((response) => {
      if (response.data !== undefined) {
        monthYearOptions = response.data.attributes;
      }
    });

    if (HtmlHelpers.isEmpty(monthYearOptions) && ['NPE', 'Flat'].includes(this.props.data.contract_type)) {
      this.props.data.commodity_board_uuid = '';
    }

    this.setState({ monthYearOptions });
  };

  getButton = (type, onClick = undefined) => (
    <Button
      outline
      className="btn-sm"
      color="info"
      onClick={onClick}
      disabled={type === 'Cancel' ? false : this.props.disabled}
    >
      {type}
    </Button>
  )

  onChange = (event) => this.props.onChange(event);

  getItem = () => this.props.data || {};

  getScheduleFields = () => (
    this.getItem().schedules.map((row, idx) => this.getScheduleRow(idx, row))
  );

  deleteIcon = (idx) => (
    this.getItem().num_schedules > 1 ? (
      <td aria-label="DeleteTD">
        <DeleteIcon iconSize="1x" onClick={() => this.props.removeSchedule(idx)} />
      </td>
    ) : ''
  );

  isBasis = () => !['NPE', 'Flat'].includes(this.getItem().contract_type);

  isPremium = () => this.getItem().contract_type === 'Premium Over Premium';

  options = (idx) => (
    {
      optionMonthYears: {
        component: OptionMonthsYears,
        field: `option_month_year_${idx}`,
        currentValue: this.getItem().schedules[idx].option_month_year,
        data: this.state.monthYearOptions || [],
        props: { isRequired: true },
        labelColumns: 1,
        fieldColumns: 12,
      },
    }
  );

  getScheduleRow = (idx, row) => (
    <tr key={`s_field_${idx + 1}`}>
      <td>
        {this.getDateRow('delivery_start_date', idx, row.delivery_start_date)}
      </td>
      <td>
        {this.getDateRow('delivery_end_date', idx, row.delivery_end_date)}
      </td>
      <td>
        {this.getQtyRow('quantity', idx, row.quantity)}
      </td>
      <td>
        {this.getPremiumField(idx, row)}
      </td>
      <td>
        {this.getPriceFields(idx, row)}
      </td>
      <td>
        {this.getUomCurrencyText().both}
      </td>
      <td>
        { this.isBasis() ? this.dropdownField('optionMonthYears', idx) : '' }
      </td>
      {this.deleteIcon(idx)}
    </tr>
  )

  getPremiumField = (idx, row) => (this.isPremium()
    ? this.getQtyRow('contract_premium', idx, row.contract_premium)
    : '');

  getPriceFields = (idx, row) => {
    let content;
    if (this.getItem().contract_type === 'NPE') {
      content = '';
    } else if (this.getItem().contract_type === 'Flat') {
      content = this.getQtyRow('contract_price', idx, row.contract_price);
    } else {
      content = this.getQtyRow('basis', idx, row.basis, !this.isPremium());
    }
    return content;
  }

  getUomCurrencyText = (idx, row) => {
    let content = { uom: '', currency: '', both: '' };
    if (this.getItem().contract_type === 'NPE') {
      content = '';
    } else if (this.getItem().contract_type === 'Flat') {
      content.both = `${this.getItem().flatUom} (${this.getItem().currency})`;
      content.currency = this.getItem().currency;
      content.uom = this.getItem().flatUom;
    } else {
      content.both = `${this.getItem().basisUom} (${this.getItem().basisCurrency})`;
      content.currency = this.getItem().basisCurrency;
      content.uom = this.getItem().basisUom;
    }
    return content;
  }

  getDateRow = (field, idx, value) => (
    this.getDateFieldRow(`${field}_${idx}`, '', true, { value, fieldColumns: 11, labelColumns: 0 })
  )

  getQtyRow = (field, idx, value, required = true) => (
    this.getNumericFieldRow(`${field}_${idx}`, '', required, { value, fieldColumns: 11, labelColumns: 0 })
  )

  getHeaderText = () => {
    let content = '';
    if (this.getItem().contract_type === 'Flat') {
      content = 'Contract Price';
    } else if (this.isBasis()) {
      content = 'Basis';
    }
    return content;
  }

  getScheduleQuantity = () => this.getItem().schedules.map((s) => parseFloat(s.quantity)).reduce((a, c) => (a + c), 0);

  getAvg = () => {
    if (this.getItem().contract_type === 'Flat' || this.isBasis()) {
      const qty = this.getScheduleQuantity();
      const basis = this.getItem().schedules.map((s) => (
        parseFloat(s.quantity) * parseFloat(s.basis || s.contract_price)
      )).reduce((a, c) => (a + c), 0);
      return (basis / qty).toFixed(4);
    }
    return '';
  }

  modalBodyRows = () => (
    <div>
      <br />
      <table width="100%" className="align-center">
        <thead>
          <tr>
            <th>Delivery Start</th>
            <th>Delivery End</th>
            <th>Quantity</th>
            <th>
              { this.isPremium() ? 'Premium' : ' ' }
            </th>
            <th>{this.getHeaderText()}</th>
            <th aria-label="scheduleUOM" />
            <th>
              { this.isBasis() ? 'Option Month/ Year' : ' ' }
            </th>
          </tr>
        </thead>
        <tbody>
          {this.getScheduleFields()}
        </tbody>
      </table>
      <Row key="addSchedule" className="text-right">
        <Col md="11">
          {this.getButton('Add Another Schedule', this.props.addSchedule)}
        </Col>
      </Row>
      <hr />
      <table width="100%" className="align-center">
        <tbody>
          <tr>
            <td className="text-right">
              <b>Contract Quantity: </b>
            </td>
            <td>
              &nbsp;
              &nbsp;
              { this.getItem().quantity }
              &nbsp;
              &nbsp;
              { this.getItem().flatUom }
            </td>
            <td className="text-right">
              <b>Schedule Quantity: </b>
            </td>
            <td>
              &nbsp;
              &nbsp;
              { this.getScheduleQuantity() }
              &nbsp;
              &nbsp;
              { this.getItem().flatUom }
            </td>
            <td className="text-right">
              { this.isBasis() ? (<b>Average: </b>) : '' }
              { this.getItem().contract_type === 'Flat' ? (<b>Price Average: </b>) : '' }
            </td>
            <td>
              &nbsp;
              &nbsp;
              { this.getAvg() }
               &nbsp;
               &nbsp;
              { this.getUomCurrencyText().currency }
              (
              { this.getUomCurrencyText().uom }
              )
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  );

  submitButtons = () => (
    <div className="text-center">
      <br />
      {this.getButton('Back', (event) => this.props.continue(event, 5))}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Create Contract')}
      &nbsp;&nbsp;&nbsp;
      {this.getButton('Cancel', this.closeModal)}
    </div>
  )

  modalPage = () => (
    <div>
      { this.modalBodyRows() }
      { this.showButtonsAndErrors() ? this.buttonsAndErrors() : '' }
      { this.underButtons() }
    </div>
  )
}
