import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

import CommodityCodes from 'components/Options/CommodityCodes';
import FinalUsages from 'components/Options/FinalUsages';

export default withAuth(withAlert()(class TaxRateForm extends CrudForm {
  formType = () => 'Tax Rate';

  getCrudType = () => 'contracts/tax_rates';

  successAlertMsg = () => this.successMsg('Tax Rate');

  options = () => (
    {
      commodity: {
        component: CommodityCodes,
        field: 'commodity_code_uuid',
        props: { isRequired: true, apiParams: { order: 'code' } },
      },
      finalUsage: {
        component: FinalUsages,
        field: 'final_usage',
        props: { isRequired: true },
      },
    }
  );

  modalBodyRows = () => (
    <div>
      {this.dropdownField('commodity')}
      {this.dropdownField('finalUsage')}
      {this.getNumericFieldRow('vat_percent', 'Vat %')}
      {this.getNumericFieldRow('vat_entry_tax_percent', 'Vat Entry Tax %')}
      {this.getNumericFieldRow('vat_export_tax_percent', 'Vat Export Tax %')}
    </div>
  )
}));
