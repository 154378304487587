import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/DirectOrders';
import ItemForm from 'components/Forms/DirectOrder';

export default withAuth(class DirectOrderListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/direct_orders';

  getStorageName = () => 'otto_contracts_direct_orders_list';

  getPageTitle = () => 'Direct Orders';

  getFormTag = () => ItemForm;

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  getAddText = () => 'Create Direct Order';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
