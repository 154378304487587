import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TimeSlots';

export default withAuth(class TimeSlotListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/time_slots';

  getStorageName = () => 'otto_contracts_time_slots_list';

  getPageTitle = () => 'Time Slots';

  getWidthCenter = () => 10;

  getWidthLeft = () => 1;

  getWidthRight = () => 1;

  canDownloadCsv = () => false;
});
