import { withAuth } from '@okta/okta-react';
import { Api, CrudList, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/OperationsOrders';

export default withAuth(class OperationsTruckOrdersListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/truck_orders';

  getListParams = () => ({ transfers: 'I', filter_source: this.props.match.params.loc });

  getStorageName = () => 'otto_contracts_operations_trucks_list';

  getPageTitle = () => (
    `All Truck Orders - ${HtmlHelpers.capitalize(this.props.match.params.loc)}`
  );

  getEditGroups = () => ['barcelona_operations', 'verdu_operations'];

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  reprintBillOfLading = (uuid) => Api.doPdfRequest(
    'GET',
    `${process.env.REACT_APP_CONTRACTO_API_URL}/v1/contracts/bill_of_lading_pdfs/${uuid}`,
    '',
    this.props.auth,
  )
});
