import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/OrdersWithoutContracts';
import ItemForm from 'components/Forms/AddContract';

export default withAuth(class OrdersWithoutContractsListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/truck_orders';

  getListParams = () => ({ filter_canceled: 'Y', filtered: [{ id: 'nomination_uuid', value: 'value_is_null' }] });

  getStorageName = () => 'otto_contracts_orders_without_contracts_list';

  getPageTitle = () => 'Truck Orders without Contracts';

  getEditGroups = () => ['execution_admin'];

  getFormTag = () => ItemForm;

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
