import { Notes } from '@westernmilling/eu_react_shared';

export default class NominationNotes extends Notes {
  constructor(props) {
    super(props);
    this.getData();
  }

  addAdmin = () => true;

  title = () => 'Notes';

  modelType = () => 'Contracts::Nomination';

  modelUuid = () => this.props.uuid;
}
