import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default class PurchaseSales extends OptionsBaseList {
  getDropdownLabelText = () => 'Purchase/ Sales';

  getDropdownFieldName = () => this.props.fieldName || 'purchase_sales';

  getOptions = () => (
    [
      { value: 'P', label: 'Purchase' },
      { value: 'S', label: 'Sales' },
    ]
  )
}
