import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class UnitOfMeasure extends OptionsBaseApi {
  getApiName = () => 'items/unit_of_measures';

  getApiParams = () => ({ filtered: JSON.stringify([{ id: 'active', value: 'yes' }]), show_all: 'true' });

  getDropdownLabelText = () => this.props.fieldLabel || 'Unit Of Measure';

  getDropdownFieldName = () => this.props.fieldName || 'unit_of_measure_uuid';

  getLabelFieldName = () => 'name';

  additionalFields = () => ['symbol'];
}
