import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class VendorEntities extends OptionsBaseApi {
  getApiName = () => 'entities/entity_vendors';

  getApiParams = () => ({ query: 'entity_vendors.active=true and entity.active=true', order_literal: 'entity.name' });

  getDropdownLabelText = () => this.props.fieldLabel || 'Vendor';

  getDropdownFieldName = () => this.props.fieldName || 'entity_vendor_uuid';
}
