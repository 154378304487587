import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Carriers from 'components/Options/Carriers';
import CarrierAgencies from 'components/Options/CarrierAgencies';
import ContractItems from 'components/Options/ContractItems';
import ContractSourceLocations from 'components/Options/ContractSourceLocations';
import Items from 'components/Options/Items';
import SourceLocation from 'components/Options/SourceLocation';
import TimeSlots from 'components/Options/TimeSlots';

import ShipToField from 'components/Forms/ShipToField';

export default withAuth(withAlert()(class TruckOrderActionEditForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.formKey = 1;
  }

  formType = () => 'Truck Order';

  getCrudType = () => 'contracts/truck_orders';

  successAlertMsg = () => this.successMsg('Truck Order');

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  getModalSize = () => 'xl';

  timeSlotDisabled = () => (
    HtmlHelpers.isEmpty(this.getItem().pickup_date) || !['Available', 'Scheduled'].includes(this.getItem().status)
  );

  timeSlotRequired = () => (
    HtmlHelpers.isNotEmpty(this.getItem().pickup_date) && HtmlHelpers.isNotEmpty(this.getItem().nomination_uuid)
  );

  options = () => (
    {
      carrierAgency: {
        component: CarrierAgencies,
        field: 'carrier_agency_entity_uuid',
      },
      contractSource: {
        component: ContractSourceLocations,
        field: 'contract_source_location_uuid',
        label: 'Source Location',
        props: {
          apiParams: { query: `contract_source_locations.contract_uuid = '${this.getItem().contract_uuid}'` },
          isRequired: true,
        },
      },
      item: {
        component: Items,
        field: 'item_uuid',
        props: {
          isRequired: true,
          apiParams: {
            filtered: JSON.stringify([{ id: 'active', value: 'yes' }]),
            show_all: 'true',
            order: 'reference',
          },
        },
      },
      contractItems: {
        component: ContractItems,
        field: 'item_uuid',
        label: 'Item',
        props: {
          valueField: 'item_uuid',
          apiParams: {
            query: `contract_uuid = '${this.getItem().contract_uuid}'`,
            order: 'reference',
          },
        },
      },
      source: {
        component: SourceLocation,
        field: 'agris_bin_desc',
        props: { isRequired: true },
      },
      timeSlot: {
        component: TimeSlots,
        field: 'time_slot_uuid',
        currentValue: this.getItem().time_slot_uuid,
        disabled: this.timeSlotDisabled(),
        props: {
          isRequired: this.timeSlotRequired(),
          key: this.state.formKey,
          orderParams: {
            agris_bin_desc: this.getItem().agris_bin_desc,
            order_uuid: this.getItem().uuid,
            pickup_date: this.getItem().pickup_date,
          },
        },
      },
      vendors: {
        component: Carriers,
        field: 'carrier_entity_uuid',
      },
    }
  );

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';

    let formKey = this.state.formKey;
    if (name === 'pickup_date') {
      newData.time_slot_uuid = '';
      formKey += 1;
    }

    const binDesc = event.target.value?.item?.agris_bin_desc;

    if (name === 'contract_source_location_uuid' && this.getItem().agris_bin_desc !== binDesc) {
      newData.time_slot_uuid = '';
      newData.agris_bin_desc = binDesc;
      formKey += 1;
    }

    this.setVariables({ item: newData, formKey });
  }

  shipToEntityUuid = () => (
    HtmlHelpers.isEmpty(this.getItem().endorsee_entity_uuid)
      ? this.getItem().bill_to_entity_uuid
      : this.getItem().endorsee_entity_uuid
  );

  hasContract = () => HtmlHelpers.isNotEmpty(this.getItem().nomination_uuid)

  getItemField = () => (this.hasContract() ? this.dropdownField('contractItems') : this.dropdownField('item'));

  sourceDisplay = () => (
    HtmlHelpers.isNotEmpty(this.getItem().contract_uuid)
      ? this.dropdownField('contractSource')
      : this.dropdownField('source')
  )

  modalBodyRows = () => (
    <div>
      {this.sourceDisplay()}
      {this.getItemField()}
      {this.getNumericFieldRow('quantity_ordered', 'Quantity')}
      {this.getTextFieldRow('po_number', 'Customer PO', false)}
      {this.dropdownField('carrierAgency')}
      {this.dropdownField('vendors')}
      {this.getDateFieldRow('pickup_date', 'Pickup Date', false)}
      {this.dropdownField('timeSlot')}
      {this.getTextFieldRow('driver_name', 'Driver', false)}
      {this.getTextFieldRow('driver_id', 'Driver ID', false)}
      {this.getTextFieldRow('driver_cell_phone', 'Driver Cell Phone', false)}
      {this.getTextFieldRow('truck_plate', 'Truck Plate', false)}
      {this.getTextFieldRow('trailer_plate', 'Trailer Plate', false)}
      <ShipToField
        auth={this.props.auth}
        entityUuid={this.shipToEntityUuid()}
        item={this.getItem()}
        onChange={this.onChange}
      />
    </div>
  )
}));
