import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TaxRates';
import ItemForm from 'components/Forms/TaxRate';

export default withAuth(class TaxRatesListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/tax_rates';

  getStorageName = () => 'otto_contracts_tax_rates_list';

  getPageTitle = () => 'Tax Rates';

  getFormTag = () => ItemForm;

  getAddGroups = () => ['elian_admin'];

  getEditGroups = () => ['elian_admin'];

  getAddText = () => 'Create Tax Rate';

  auditLogModelType = () => 'Contracts::TaxRate';

  getWidthCenter = () => 8;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;

  canDownloadCsv = () => true;
});
