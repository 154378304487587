import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Select from 'react-select';
import {
  Button, Col, Collapse, FormGroup, Input,
  Modal, ModalHeader, ModalBody, Row,
} from 'reactstrap';

export default withAuth(withAlert()(class TimeSlotDayForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.time_slot_day_uuid = props.timeSlotDayUuid;
    if (props.specialDay) {
      this.state.item.effective_date = props.specialDate;
      this.state.item.end_date = props.specialDate;
    }
  }

  formType = () => `Time Slot for ${this.props.weekday}`;

  getCrudType = () => 'contracts/time_slot_day_times';

  successAlertMsg = () => this.successMsg('Time Slot');

  modalBodyRows = () => (
    <div>
      { this.getHour15Min('start', 'Start Time') }
      { this.getHour15Min('end', 'End Time') }
      { this.getNumericFieldRow('num_appts', '# of Appointments', true, { fieldColumns: 2 }) }
      { this.props.specialDay ? '' : this.getDateFieldRow('effective_date', 'Effective Date') }
    </div>
  )
}));
