import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default class GradeBases extends OptionsBaseList {
  getDropdownLabelText = () => 'Grade Base';

  getDropdownFieldName = () => this.props.fieldName || 'grade_base';

  getOptions = () => (
    [
      { value: 'D', label: 'Destination' },
      { value: 'O', label: 'Origin' },
    ]
  )
}
