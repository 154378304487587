import { Links, TableFilters } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Number',
    accessor: 'number',
    Cell: (row) => Links.getLink('contracts', `schedules/view/${row.original.uuid}`, row.original.number),
    className: 'text-center',
  },
  {
    Header: 'Delivery Start',
    accessor: 'delivery_start_formatted',
    className: 'text-center',
    width: 175,
  },
  {
    Header: 'Delivery End',
    accessor: 'delivery_end_formatted',
    className: 'text-center',
    width: 175,
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    className: 'text-right',
  },
  {
    Header: 'Quantity Balance',
    accessor: 'balance',
    className: 'text-right',
  },
  {
    Header: 'Premium',
    accessor: 'contract_premium',
    className: 'text-right',
  },
  {
    Header: 'Basis',
    accessor: 'contract_basis',
    className: 'text-right',
  },
  {
    Header: 'Futures',
    accessor: 'contract_futures',
    className: 'text-right',
  },
  {
    Header: 'FX Rate',
    accessor: 'contract_fx',
    className: 'text-right',
  },
  {
    Header: 'Flat Price',
    accessor: 'contract_price',
    className: 'text-right',
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
  },
];

export default Columns;
