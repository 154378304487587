import { HtmlHelpers, LinksActionData } from '@westernmilling/eu_react_shared';

export default class DirectOrderView extends LinksActionData {
  menuItems = () => {
    const uuid = this.props.uuid;
    const info = this.props.linkInfo;
    return HtmlHelpers.isEmpty(uuid) ? [] : [
      this.destinationWeightInfo(info.destination_weight),
      this.editInfo(info.edit),
      this.createInboundExportInfo(info.create_inbound_export),
      this.createExportInfo(info.create_export),
      this.reverseExportInfo(info.reverse_inbound_export),
      this.reverseOutboundExportInfo(info.reverse_outbound_export),
    ];
  }

  createExportInfo = (info) => this.buildInfo(info, info.label, () => this.props.createExport('Outbound'));

  createInboundExportInfo = (info) => this.buildInfo(info, info.label, () => this.props.createInboundExport('Inbound'));

  destinationWeightInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('DestinationWeight'));

  editInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal());

  reverseExportInfo = (info) => this.buildInfo(info, info.label, () => this.props.openModal('ReverseInboundExport'));

  reverseOutboundExportInfo = (info) => (
    this.buildInfo(info, info.label, () => this.props.openModal('ReverseOutboundExport'))
  );
}
