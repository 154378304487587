import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import ContractItems from 'components/Options/ContractItems';

export default withAuth(withAlert()(class VesselOrderForm extends CrudForm {
  constructor(props) {
    super(props);
    if (HtmlHelpers.isEmpty(this.state.item.nomination_uuid)) {
      const nomination = props.additionalFormProps.nomination || {};
      this.state.item.nomination_uuid = nomination.uuid;
      this.state.item.contract_uuid = nomination.contract_uuid;
      this.state.item.purchase_sales = nomination.purchase_sales;
    }
  }

  formType = () => 'Vessel Order';

  getCrudType = () => 'contracts/vessel_orders';

  successAlertMsg = () => this.successMsg('Vessel Order');

  contractUuid = () => this.getItem().contract_uuid;

  options = () => (
    {
      contractItems: {
        component: ContractItems,
        field: 'item_uuid',
        label: 'Item',
        props: {
          valueField: 'item_uuid',
          isRequired: true,
          apiParams: {
            query: `contract_uuid = '${this.contractUuid()}'`,
            order: 'reference',
          },
        },
      },
    }
  );

  showDestination = () => (
    this.getItem().purchase_sales === 'P' || HtmlHelpers.isNotEmpty(this.getItem().destination_weight)
  );

  destinationWeight = () => (
    this.showDestination()
      ? this.getNumericFieldRow('destination_weight', 'Destination Weight (KG)')
      : ''
  );

  shippedDate = () => (
    this.getItem().purchase_sales === 'S'
      ? this.getDateFieldRow('shipped_date', 'Shipped Date', true, { value: this.getItem().shipped_date_formatted })
      : ''
  );

  arrivalDate = () => (
    this.showDestination()
      ? this.getDateFieldRow('arrival_date', 'Arrival Date', true, { value: this.getItem().arrival_date_formatted })
      : ''
  );

  additionalRows = () => (
    <div>
      {this.destinationWeight()}
      {this.shippedDate()}
      {this.arrivalDate()}
    </div>
  )

  modalBodyRows = () => (
    <div>
      {this.getTextFieldRow('vessel_name', 'Vessel Name')}
      {this.dropdownField('contractItems')}
      {this.getNumericFieldRow('origin_weight', 'Origin Weight (KG)')}
      {this.additionalRows()}
    </div>
  )
}));
