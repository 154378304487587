import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import BrokerEntities from 'components/Options/BrokerEntities';

export default withAuth(withAlert()(class AccrualModal extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item = props.item;
  }

  formType = () => 'Contract Accruals';

  getCrudType = () => 'contracts/contracts';

  fields = () => ({
    accrual_d_a_s: 'Port Expenses',
    accrual_cert: 'Cert',
    accrual_analysis: 'Analysis',
    accrual_fob_disch: 'FOB/ DISCH',
    accrual_insurance: 'Insurance',
    accrual_customs: 'Customs',
    accrual_tax: 'Tax',
    accrual_other: 'Others',
  });

  options = () => (
    {
      brokers: {
        component: BrokerEntities,
        field: 'broker_entity_uuid',
        label: 'Broker',
        props: { isRequired: HtmlHelpers.isNotEmpty(this.getItem().accrual_brokerage) },
      },
    }
  );

  buildApiVariables = () => {
    const data = {
      uuid: this.getItem().uuid,
      broker_entity_uuid: this.getItem().broker_entity_uuid,
      accrual_brokerage_cents: (this.getItem().accrual_brokerage * 1000) / 10,
      accrual_freight_cents: (this.getItem().accrual_freight * 1000) / 10,
    };
    Object.keys(this.fields()).forEach((field) => {
      const amt = this.getItem()[field] || 0;
      data[`${field}_cents`] = (amt * 1000) / 10;
    });
    return data;
  }

  handleSuccess = (data) => {
    this.showAlertMsg(this.successAlertMsg(data));
    this.props.updateData();
    this.props.closeModal();
  }

  getAccrualRow = (field, label) => this.getNumericFieldRow(field, label, false);

  showBrokers = () => (
    HtmlHelpers.isNotEmpty(this.getItem().accrual_brokerage) && parseFloat(this.getItem().accrual_brokerage) > 0
  );

  modalBodyRows = () => (
    <div>
      { this.getAccrualRow('accrual_freight', 'Freight') }
      { this.getAccrualRow('accrual_brokerage', 'Brokerage') }
      { this.showBrokers() ? this.dropdownField('brokers') : '' }
      { Object.keys(this.fields()).map((field) => this.getAccrualRow(field, this.fields()[field])) }
    </div>
  )
}));
