import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  AddIcon, Api, CrudList, HtmlHelpers,
} from '@westernmilling/eu_react_shared';
import { Col, Row } from 'reactstrap';

import Columns from 'helpers/columns/AgrisMaintenance';
import UpdateTablesListContainer from './UpdateTablesListContainer';

export default withAuth(withAlert()(class SourceLocationListContainer extends UpdateTablesListContainer {
  getColumns = () => Columns(this, false);

  getListApi = () => 'agris/source_locations';

  getStorageName = () => 'otto_contracts_source_location_list';

  getPageTitle = () => 'Source Locations';
}));
