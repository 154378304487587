import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import NoteCategories from 'components/Options/NoteCategories';

export default withAuth(withAlert()(class NoteAddForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.model_type = this.props.modelType;
    this.state.item.model_uuid = this.props.modelUuid;
  }

  formType = () => this.props.title;

  getCrudType = () => 'notes';

  getLabelColumns = () => 2;

  getFieldColumns = () => 9;

  getModalSize = () => 'xl';

  getTextareaRowSize = () => 8;

  successAlertMsg = (data) => (['Note added successfully', 'success']);

  options = () => (
    {
      category: {
        component: NoteCategories,
        field: 'category',
        props: { isRequired: true },
      },
    }
  )

  additionalValidations = () => {
    const errors = [];
    if (HtmlHelpers.isEmpty(this.getItem().category)) {
      errors.push('Category is not present');
    }
    this.setState({ errors });
    return errors;
  }

  modalBodyRows = () => (
    <div>
      {this.dropdownField('category')}
      {this.getTextareaRow('text', 'Note', true)}
      <br />
    </div>
  )
}));
