import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import ShipToField from 'components/Forms/ShipToField';

export default withAuth(withAlert()(class NominationEdit extends CrudForm {
  formType = () => this.props.additionalFormProps.type;

  getCrudType = () => 'contracts/nominations';

  successAlertMsg = () => this.successMsg(this.formType());

  nomination = () => this.props.additionalFormProps.nomination;

  isNomination = () => this.props.additionalFormProps.type === 'Nomination';

  getLabelColumns = () => 3;

  getFieldColumns = () => 7;

  getModalSize = () => 'xl';

  modalBodyRows = () => (
    <div>
      <ShipToField
        auth={this.props.auth}
        entityUuid={this.isNomination() ? this.nomination().bill_to_entity_uuid : this.nomination().endorsee_entity_uuid}
        item={this.getItem()}
        onChange={this.onChange}
      />
      {this.getTextFieldRow('quantity', 'Quantity')}
      {this.getDateFieldRow('delivery_end_date', 'Delivery End')}
    </div>
  );
}));
