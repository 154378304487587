import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Api, CrudList, HtmlHelpers, ModalConfirmation, WmAuth,
} from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TrucksOnSite';
import ItemForm from 'components/Forms/TruckCheckOut';
import ManualWeightEntry from 'components/Forms/ManualWeightEntry';
import LoadersForm from 'components/Forms/LoadersForm';

export default withAuth(withAlert()(class TrucksOnSiteListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/truck_orders';

  getListParams = () => ({
    filtered: [{ id: 'status', value: 'Truck on Site' }],
    filter_source: this.props.match.params.loc,
    transfers: 'I',
    show_all: 'true',
  });

  getStorageName = () => 'otto_contracts_trucks_on_site_list';

  getPageTitle = () => (
    `Trucks on Site - ${HtmlHelpers.capitalize(this.props.match.params.loc)}`
  );

  getFormTag = () => ItemForm;

  getEditGroups = () => ['barcelona_operations', 'verdu_operations'];

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  isBarcelona = () => this.props.match.params.loc === 'barcelona';

  reprintPickupTicket = (uuid) => Api.doPdfRequest(
    'GET',
    `${process.env.REACT_APP_CONTRACTO_API_URL}/v1/contracts/pickup_ticket_pdfs/${uuid}`,
    '',
    this.props.auth,
  )

  updateData = () => {
    this.setState({
      disableCancel: null,
      showModal: false,
      selectedItem: null,
      loading: true,
      showModalManualWeight: false,
      selectedItemManualWeight: null,
      showModalCancelCheckIn: false,
      selectedItemCancelCheckIn: null,
    });
    this.refreshAfterUpdate();
  }

  manualEntry = () => (
    this.state.showModalManualWeight ? (
      <ManualWeightEntry
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItemManualWeight}
        showModal={this.state.showModalManualWeight}
        closeModal={() => this.handleCloseModal('ManualWeight')}
        updateData={this.updateData}
        englishLanguageUuid={this.state.englishLanguageUuid}
        additionalFormProps={this.additionalFormProps()}
      />
    ) : ''
  );

  additionalFormProps = () => ({
    barcelona: this.state.additonalInfo.barcelona,
    verdu: this.state.additonalInfo.verdu,
  });

  cancelMsg = () => (
    <div>
      Are you sure you want to cancel this check in?
      <br />
      <br />
      {`Truck Order: ${this.state.selectedItemCancelCheckIn.id}`}
      <br />
      {`Driver Name: ${this.state.selectedItemCancelCheckIn.driver_name}`}
      <br />
      {`Truck Plate: ${this.state.selectedItemCancelCheckIn.truck_plate}`}
    </div>
  );

  cancelCheckIn = () => {
    let contents = '';
    if (this.state.showModalCancelCheckIn) {
      contents = (
        <ModalConfirmation
          msg={this.cancelMsg()}
          onConfirmation={this.cancelApi}
          showModal={this.state.showModalCancelCheckIn}
          closeModal={() => this.handleCloseModal('CancelCheckIn')}
          disabled={this.state.disableCancel}
        />
      );
    }
    return contents;
  };

  handleCloseOnError = () => {
    this.handleCloseModal('CancelCheckIn');
    this.updateData();
  };

  cancelApi = () => {
    const data = { uuid: this.state.selectedItemCancelCheckIn.uuid };
    this.setState({ disableCancel: true });
    Api.crudDelete(this.props.auth, 'contracts/truck_order_checkins', data)
      .then((response) => {
        if (response.data !== undefined) {
          this.updateData();
          this.props.alert.show('Sucessfully canceled the check in', { type: 'success' });
        } else if (response.errors !== undefined) {
          this.handleCloseOnError();
          const errors = HtmlHelpers.convertRailsErrors(response.errors);
          this.props.alert.show(errors.join(', '), { type: 'error' });
        } else {
          this.handleCloseOnError();
          this.props.alert.show(
            'System error, please try again. If you continue to get this error, open a Help Desk ticket',
            { type: 'error' },
          );
        }
      });
  };

  isLoader = () => WmAuth.isEditAdmin(this.state, ['loaders']);

  loadersButton = () => (
    this.state.showModalLoaders ? (
      <LoadersForm
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItemLoaders}
        showModal={this.state.showModalLoaders}
        closeModal={() => this.handleCloseModal('Loaders')}
        updateData={this.updateData}
        englishLanguageUuid={this.state.englishLanguageUuid}
      />
    ) : ''
  );

  additionalContent = () => (
    <div>
      {this.manualEntry()}
      {this.cancelCheckIn()}
      {this.loadersButton()}
    </div>
  );
}));
