import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { CrudList, HtmlHelpers, TableTitle } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Nominations';
import Nomination from 'components/Forms/Nomination';
import NominationEdit from 'components/Forms/NominationEdit';

export default withAuth(withAlert()(class Nominations extends CrudList {
  isNomination = () => this.props.pageType === 'nomination';

  getColumns = () => Columns(this);

  getFormTag = () => Nomination;

  getListApi = () => 'contracts/nominations';

  nominationQuery = () => ({
    filtered: [{ id: 'nomination_generations__contract_schedule_uuid', value: this.props.schedule.uuid }],
  });

  endorsementQuery = () => ({
    filtered: [
      { id: 'nomination_generations__order_sequence', value: this.props.nomination.id },
      { id: 'nomination_generations__not_self', value: this.props.nomination.uuid },
    ],
  });

  getListParams = () => (this.isNomination() ? this.nominationQuery() : this.endorsementQuery());

  getStorageName = () => 'otto_contract_nominations_table_list';

  getAddGroups = () => ['execution_admin'];

  getEditGroups = () => ['execution_admin'];

  getPageTitle = () => (this.isNomination() ? 'Nominations' : 'Endorsements');

  getAddText = () => `Add ${this.isNomination() ? 'Nomination' : 'Endorsement'}`;

  auditLogModelType = () => 'Contracts::Nominations';

  getTableClassSettings = () => 'otto__short_table';

  additionalFormProps = () => ({ schedule: this.props.schedule, type: 'nomination' });

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  canDownloadCsv = () => false;

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  handleOpenModal = (selectedItem, typ = '', opts = {}) => {
    let type = typ;
    if (type === '' && !this.isNomination()) {
      type = 'Endorsement';
    }
    this.setState({ [`showModal${type}`]: true, [`selectedItem${type}`]: selectedItem, [`selectedOptions${type}`]: opts });
  }

  updateData = () => this.props.updateData();

  parent = () => this.props.schedule || this.props.nomination;

  parentActive = () => (
    HtmlHelpers.isEmpty(this.props.nomination) ? this.parent().status !== 'Canceled' : this.parent().active
  );

  showAddButton = () => (
    this.parentActive() && parseFloat(this.parent().quantity_balance) > 0 && this.isAddAdmin()
  );

  smallTitle = () => (
    <Row>
      <Col md="8" className="text-left">
        <TableTitle title={this.getPageTitle()} />
      </Col>
      <Col md="4" className="align-self-center text-right">
        { this.showAddButton() ? this.createButton() : ''}
      </Col>
    </Row>
  );

  additionalContent = () => (
    <div>
      {this.addEndorsementContent()}
      {this.editEndorsementContent()}
      {this.editNominationContent()}
    </div>
  );

  editEndorsementContent = () => (this.state.showModalEditEndorsement ? (
    <NominationEdit
      userGroups={this.state.userGroups}
      data={this.state.data}
      editItem={this.state.selectedItemEditEndorsement}
      showModal={this.state.showModalEditEndorsement}
      closeModal={() => this.handleCloseModal('EditEndorsement')}
      updateData={this.updateData}
      additionalFormProps={{
        type: 'Endorsement',
        nomination: this.state.selectedOptionsEditEndorsement.nomination || this.props.nomination,
      }}
    />
  ) : '');

  editNominationContent = () => (this.state.showModalEditNomination ? (
    <NominationEdit
      userGroups={this.state.userGroups}
      data={this.state.data}
      editItem={this.state.selectedItemEditNomination}
      showModal={this.state.showModalEditNomination}
      closeModal={() => this.handleCloseModal('EditNomination')}
      updateData={this.updateData}
      additionalFormProps={{
        type: 'Nomination',
        nomination: this.state.selectedOptionsEditNomination.nomination || this.props.nomination,
      }}
    />
  ) : '');

  addEndorsementContent = () => (this.state.showModalEndorsement ? (
    <Nomination
      userGroups={this.state.userGroups}
      data={this.state.data}
      editItem={this.state.selectedItemEndorsement}
      showModal={this.state.showModalEndorsement}
      closeModal={() => this.handleCloseModal('Endorsement')}
      additionalFormProps={{ type: 'Endorsement' }}
      nomination={this.state.selectedOptionsEndorsement.nomination || this.props.nomination}
      updateData={this.updateData}
    />
  ) : '')
}));
