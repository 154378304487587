import { HtmlHelpers, PaginationTable } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/InboundTransferUploads';

export default class TruckTransferUploadResults extends PaginationTable {
  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.loading = false;
  }

  getColumns = () => Columns(this);

  getTitle = () => 'Transfer Truck Upload Results'

  getData = () => this.state.data;

  canDownloadCsv = () => true;

  fetchCsvData = async () => {
    this.setState({ currentlyDownloading: true });

    const csvContent = await this.buildCSV(this.getData());
    await this.openCSV(csvContent);
    await HtmlHelpers.sleep(600);

    this.setState({ currentlyDownloading: false });
  }
}
