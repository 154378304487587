import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Commodity',
    accessor: 'commodity_code',
    className: 'text-left',
  },
  {
    Header: 'Final Usage',
    accessor: 'final_usage',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Vat %',
    accessor: 'vat_percent',
    className: 'text-right',
    width: 120,
  },
  {
    Header: 'Vat Entry Tax %',
    accessor: 'vat_entry_tax_percent',
    className: 'text-right',
    width: 120,
  },
  {
    Header: 'Vat Export Tax %',
    accessor: 'vat_export_tax_percent',
    className: 'text-right',
    width: 120,
  },
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
