import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Order ID',
    accessor: 'order_id',
    Cell: (row) => Links.getLink('contracts', `truck_orders/view/${row.original.uuid}`, row.original.order_id, true),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Customer',
    Cell: (row) => (row.original.transfer ? `Transfer To: ${row.original.customer}` : row.original.customer),
    accessor: 'customer',
    className: 'text-left',
    width: 300,
  },
  {
    Header: 'Customer PO',
    accessor: 'customer_po',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Pickup Date',
    accessor: 'pickup_date_formatted',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'BOL #',
    accessor: 'bol_number',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Truck Plate',
    accessor: 'truck_plate',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Uploaded',
    accessor: 'uploaded',
    className: 'text-center',
    width: 75,
  },
  {
    Header: 'Message',
    accessor: 'msg',
    className: 'text-left',
  },
];

export default Columns;
