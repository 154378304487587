import { AppJs } from '@westernmilling/eu_react_shared';

import ContractListContainer from 'containers/ContractListContainer';
import ContractBrokerageReportListContainer from 'containers/ContractBrokerageReportListContainer';
import ContractChangesListContainer from 'containers/ContractChangesListContainer';
import ContractScheduleListContainer from 'containers/ContractScheduleListContainer';
import ContractScheduleViewContainer from 'containers/ContractScheduleViewContainer';
import ContractViewContainer from 'containers/ContractViewContainer';
import DirectOrderListContainer from 'containers/DirectOrderListContainer';
import DirectOrderViewContainer from 'containers/DirectOrderViewContainer';
import GrainTicketsNotInvoicedListContainer from 'containers/GrainTicketsNotInvoicedListContainer';
import InboundTransferUploadContainer from 'containers/InboundTransferUploadContainer';
import InvoiceListContainer from 'containers/InvoiceListContainer';
import LowToleranceScheduleListContainer from 'containers/LowToleranceScheduleListContainer';
import MarketZoneListContainer from 'containers/MarketZoneListContainer';
import MerchandiserListContainer from 'containers/MerchandiserListContainer';
import NominationViewContainer from 'containers/NominationViewContainer';
import NominationListContainer from 'containers/NominationListContainer';
import OperationsTruckOrdersListContainer from 'containers/OperationsTruckOrdersListContainer';
import OrdersWithoutContractsListContainer from 'containers/OrdersWithoutContractsListContainer';
import OutboundTruckUploadContainer from 'containers/OutboundTruckUploadContainer';
import SourceLocationListContainer from 'containers/SourceLocationListContainer';
import StorageUnitListContainer from 'containers/StorageUnitListContainer';
import TaxRatesListContainer from 'containers/TaxRatesListContainer';
import TimeSlotGroupsListContainer from 'containers/TimeSlotGroupsListContainer';
import TimeSlotListContainer from 'containers/TimeSlotListContainer';
import TimeSlotSpecialDayListContainer from 'containers/TimeSlotSpecialDayListContainer';
import TimeSlotViewContainer from 'containers/TimeSlotViewContainer';
import TransferTruckOrderListContainer from 'containers/TransferTruckOrderListContainer';
import TransportationModeListContainer from 'containers/TransportationModeListContainer';
import TruckOrderBulkCancelListContainer from 'containers/TruckOrderBulkCancelListContainer';
import TruckOrderListContainer from 'containers/TruckOrderListContainer';
import TruckOrderViewContainer from 'containers/TruckOrderViewContainer';
import TruckOrdersOnHoldListContainer from 'containers/TruckOrdersOnHoldListContainer';
import TrucksOnSiteListContainer from 'containers/TrucksOnSiteListContainer';
import UpcomingTimeSlotsListContainer from 'containers/UpcomingTimeSlotsListContainer';
import UpcomingTruckOrdersListContainer from 'containers/UpcomingTruckOrdersListContainer';
import VesselOrderPurchaseListContainer from 'containers/VesselOrderPurchaseListContainer';
import VesselOrderSalesListContainer from 'containers/VesselOrderSalesListContainer';
import VesselOrderViewContainer from 'containers/VesselOrderViewContainer';

export default class App extends AppJs {
  defaultRoute = () => ContractListContainer;

  routes = () => [
    { path: '/schedules', component: ContractScheduleListContainer },
    { path: '/schedules/view/:uuid', component: ContractScheduleViewContainer },
    { path: '/contract_brokerage_report', component: ContractBrokerageReportListContainer },
    { path: '/contract_changes', component: ContractChangesListContainer },
    { path: '/contracts', component: ContractListContainer },
    { path: '/contracts/view/:uuid', component: ContractViewContainer },
    { path: '/direct_orders', component: DirectOrderListContainer },
    { path: '/direct_orders/view/:uuid', component: DirectOrderViewContainer },
    { path: '/grain_tickets/not_invoiced', component: GrainTicketsNotInvoicedListContainer },
    { path: '/inbound_transfer_uploads', component: InboundTransferUploadContainer },
    { path: '/invoices', component: InvoiceListContainer },
    { path: '/low_tolerances', component: LowToleranceScheduleListContainer },
    { path: '/market_zones', component: MarketZoneListContainer },
    { path: '/merchandisers', component: MerchandiserListContainer },
    { path: '/nominations', component: NominationListContainer },
    { path: '/nominations/view/:uuid', component: NominationViewContainer, props: { type: 'Nomination' } },
    { path: '/endorsements/view/:uuid', component: NominationViewContainer, props: { type: 'Endorsement' } },
    { path: '/storage_units', component: StorageUnitListContainer },
    { path: '/tax_rates', component: TaxRatesListContainer },
    { path: '/time_slot_groups', component: TimeSlotGroupsListContainer },
    { path: '/time_slots', component: TimeSlotListContainer },
    { path: '/time_slot_special_days', component: TimeSlotSpecialDayListContainer },
    { path: '/time_slots/view/:uuid', component: TimeSlotViewContainer },
    { path: '/orders', component: TruckOrderListContainer },
    { path: '/orders_without_contracts', component: OrdersWithoutContractsListContainer },
    { path: '/outbound_truck_uploads', component: OutboundTruckUploadContainer },
    { path: '/source_locations', component: SourceLocationListContainer },
    { path: '/transfers', component: TransferTruckOrderListContainer },
    { path: '/transportation_modes', component: TransportationModeListContainer },
    { path: '/truck_orders/bulk_cancelation', component: TruckOrderBulkCancelListContainer },
    { path: '/truck_orders/view/:uuid', component: TruckOrderViewContainer, props: { type: 'orders' } },
    { path: '/transfer_truck_orders/view/:uuid', component: TruckOrderViewContainer, props: { type: 'transfers' } },
    { path: '/truck_orders_on_hold', component: TruckOrdersOnHoldListContainer },
    { path: '/trucks_on_site/:loc', component: TrucksOnSiteListContainer },
    { path: '/upcoming_orders/:loc', component: UpcomingTruckOrdersListContainer },
    { path: '/upcoming_time_slots', component: UpcomingTimeSlotsListContainer },
    { path: '/all_operations_truck_orders/:loc', component: OperationsTruckOrdersListContainer },
    { path: '/purchase_vessel_orders', component: VesselOrderPurchaseListContainer },
    { path: '/sales_vessel_orders', component: VesselOrderSalesListContainer },
    { path: '/vessel_orders/view/:type/:uuid', component: VesselOrderViewContainer },
  ];
}
