import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default class ContractType extends OptionsBaseList {
  getDropdownLabelText = () => 'Contract Type';

  getDropdownFieldName = () => this.props.fieldName || 'contract_type';

  getOptions = () => (
    [
      { value: 'NPE', label: 'NPE' },
      { value: 'Flat', label: 'Flat' },
      { value: 'Premium Over Premium', label: 'Premium Over Premium' },
      { value: 'Euro Basis', label: 'Euro Basis' },
      { value: 'Spanish Basis', label: 'Spanish Basis' },
      { value: 'US Basis', label: 'US Basis' },
    ]
  )
}
