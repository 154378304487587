import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TruckOrders';
import ItemForm from 'components/Forms/TruckOrder';

export default withAuth(class TruckOrderListContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'contracts/truck_orders';

  getStorageName = () => 'otto_contracts_truck_orders_list';

  getPageTitle = () => 'Truck Orders';

  getFormTag = () => ItemForm;

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getAddGroups = () => ['trading_admin'];

  getEditGroups = () => ['execution_admin'];

  getAddText = () => 'Create Truck Order without Contract';

  auditLogModelType = () => 'Contracts::TruckOrder';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
