import React from 'react';
import { ColumnButton, HtmlHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Order ID',
    accessor: 'id',
    Cell: (row) => Links.getLink('contracts', `truck_orders/view/${row.original.uuid}`, row.original.id),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Source',
    accessor: 'agris_bin_desc',
    className: 'text-left',
    width: 250,
  },
  {
    Header: 'Ship To',
    accessor: 'ship_to',
    Cell: (row) => (<pre>{row.original.ship_to}</pre>),
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    id: 'quantity',
    Header: 'Quantity',
    accessor: (d) => d.quantity_shipped || d.quantity_ordered,
    className: 'text-right',
    width: 150,
  },
  {
    id: 'unit_of_measure__symbol',
    Header: 'UOM',
    accessor: 'unit_of_measure_symbol',
    className: 'text-center',
    width: 100,
  },
  {
    id: 'pickup_shipped',
    Header: 'Pickup Date',
    accessor: (d) => d.shipped_date || d.pickup_date || '',
    className: 'text-center',
    width: 100,
  },
  {
    id: 'time_slot_appointment__time_slot_starts_at',
    Header: 'Time Slot',
    accessor: 'time_slot_range',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    width: 120,
  },
  {
    id: 'selection',
    Header: '',
    Cell: (row) => (<ColumnButton onClick={() => context.handleOpenModal(row.index)} text="Add to Contract" />),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 140,
    show: context.isEditAdmin(),
    csvShow: false,
  },
];

export default Columns;
